import React, {useEffect, useState} from 'react';
import {AiFillSchedule, AiOutlineBars} from "react-icons/ai";
import {FaListCheck, FaUserCheck} from "react-icons/fa6";
import {
  MdEventRepeat,
  MdLibraryAddCheck,
  MdOutlineCleaningServices,
  MdOutlineDoorSliding,
  MdPermMedia
} from "react-icons/md";
import { PiElevatorFill } from "react-icons/pi"; //hall call
import { FaElevator } from "react-icons/fa6"; //car call
import {BiDoorOpen, BiSolidTimer} from "react-icons/bi";
import { RiDoorClosedLine } from "react-icons/ri";
import { BsDatabaseFillCheck } from "react-icons/bs";
import {getBuilderNodeList} from "../api";
import errorPage from "../../../pages/ErrorPage";
import { useWorkflowStore } from "../model";
import {IoCaretForwardCircle, IoMoveSharp, IoStopCircle} from "react-icons/io5";
import {RiBattery2ChargeFill} from "react-icons/ri";
import {BsSignStopFill} from "react-icons/bs";
import {MdHome} from "react-icons/md";
import {TbEaseInOutControlPoints, TbStairsUp} from "react-icons/tb";
import {FaMapMarkedAlt} from "react-icons/fa";
/*SVG*/

const ItemBuilder = (props: any) => {

  const {robotInfo} = useWorkflowStore();

  const [nodeList, setNodeList] = useState<any>({});
  const [robotNodeList, setRobotNodeList] = useState<any>([]);
  const [elvNodeList, setElvNodeList] = useState<any>([]);
  const [plcNodeList, setPlcNodeList] = useState<any>([]);
  const [commonNodeList, setCommonNodeList] = useState<any>([]);
  const [autoDoorNodeList, setAutoDoorNodeList] = useState<any>([]);


  const onDragStart = (event: any, nodeType: any) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.setData('id', event.target.id);
    event.dataTransfer.effectAllowed = 'move';
  };

  useEffect(() => {
    if(props.isReset){
      setRobotNodeList([]);
      setElvNodeList([]);
      setPlcNodeList([]);
      setCommonNodeList([]);
      setAutoDoorNodeList([]);
    }
  }, [props.isReset]);

  useEffect(() => {
    if (robotInfo) {
      getNodeListAPI(robotInfo.deviceId);
    }
  }, [robotInfo]);

  const getNodeListAPI = async (deviceId: string) => {
    await getBuilderNodeList(deviceId)
        .then((res: any) => {
          if (res !== null && res !== undefined) {
            console.log(res)
            makeNodeList(res);
          }
        })
        .catch((error: string) => {
          errorPage();
        });
  };

  const makeNodeList = (data: any) => {
    setRobotNodeList(data.robotNodeList.length > 0 ?  data.robotNodeList : []);
    setElvNodeList(data.elvNodeList.length > 0 ?  data.elvNodeList : []);
    setPlcNodeList(data.plcNodeList.length > 0 ?  data.plcNodeList : []);
    setCommonNodeList(data.commonNodeList.length > 0 ?  data.commonNodeList : []);
    setAutoDoorNodeList(data.autoDoorNodeList.length > 0 ? data.autoDoorNodeList : []);
  }

  const iconMapping = (code: string) => {

    const name = code.replace('NODE_', '');
    switch (name){
      case "MOVE": {
        return (<IoMoveSharp size="20" color="rgb(100, 100, 100)"/>);
        break;
      }
      case "STOP": {
        return (<BsSignStopFill size="20" color="rgb(100, 100, 100)"/>);
        break;
      }
      case "CHARGE": {
        return (<RiBattery2ChargeFill size="20" color="rgb(100, 100, 100)"/>);
        break;
      }
      case "HOME": {
        return (<MdHome size="20" color="rgb(100, 100, 100)"/>);
        break;
      }
      case "HALL_CALL": {
        return (<PiElevatorFill size="20" color="rgb(100, 100, 100)"/>);
        break;
      }
      case "CAR_CALL": {
        return (<FaElevator size="20" color="rgb(100, 100, 100)"/>);
        break;
      }
      case "OPEN_DOOR": {
        return (<BiDoorOpen size="20" color="rgb(100, 100, 100)"/>);
        break;
      }
      case "KEEP_OPEN": {
        return (<RiDoorClosedLine size="20" color="rgb(100, 100, 100)"/>);
        break;
      }
      case "CHECK": {
        return (<FaListCheck size="20" color="rgb(100, 100, 100)"/>);
        break;
      }
      case "CHECK_SELECT": {
        return (<MdLibraryAddCheck size="20" color="rgb(100, 100, 100)"/>);
        break;
      }
      case "SET_VALUE": {
        return (<BsDatabaseFillCheck size="20" color="rgb(100, 100, 100)"/>);
        break;
      }
      case "IO_CONTROL": {
        return (<TbEaseInOutControlPoints size="20" color="rgb(100, 100, 100)"/>);
        break;
      }
      case "WAIT": {
        return (<BiSolidTimer size="20" color="rgb(100, 100, 100)"/>);
        break;
      }
      case "ON_TIME": {
        return (<MdEventRepeat size="20" color="rgb(100, 100, 100)"/>);
        break;
      }
      case "USER_SELECT": {
        return (<FaUserCheck size="20" color="rgb(100, 100, 100)"/>);
        break;
      }
      case "AUTO_DOOR_OPEN": {
        return (<MdOutlineDoorSliding size="20" color="rgb(100, 100, 100)"/>);
        break;
      }
      case "AUTO_DOOR_CLOSE": {
        return (<MdOutlineDoorSliding size="20" color="rgb(100, 100, 100)"/>);
        break;
      }
      case "CLEAN": {
        return (<MdOutlineCleaningServices  size="20" color="rgb(100, 100, 100)"/>);
        break;
      }
      case "CONTENTS_PLAY": {
        return (<MdPermMedia size="20" color="rgb(100, 100, 100)"/>);
        break;
      }
      case "CHANGE_MAP": {
        return (<FaMapMarkedAlt size="20" color="rgb(100, 100, 100)"/>);
        break;
      }
      case "CHANGE_FLOOR": {
        return (<TbStairsUp size="20" color="rgb(100, 100, 100)"/>);
        break;
      }
      case 'ROBOT|BACK_TO_SCHEDULE':
        return (<AiFillSchedule size="20" color="rgb(100, 100, 100)"/>);
        break;
    }

    return <IoStopCircle size="20" color="rgb(100, 100, 100)"/>;
  }

  return (
      <aside className={"wf-body-right-contents"}>
        <div id="START/END|START|시작" draggable={true} onDragStart={(event) => onDragStart(event, 'input')}>
          <div style={styles.parent}>
            <div style={styles.iconParent}><IoCaretForwardCircle size="20" color="rgb(100, 100, 100)"/></div>
            시작
          </div>
        </div>

        <div id="START/END|END|종료" draggable={true} onDragStart={(event) => onDragStart(event, 'output')}>
          <div style={styles.parent}>
            <div style={styles.iconParent}><IoStopCircle size="20" color="rgb(100, 100, 100)"/></div>
            종료
          </div>
        </div>

        {/* 공통 */}
        {
          commonNodeList.length > 0 ?
              (<div style={styles.sidebarItemHeadingContainer}>
                <div style={{display: "flex"}}><AiOutlineBars size="20" color="rgb(100, 100, 100)"/>
                  <span style={styles.sidebarItemHeading}> 공통 </span>
                </div>
              </div>) : ''
        }
        {
          commonNodeList.map((item: any, idx: number) => {
            const name = item.nodeNameEn.replace('NODE_', '');

            return (
                <div key={`node_${idx}`} id={`COMMON|${name}|${item.nodeNameKr}`} draggable={true}
                     onDragStart={(event) => onDragStart(event, 'default')}>
                  <div style={styles.parent}>
                    <div style={styles.iconParent}>
                      { iconMapping(item.nodeNameEn) }
                    </div>
                    {item.nodeNameKr}
                  </div>
                </div>
            )

          })
        }
        {/* 로봇 */}
        {
          robotNodeList.length > 0 ?
              (<div style={styles.sidebarItemHeadingContainer}>
                <div style={{display: "flex"}}><AiOutlineBars size="20" color="rgb(100, 100, 100)"/>
                  <span style={styles.sidebarItemHeading}> 로봇 </span>
                </div>
              </div>) : ''
        }
        {
          robotNodeList.map((item: any, idx: number) => {
            const name = item.nodeNameEn.replace('NODE_', '');

            return (
                <div key={`node_${idx}`} id={`AMR|${name}|${item.nodeNameKr}`} draggable={true}
                     onDragStart={(event) => onDragStart(event, 'default')}>
                  <div style={styles.parent}>
                    <div style={styles.iconParent}>
                      { iconMapping(item.nodeNameEn) }
                    </div>
                    {item.nodeNameKr}
                  </div>
                </div>
            )

          })
        }

        {/* 엘리베이터 */}
        {
          elvNodeList.length > 0 ?
              (<div style={styles.sidebarItemHeadingContainer}>
                <div style={{display: "flex"}}><AiOutlineBars size="20" color="rgb(100, 100, 100)"/>
                  <span style={styles.sidebarItemHeading}> 엘리베이터 </span>
                </div>
              </div>) : ''
        }
        {
          elvNodeList.map((item: any, idx: number) => {
            const name = item.nodeNameEn.replace('NODE_', '');

            return (
                <div key={`node_${idx}`} id={`ELV|${name}|${item.nodeNameKr}`} draggable={true}
                     onDragStart={(event) => onDragStart(event, 'default')}>
                  <div style={styles.parent}>
                    <div style={styles.iconParent}>
                      { iconMapping(item.nodeNameEn) }
                    </div>
                    {item.nodeNameKr}
                  </div>
                </div>
            )

          })
        }

        {/* 자동문 */}
        {
          autoDoorNodeList.length > 0 ?
              (<div style={styles.sidebarItemHeadingContainer}>
                <div style={{display: "flex"}}><AiOutlineBars size="20" color="rgb(100, 100, 100)"/>
                  <span style={styles.sidebarItemHeading}> 자동문 </span>
                </div>
              </div>) : ''
        }
        {
          autoDoorNodeList.map((item: any, idx: number) => {
            const name = item.nodeNameEn.replace('NODE_', '');

            return (
                <div key={`node_${idx}`} id={`AUTODOOR|${name}|${item.nodeNameKr}`} draggable={true}
                     onDragStart={(event) => onDragStart(event, 'default')}>
                  <div style={styles.parent}>
                    <div style={styles.iconParent}>
                      { iconMapping(item.nodeNameEn) }
                    </div>
                    {item.nodeNameKr}
                  </div>
                </div>
            )

          })
        }

        {/* PLC */}
        {
          plcNodeList.length > 0 ?
              (<div style={styles.sidebarItemHeadingContainer}>
                <div style={{display: "flex"}}><AiOutlineBars size="20" color="rgb(100, 100, 100)"/>
                  <span style={styles.sidebarItemHeading}> PLC </span>
                </div>
              </div>) : ''
        }
        {
          plcNodeList.map((item: any, idx: number) => {
            const name = item.nodeNameEn.replace('NODE_', '');

            return (
                <div key={`node_${idx}`} id={`PLC|${name}|${item.nodeNameKr}`} draggable={true}
                     onDragStart={(event) => onDragStart(event, 'default')}>
                  <div style={styles.parent}>
                    <div style={styles.iconParent}>
                      { iconMapping(item.nodeNameEn) }
                    </div>
                    {item.nodeNameKr}
                  </div>
                </div>
            )

          })
        }

        {/* Cleaning Robot */}
        {/*<div style={styles.sidebarItemHeadingContainer}>*/}
        {/*    <div style={{display: "flex"}}><AiOutlineBars size="20" color="rgb(100, 100, 100)"/>*/}
        {/*        <span style={styles.sidebarItemHeading}> CLEANING ROBOT </span>*/}
        {/*    </div>*/}
        {/*</div>*/}

        {/*<div id="CLEANING ROBOT|CLEAN" draggable={true} onDragStart={(event) => onDragStart(event, 'default')}>*/}
        {/*    <div style={styles.parent}>*/}
        {/*        <div style={styles.iconParent}><img src={Clean} width={20} height={20}/></div>*/}
        {/*        CLEAN*/}
        {/*    </div>*/}
        {/*</div>*/}
        {/*<div id="CLEANING ROBOT|MOVE" draggable={true} onDragStart={(event) => onDragStart(event, 'default')}>*/}
        {/*    <div style={styles.parent}>*/}
        {/*        <div style={styles.iconParent}><img src={Move} width={20} height={20}/></div>*/}
        {/*        MOVE*/}
        {/*    </div>*/}
        {/*</div>*/}
        {/*<div id="CLEANING ROBOT|CHARGE" draggable={true} onDragStart={(event) => onDragStart(event, 'default')}>*/}
        {/*    <div style={styles.parent}>*/}
        {/*        <div style={styles.iconParent}><img src={Charge} width={20} height={20}/></div>*/}
        {/*        CHARGE*/}
        {/*    </div>*/}
        {/*</div>*/}
        {/*<div id="CLEANING ROBOT|CHANGE MAP" draggable={true} onDragStart={(event) => onDragStart(event, 'default')}>*/}
        {/*    <div style={styles.parent}>*/}
        {/*        <div style={styles.iconParent}><img src={ChangeMap} width={20} height={20}/></div>*/}
        {/*        CHANGE MAP*/}
        {/*    </div>*/}
        {/*</div>*/}

        {/*<div style={styles.sidebarItemHeadingContainer}>*/}
        {/*    <div style={{display: "flex"}}><AiOutlineBars size="20" color="rgb(100, 100, 100)"/>*/}
        {/*        <span style={styles.sidebarItemHeading}> TIME </span>*/}
        {/*    </div>*/}
        {/*</div>*/}

        {/*<div id="TIME|USER SELECT" draggable={true} onDragStart={(event) => onDragStart(event, 'default')}>*/}
        {/*    <div style={styles.parent}>*/}
        {/*        <div style={styles.iconParent}><FaUserCheck size="20" color="rgb(100, 100, 100)"/></div>*/}
        {/*        USER SELECT*/}
        {/*    </div>*/}
        {/*</div>*/}

        {/*<div id="TIME|WAIT" draggable={true} onDragStart={(event) => onDragStart(event, 'default')}>*/}
        {/*    <div style={styles.parent}>*/}
        {/*        <div style={styles.iconParent}><BiSolidTimer size="20" color="rgb(100, 100, 100)"/></div>*/}
        {/*        WAIT*/}
        {/*    </div>*/}
        {/*</div>*/}

        {/*<div id="TIME|ON TIME" draggable={true} onDragStart={(event) => onDragStart(event, 'default')}>*/}
        {/*    <div style={styles.parent}>*/}
        {/*        <div style={styles.iconParent}><MdEventRepeat size="20" color="rgb(100, 100, 100)"/></div>*/}
        {/*        ON TIME*/}
        {/*    </div>*/}
        {/*</div>*/}


        {/*<div style={styles.sidebarItemHeadingContainer}>*/}
        {/*    <div style={{display: "flex"}}><AiOutlineBars size="20" color="rgb(100, 100, 100)"/>*/}
        {/*        <span style={styles.sidebarItemHeading}> AMR </span>*/}
        {/*    </div>*/}
        {/*</div>*/}

        {/*<div id="AMR|MOVE" draggable={true} onDragStart={(event) => onDragStart(event, 'default')}>*/}
        {/*    <div style={styles.parent}>*/}
        {/*        <div style={styles.iconParent}><LuMove size="20" color="rgb(100, 100, 100)"/></div>*/}
        {/*        MOVE*/}
        {/*    </div>*/}
        {/*</div>*/}

        {/*<div id="AMR|CHANGE MAP" draggable={true} onDragStart={(event) => onDragStart(event, 'default')}>*/}
        {/*    <div style={styles.parent}>*/}
        {/*        <div style={styles.iconParent}><FaMapLocationDot size="20" color="rgb(100, 100, 100)"/></div>*/}
        {/*        <span>CHANGE MAP</span>*/}
        {/*    </div>*/}
        {/*</div>*/}

        {/*<div id="AMR|UPDATE FLOOR" draggable={true} onDragStart={(event) => onDragStart(event, 'default')}>*/}
        {/*    <div style={styles.parent}>*/}
        {/*        <div style={styles.iconParent}><TbStairsUp size="20" color="rgb(100, 100, 100)"/></div>*/}
        {/*        UPDATE FLOOR*/}
        {/*    </div>*/}
        {/*</div>*/}

        {/*<div id="AMR|CHECK" draggable={true} onDragStart={(event) => onDragStart(event, 'default')}>*/}
        {/*    <div style={styles.parent}>*/}
        {/*        <div style={styles.iconParent}><FaListCheck size="20" color="rgb(100, 100, 100)"/></div>*/}
        {/*        CHECK*/}
        {/*    </div>*/}
        {/*</div>*/}

        {/*<div id="AMR|CHECK SELECT" draggable={true} onDragStart={(event) => onDragStart(event, 'default')}>*/}
        {/*    <div style={styles.parent}>*/}
        {/*        <div style={styles.iconParent}><MdLibraryAddCheck size="20" color="rgb(100, 100, 100)"/></div>*/}
        {/*        CHECK SELECT*/}
        {/*    </div>*/}
        {/*</div>*/}

        {/*<div style={styles.sidebarItemHeadingContainer}>*/}
        {/*    <div style={{display: "flex"}}><AiOutlineBars size="20" color="rgb(100, 100, 100)"/>*/}
        {/*        <span style={styles.sidebarItemHeading}> ELV </span>*/}
        {/*    </div>*/}
        {/*</div>*/}

        {/*<div id="ELV|HALL CALL" draggable={true} onDragStart={(event) => onDragStart(event, 'default')}>*/}
        {/*    <div style={styles.parent}>*/}
        {/*        <div style={styles.iconParent}><PiElevatorFill size="20" color="rgb(100, 100, 100)"/></div>*/}
        {/*        HALL CALL*/}
        {/*    </div>*/}
        {/*</div>*/}

        {/*<div id="ELV|CAR CALL" draggable={true} onDragStart={(event) => onDragStart(event, 'default')}>*/}
        {/*    <div style={styles.parent}>*/}
        {/*        <div style={styles.iconParent}><FaElevator size="20" color="rgb(100, 100, 100)"/></div>*/}
        {/*        CAR CALL*/}
        {/*    </div>*/}
        {/*</div>*/}

        {/*<div id="ELV|OPEN DOOR" draggable={true} onDragStart={(event) => onDragStart(event, 'default')}>*/}
        {/*    <div style={styles.parent}>*/}
        {/*        <div style={styles.iconParent}><BiDoorOpen size="20" color="rgb(100, 100, 100)"/></div>*/}
        {/*        OPEN DOOR*/}
        {/*    </div>*/}
        {/*</div>*/}

        {/*<div id="ELV|KEEP OPEN" draggable={true} onDragStart={(event) => onDragStart(event, 'default')}>*/}
        {/*    <div style={styles.parent}>*/}
        {/*        <div style={styles.iconParent}><RiDoorClosedLine size="20" color="rgb(100, 100, 100)"/></div>*/}
        {/*        KEEP OPEN*/}
        {/*    </div>*/}
        {/*</div>*/}

        {/*<div id="ELV|CHECK" draggable={true} onDragStart={(event) => onDragStart(event, 'default')}>*/}
        {/*    <div style={styles.parent}>*/}
        {/*        <div style={styles.iconParent}><FaListCheck size="20" color="rgb(100, 100, 100)"/></div>*/}
        {/*        CHECK*/}
        {/*    </div>*/}
        {/*</div>*/}

        {/*<div id="ELV|CHECK SELECT" draggable={true} onDragStart={(event) => onDragStart(event, 'default')}>*/}
        {/*    <div style={styles.parent}>*/}
        {/*        <div style={styles.iconParent}><MdLibraryAddCheck size="20" color="rgb(100, 100, 100)"/></div>*/}
        {/*        CHECK SELECT*/}
        {/*    </div>*/}
        {/*</div>*/}

        {/*<div style={styles.sidebarItemHeadingContainer}>*/}
        {/*    <div style={{display: "flex"}}><AiOutlineBars size="20" color="rgb(100, 100, 100)"/>*/}
        {/*        <span style={styles.sidebarItemHeading}> PLC </span>*/}
        {/*    </div>*/}
        {/*</div>*/}

        {/*<div id="PLC|SET VALUE" draggable={true} onDragStart={(event) => onDragStart(event, 'default')}>*/}
        {/*    <div style={styles.parent}>*/}
        {/*        <div style={styles.iconParent}><BsDatabaseFillCheck size="20" color="rgb(100, 100, 100)"/></div>*/}
        {/*        SET VALUE*/}
        {/*    </div>*/}
        {/*</div>*/}

        {/*<div id="PLC|CHECK" draggable={true} onDragStart={(event) => onDragStart(event, 'default')}>*/}
        {/*    <div style={styles.parent}>*/}
        {/*        <div style={styles.iconParent}><FaListCheck size="20" color="rgb(100, 100, 100)"/></div>*/}
        {/*        CHECK*/}
        {/*    </div>*/}
        {/*</div>*/}

        {/*<div id="PLC|CHECK SELECT" draggable={true} onDragStart={(event) => onDragStart(event, 'default')}>*/}
        {/*    <div style={styles.parent}>*/}
        {/*        <div style={styles.iconParent}><MdLibraryAddCheck size="20" color="rgb(100, 100, 100)"/></div>*/}
        {/*        CHECK SELECT*/}
        {/*    </div>*/}
        {/*</div>*/}
      </aside>
  );
};

export default ItemBuilder;

const styles = {
  parent: {
    padding: 7,
    fontSize: 14,
    background: '#fff',
    cursor: 'move',
    marginTop: 10,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #ddd',
    fontFamily: 'Gmarket Sans, Medium'
  },
  iconParent: {
    borderRight: '1px solid rgb(230, 230, 230)',
    padding: 5,
    paddingRight: 10,
    paddingLeft: 5,
    marginRight: 10,
  },
  splitIcon: {
    transform: 'rotate(90deg)',
  },
  joinIcon: {
    transform: 'rotate(-90deg)',
  },
  sidebarItemHeading: {
    fontSize: 16,
    fontWeight: 'bold',
    marginLeft: '10px',
    lineHeight: 'normal'
  },
  sidebarItemHeadingContainer: {
    marginTop: 24,
    marginBottom: 12
  },
}
