import { User } from "firebase/auth";
import { create } from "zustand";
import { TUser } from "../types";

interface UserData {
  isShowUserIcon: boolean;
  userStatus: number | null;
  // userAuthSites: { name: string; id: string }[];
  selectedUserAuthSite: string[];
  bookmarkDevices: string[];
  googleUserInfo: User | null;
  userInfo: TUser | null;
  updateIsShowUserIcon: (input: boolean) => void;
  changeUserStatus: (input: number) => void;
  // changeUserAuthSites: (input: { name: string; id: string }[]) => void;
  changeSelectedUserAuthSites: (input: string[]) => void;
  updateBookmarkDevices: (input: string[]) => void;
  updateGoogleUserInfo: (input: User) => void;
  updateUserInfo: (input: TUser | null) => void;
}

const useUserStore = create<UserData>()((set) => ({
  isShowUserIcon: false,
  userStatus: null,
  // userAuthSites: [],
  selectedUserAuthSite: [],
  bookmarkDevices: [],
  googleUserInfo: null,
  userInfo: null,
  updateIsShowUserIcon: (input) =>
    set((state) => ({
      isShowUserIcon: input,
    })),
  changeUserStatus: (input) =>
    set((state) => ({
      userStatus: input,
    })),
  // changeUserAuthSites: (input) =>
  //   set((state) => ({
  //     userAuthSites: input,
  //   })),
  changeSelectedUserAuthSites: (input) =>
    set((state) => ({
      selectedUserAuthSite: input,
    })),
  updateBookmarkDevices: (input) =>
    set((state) => ({
      bookmarkDevices: input,
    })),
  updateGoogleUserInfo: (input) =>
    set(() => ({
      googleUserInfo: input,
    })),
  updateUserInfo: (input) =>
    set(() => ({
      userInfo: input,
    })),
}));

export default useUserStore;
