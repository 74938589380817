import workflowNodeJson from "../json/workflowNode.json";

export const workflowParser = (nodes: any, edges: any, robotInfo: any, selectedUserAuthSite: string, type: string | null) => {

  const jsonData: any[] = [];

  let startEndId: string[] = [];

  nodes.forEach((item: any, idx: number) => {
    if (item.data.label.props.action === "END") {
      startEndId.push(item.id);
    }
  });


  nodes.forEach((item: any, idx: number) => {
    if (item.data.label.props.type !== "START/END") {
      const data = nodeParser(item, item.data.label.props.fullName, edges, robotInfo, selectedUserAuthSite, type, startEndId, idx);
      jsonData.push(data);
    }
  });

  return jsonData;
}

export const nodeParser = (item: any, action: string, edges: any, robotInfo: any, selectedUserAuthSite: string, type: string | null, startEndId: string[], idx: number) => {


  const key = action.split('|');

  console.log('action = ', action)


  switch (key[1]) {
    case "WAIT" : {
      const nodeJson = workflowNodeJson[key[1]];
      const json = JSON.parse(JSON.stringify(nodeJson));

      if (type === 'TASK' || robotInfo) {
        json.message.deviceId = robotInfo.deviceId;
      }

      json.message.command_name = item.id;
      json.message.device_type = 'TIME';

      if (item.variable !== null && item.variable !== undefined) {
        json.message.action.param.wait_time = item.variable[0].value;
      }

      json.message.transition = transitionData(item, edges, startEndId);


      return json;
      break;
    }
    case "ON_TIME": {
      const nodeJson = workflowNodeJson[key[1]];
      const json = JSON.parse(JSON.stringify(nodeJson));

      if (type === 'TASK' || robotInfo) {
        json.message.deviceId = robotInfo.deviceId;
      }

      json.message.command_name = item.id;
      json.message.device_type = 'TIME';

      if (item.variable !== null && item.variable !== undefined) {
        json.message.action.param.on_time = item.variable[0].value;
      }
      const transition = [];
      let cnt = 0;


      json.message.transition = transitionData(item, edges, startEndId);

      return json;
      break;
    }

    case "MOVE" : {
      const nodeJson = workflowNodeJson[key[1]];
      const json = JSON.parse(JSON.stringify(nodeJson));

      if (type === 'TASK' || robotInfo) {
        json.message.deviceId = robotInfo.deviceId;
      }

      json.message.command_name = item.id;
      json.message.device_type = key[0];

      if (item.variable !== null && item.variable !== undefined) {
        json.message.action.param.poiId = item.variable[0].value;
      }
      json.message.transition = transitionData(item, edges, startEndId);

      return json;
      break;
    }

    case "CHARGE" : {
      const nodeJson = workflowNodeJson[key[1]];
      const json = JSON.parse(JSON.stringify(nodeJson));

      if (type === 'TASK' || robotInfo) {
        json.message.deviceId = robotInfo.deviceId;
      }

      json.message.command_name = item.id;
      json.message.device_type = key[0];

      json.message.action.param = {};

      json.message.transition = transitionData(item, edges, startEndId);

      return json;


      break;
    }

    case "CHANGE_MAP": {

      const nodeJson = workflowNodeJson[key[1]];
      const json = JSON.parse(JSON.stringify(nodeJson));

      if (type === 'TASK' || robotInfo) {
        json.message.deviceId = robotInfo.deviceId;
      }

      json.message.command_name = item.id;
      json.message.device_type = key[0];

      if (robotInfo.standardLocation !== null && robotInfo.standardLocation !== undefined) {
        json.message.action.param = {
          mapId: robotInfo.standardLocation.mapId,
          poseX: "0.0",
          poseY: "0.0",
          poseTheta: "0.0"
        };
      }

      json.message.transition = transitionData(item, edges, startEndId);


      return json;
      break;
    }

    case "HALL_CALL" : {

      const nodeJson = workflowNodeJson[key[1]];
      const json = JSON.parse(JSON.stringify(nodeJson));
      if (type === 'TASK' || robotInfo) {
        json.message.deviceId = robotInfo.deviceId;
      }

      json.message.command_name = item.id;
      json.message.device_type = key[0];

      if (item.startingFloor !== undefined && item.targetFloor !== undefined) {
        json.message.action.param = {
          groupId: "1",
          carId: "1",
          startingFloor: item.startingFloor[0].value,
          targetFloor: item.targetFloor[0].value,
          doorSide: "FRONT"
        };
      }

      json.message.transition = transitionData(item, edges, startEndId);

      return json;
      break;
    }

    case "CAR_CALL" : {

      const nodeJson = workflowNodeJson[key[1]];
      const json = JSON.parse(JSON.stringify(nodeJson));
      if (type === 'TASK' || robotInfo) {
        json.message.deviceId = robotInfo.deviceId;
      }

      json.message.command_name = item.id;
      json.message.device_type = key[0];

      if (item.variable !== null && item.variable !== undefined) {
        json.message.action.param = {
          groupId: "1",
          carId: "1",
          targetFloor: item.variable[0].value,
          doorSide: "FRONT"
        };
      }

      json.message.transition = transitionData(item, edges, startEndId);

      return json;
      break;
    }

    case "CLEAN" : {

      const nodeJson = workflowNodeJson[key[1]];
      const json = JSON.parse(JSON.stringify(nodeJson));
      if (type === 'TASK' || robotInfo) {
        json.message.deviceId = robotInfo.deviceId;
      }

      json.message.command_name = item.id;
      json.message.device_type = key[0];

      if (item.task[0].data.siteId !== undefined && item.task[0].data.mapId !== undefined && item.task[0].data.taskId !== undefined) {
        json.message.action.param = {
          executableTask: {
            siteId: item.task[0].data.siteId,
            siteName: item.task[0].data.siteName,
            mapId: item.task[0].data.mapId,
            mapName: item.task[0].data.mapName,
            taskId: item.task[0].data.taskId,
            taskName: item.task[0].data.taskName
          },
          cleaningMode: item.mode[0].value
        };
      }

      json.message.transition = transitionData(item, edges, startEndId);

      return json;
      break;
    }

    case "OPEN_DOOR" : {
      const nodeJson = workflowNodeJson[key[1]];
      const json = JSON.parse(JSON.stringify(nodeJson));
      if (type === 'TASK' || robotInfo) {
        json.message.deviceId = robotInfo.deviceId;
      }

      json.message.command_name = item.id;
      json.message.device_type = key[0];

      if (item.variable !== null && item.variable !== undefined) {
        json.message.action.param = {
          "groupId": "1",
          "carId": "1",
          "doorSide": "FRONT"
        };
      }

      json.message.transition = transitionData(item, edges, startEndId);

      return json;
      break;
    }
    case "ROBOT ELV MOVE" : {
      const nodeJson = workflowNodeJson[key[1]];
      const json = JSON.parse(JSON.stringify(nodeJson));
      if (type === 'TASK' || robotInfo) {
        json.message.deviceId = robotInfo.deviceId;
      }

      json.message.command_name = item.id;
      json.message.device_type = key[0];

      if (item.selectElv !== undefined && robotInfo && item.insidePoi && item.outPoi && item.startingFloor && item.targetFloor) {
        json.message.action.param = {
          deviceIdElv: item.selectElv[0].value,
          deviceIdRobot: robotInfo.deviceId,
          insidePoi: item.insidePoi[0].value,
          outPoi: item.outPoi[0].value,
          startingFloor: item.startingFloor[0].value2,
          targetFloor: item.targetFloor[0].value2,
          elvStartingFloor: item.startingFloor[0].value1,
          elvTargetFloor: item.targetFloor[0].value1
        };
      }

      json.message.transition = transitionData(item, edges, startEndId);

      return json;
      break;
    }

    case "CONVEYOR CONTROL" : {
      const nodeJson = workflowNodeJson[key[1]];
      const json = JSON.parse(JSON.stringify(nodeJson));
      if (type === 'TASK' || robotInfo) {
        json.message.deviceId = robotInfo.deviceId;
      }

      json.message.command_name = item.id;
      json.message.device_type = key[0];

      json.message.transition = transitionData(item, edges, startEndId);

      return json;
      break;
    }

    case "CHECK" : {
      const nodeJson = workflowNodeJson[key[1]];
      const json = JSON.parse(JSON.stringify(nodeJson));
      if (type === 'TASK' || robotInfo) {
        json.message.deviceId = robotInfo.deviceId;
      }

      json.message.command_name = item.id;
      json.message.device_type = key[0];

      json.message.action.param.type = 'simple';
      json.message.action.param.conditions = [];

      if (item.condition !== null && item.condition !== undefined) {

        if (item.condition.length > 0) {
          item.condition.forEach((data: any, i: number) => {
            json.message.action.param.conditions.push({
              'name': data.name,
              'operator': data.operator,
              'value': data.value
            })
          })
        }

      }

      json.message.transition = transitionData(item, edges, startEndId);
      return json;
      break;
    }

    case "CHECK_SELECT" : {

      const nodeJson = workflowNodeJson[key[1]];
      const json = JSON.parse(JSON.stringify(nodeJson));
      if (type === 'TASK' || robotInfo) {
        json.message.deviceId = robotInfo.deviceId;
      }

      json.message.command_name = item.id;
      json.message.device_type = key[0];

      json.message.action.param.type = 'simple';
      json.message.action.param.conditions = [];
      //
      // if (item.condition !== null && item.condition !== undefined) {
      //
      //     if (item.condition.length > 0) {
      //         item.condition.forEach((data: any, i: number) => {
      //             json.message.action.param.conditions.push({
      //                 'name': data.name,
      //                 'operator': data.operator,
      //                 'value': data.value
      //             })
      //         })
      //     }
      //
      // }

      json.message.transition = transitionData(item, edges, startEndId);

      return json;
      break;
    }

    default: {
      break;
    }
  }
}


const transitionData = (item: any, edges: any, startEndId: string[]) => {

  const transition = [];
  let cnt = 0;
  edges.forEach((data: any, idx: number) => {
    if (data.source === item.id) {
      cnt++;
      transition.push({
        "trigger": "next",
        "source": data.source,
        "dest": startEndId.some((el) => el === data.target) ? "complete" : data.target
      })
    }
  });

  if (cnt === 0) {
    transition.push({
      "trigger": "next",
      "source": item.id,
      "dest": "complete"
    })
  }

  return transition
}


export const actionIndexParser = (nodeList: any, edgeList: any) => {

  const copyNodes = JSON.parse(JSON.stringify(nodeList));
  const copyEdges = JSON.parse(JSON.stringify(edgeList));
  // const copyJsonData = JSON.parse(JSON.stringify(json));

  const start = copyNodes.filter((item: any) => item.type === 'input');
  const targetList: any[] = [start[0].id];
  const newNodes: any[] = [];

  for (const data of targetList) {
    for (const item of copyEdges) {
      if (item.source === data) {
        targetList.push(item.target);
      }
    }
  }

  targetList.forEach((item: any, i: number) => {
    const filterData = copyNodes.filter((data: any) => data.id === item);
    newNodes.push(filterData[0]);
  });

  // let checkSelectId: string | null = null;
  // let preCheckSelectId: string | null = null;
  // let checkSelectCnt: number = 0;
  //
  // if (newNodes.length > 0) {
  //     newNodes.forEach((item: any, i: number) => {
  //         item.data.label.props.action === 'CHECK_SELECT' ? preCheckSelectId = item.id : null;
  //
  //         item.id = `action${i + 1}`;
  //
  //         if (item.data.label.props.action === 'CHECK_SELECT') {
  //             checkSelectId = item.id;
  //         }
  //
  //     });
  // }
  //
  // const copy2Edges = JSON.parse(JSON.stringify(edgeList));
  // const checkSelectLine = copy2Edges.filter((el: any) => el.source === preCheckSelectId);
  //
  // checkSelectCnt = checkSelectLine.length;
  //
  // let sourceNum = 1;
  //
  // copyEdges.forEach((item: any, idx: number) => {
  //     if (checkSelectId !== null) {
  //
  //         if (item.source === preCheckSelectId) { //순서대로 노드번호를 수정 후 check_select 노드 다음 cnt수 만큼...
  //             item.source = checkSelectId;
  //
  //             const checkSelectNum = Number(checkSelectId.replace('action', ''));
  //             item.target = `action${checkSelectNum + sourceNum}`;
  //
  //             if (sourceNum < checkSelectCnt) {
  //                 sourceNum++;
  //             }
  //         } else {
  //             item.source = `action${idx + 1}`;
  //             item.target = `action${idx + 2}`;
  //         }
  //
  //     }
  //
  // });
  //
  // const copy3Edges = JSON.parse(JSON.stringify(copyEdges));
  // const checkSelectList = copy3Edges.filter((el: any) => el.source === checkSelectId);
  //
  // let targetNum = 1;
  // copy3Edges.forEach((item: any, idx: number) => {
  //     if (checkSelectId !== null) {
  //         const actionNum = Number(item.source.replace('action', ''));
  //         const checkSelectNum = Number(checkSelectId.replace('action', ''));
  //     }
  // });


  const newNewNodes = JSON.parse(JSON.stringify(newNodes));
  const newNewEdges = JSON.parse(JSON.stringify(copyEdges));

  console.log('newNewNodes =', newNewNodes)
  console.log('newNewEdges =', newNewEdges)

  return {newNewNodes, newNewEdges};
}