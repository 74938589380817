import { useTranslation } from "react-i18next";
import { useAuth, useRobotData, useUserData } from "../shared/hooks";

import { DeviceDataType } from "../shared/types";

import { useEffect, useState } from "react";
import { Modal, ModalPortal, InquiryModal } from "../shared/ui/Modal";

import { getUserBookmark } from "../features/bookmark/api/bookmark";
import BookmarkCard from "../features/bookmark/ui/BookmarkCard";

const BookmarkPage = () => {
  const { t } = useTranslation();

  const { user } = useUserData();
  const { hasAuthSite, hasAuthRobot, login } = useAuth();
  const { syncRobotList } = useRobotData(user);

  const [bookmarkDevices, setBookmarkDevices] = useState<string[]>([]);
  const [bookmarkData, setBookmarkData] = useState<DeviceDataType[]>([]);

  const fetchUserBookmark = async () => {
    const { bookmarkedDeviceIds } = await getUserBookmark(user?.id);
    setBookmarkDevices(bookmarkedDeviceIds);
  };

  useEffect(() => {
    fetchUserBookmark();
  }, [user]);

  useEffect(() => {
    if (syncRobotList && user) {
      setBookmarkData(
        (syncRobotList ?? []).filter((item) =>
          (bookmarkDevices ?? []).includes(item.deviceId)
        )
      );
    }
  }, [syncRobotList, user, bookmarkDevices]);

  return (
    <div className="w-[100%] pl-[75px] bg-neutral-50">
      <div className="fixed top-0 z-20 w-full pt-10 pb-3 pl-12 text-2xl text-gray-700 bg-neutral-50">
        {t("bookmark")}
      </div>
      <section
        className="pt-[90px] flex items-center justify-between pb-3 pl-12"
        data-name="bg"
      ></section>

      <section className="flex flex-wrap justify-between px-12 pt-3 pb-10">
        {bookmarkData.length > 0 ? (
          bookmarkData.map((item, idx) => (
            <BookmarkCard
              key={idx}
              robotData={item}
              fetchUserBookmark={fetchUserBookmark}
            />
          ))
        ) : (
          <div className="flex flex-col items-center justify-center w-full h-[65vh] gap-5 pt-10">
            <span className="pb-5 text-3xl font-semibold text-gray-400">
              {t("No_bookmarks_found")}
            </span>
          </div>
        )}
      </section>

      <ModalPortal>
        <Modal />
      </ModalPortal>
      {(hasAuthSite === "false" || hasAuthRobot === "false") &&
        !(login === "false") && <InquiryModal />}
    </div>
  );
};

export default BookmarkPage;
