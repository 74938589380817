import axios from "axios";
import { getCookie } from "../../../shared/util/cookie";
import { TSignupParams } from "../../../shared/types";

const baseUrl =
  process.env.REACT_APP_ENV === "development"
    ? "https://admin-api-staging.sollink.ai"
    : "https://admin-api.sollink.ai";

export async function changePassword(uid: string, password: string) {
  return axios.post(
    "https://asia-northeast3-sollink-f2425.cloudfunctions.net/api/auth/accounts/change-password",
    //"http://127.0.0.1:5001/sollink-f2425/asia-northeast3/api/auth/accounts/change-password",
    {
      uid,
      password,
    }
  );
}

const create = async (params: TSignupParams) => {
  try {
    const res = await axios.post(`${baseUrl}/user/signup`, params);
    return res.data;
  } catch (error) {
    throw new Error("네트워크 에러");
  }
};

const checkExistUser = async () => {
  try {
    const res = await axios.get(`${baseUrl}/user/check-user`, {
      headers: {
        Authorization: `Bearer ${getCookie("idToken")}`,
      },
    });
    return res.data;
  } catch (error) {
    throw new Error("네트워크 에러");
  }
};

const checkExistEmail = async (params: { email: string }) => {
  try {
    const res = await axios.get(`${baseUrl}/user/check-email-user-exist`, {
      params,
    });
    return res.data;
  } catch (error) {
    throw new Error("네트워크 에러");
  }
};

export { create, checkExistUser, checkExistEmail };
