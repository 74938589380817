import { PostJobOrderType, ScheduleSocketData } from "../../../shared/types";
import { create } from "zustand";

interface ScheduleData {
  scheduleData: ScheduleSocketData[] | null;
  siteScheduleData: [];
  scheduleId: string | null;
  deviceId: string | null;
  robotCommand: PostJobOrderType | null;
  workStartDate: string | null;
  updateSiteScheduleData: (inputData: []) => void;
  updateScheduleData: (data: ScheduleSocketData[]) => void;
  updateScheduleId: (inputData: string | null) => void;
  updateDeviceId: (inputData: string | null) => void;
  updateRobotCommand: (inputData: PostJobOrderType | null) => void;
  updateWorkStartDate: (inputData: string | null) => void;
}

const useScheduleStore = create<ScheduleData>()((set) => ({
  scheduleData: null,
  siteScheduleData: [],
  scheduleId: null,
  deviceId: null,
  robotCommand: null,
  workStartDate: null,
  updateScheduleData: (data) =>
    set(() => ({
      scheduleData: data,
    })),
  updateSiteScheduleData(inputData) {
    set((state) => ({
      siteScheduleData: inputData,
    }));
  },
  updateScheduleId(inputData) {
    set((state) => ({
      scheduleId: inputData,
    }));
  },
  updateDeviceId(inputData) {
    set((state) => ({
      deviceId: inputData,
    }));
  },
  updateRobotCommand(inputData) {
    set((state) => ({
      robotCommand: inputData,
    }));
  },
  updateWorkStartDate(inputData) {
    set((state) => ({
      workStartDate: inputData,
    }));
  },
}));

export default useScheduleStore;
