import { useLoginStore } from "../../../features/login/model";
import { Gnb } from "../../../entities/gnb/ui";
import UserIcon from "../../../entities/user/ui/UserIcon";
import LoginModal from "../../../features/login/ui/LoginModal";
import { useLocation } from "react-router-dom";

const LayoutWrapper = () => {
  const { pathname } = useLocation();
  const { isShowLoginModal } = useLoginStore();
  return (
    <>
      {isShowLoginModal && <LoginModal />}
      <UserIcon />
      {pathname !== "/" &&
        pathname !== "/verification" &&
        pathname !== "/signup" && <Gnb />}
    </>
  );
};

export default LayoutWrapper;
