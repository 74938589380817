import dayjs from "dayjs";
import { useEffect, useState } from "react";
import useRobotstore from "../../../shared/store/robotStore";
import { Trans } from "react-i18next";
import { IoStarOutline, IoStarSharp } from "react-icons/io5";

import { DeviceDataType } from "../../../shared/types";
import { SiteType } from "../../../shared/types/site";

import { toast } from "react-toastify";
import {
  deleteUserBookmark,
  getUserBookmark,
  postUserBookmark,
} from "../../../features/bookmark/api/bookmark";
import { TableSkeleton } from "../../../shared/ui/Skeleton";
import { useAuth, useUserData } from "../../../shared/hooks";

// TODO: 데이터 구조 확정되면 타입에 맞게 수정하기
interface RobotdivProps {
  selectRobotType: string;
  selectRobotName: string;
  selectedSite: string;
  siteList: SiteType[];
  syncRobotList: DeviceDataType[];
}

export default function RobotTable({
  selectRobotType,
  selectRobotName,
  selectedSite,
  siteList,
  syncRobotList,
}: RobotdivProps) {
  const {
    changeSelectedRobot,
    updateIsShowRobotDetail,
    changeSelectedRobotDetailData,
  } = useRobotstore();

  const [showModal, setShowModal] = useState(false);
  const [modalPosition, setModalPosition] = useState<[number, number]>([0, 0]);
  const [modalData, setModalData] = useState<string | undefined>();
  const [filteredRobotList, setFilteredRobotList] = useState<DeviceDataType[]>(
    []
  );
  const [scroll, setScroll] = useState(0);
  const [bookmarkHoverIdx, setBookmarkHoverIdx] = useState<number | null>(null);
  const [bookmarkDevices, setBookmarkDevices] = useState<string[]>([]);

  const { user } = useUserData();

  const { login } = useAuth();

  const fetchUserBookmark = async () => {
    const { bookmarkedDeviceIds } = await getUserBookmark(user?.id);
    setBookmarkDevices(bookmarkedDeviceIds);
  };

  useEffect(() => {
    fetchUserBookmark();
  }, [user]);

  useEffect(() => {
    window.addEventListener("scroll", () => setScroll(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setScroll(window.scrollY));
    };
  }, []);

  useEffect(() => {
    if (!syncRobotList) return;

    if (
      !selectedSite &&
      selectRobotType === "로봇 타입 전체" &&
      !selectRobotName
    )
      return setFilteredRobotList(syncRobotList);

    const newRobots = syncRobotList.filter(robotMatcher);
    setFilteredRobotList(newRobots);
  }, [selectedSite, syncRobotList, selectRobotType, selectRobotName]);

  const handleBookmarkBtn = async ({ deviceId }: { deviceId: string }) => {
    if (bookmarkDevices?.includes(deviceId)) {
      await deleteUserBookmark({ userUuid: user?.id, deviceId });
      fetchUserBookmark();
      return toast.success("즐겨찾기가 제거되었습니다.");
    }
    await postUserBookmark({ userUuid: user?.id, deviceId });
    fetchUserBookmark();
    toast.success("즐겨찾기가 추가되었습니다.");
  };

  const openMenu = (event: any) => {
    const target = event.target.parentElement.dataset;
    if (target.name === "list") {
      const data = JSON.parse(target.detail);
      changeSelectedRobot(data.deviceId);
      changeSelectedRobotDetailData(data);
      updateIsShowRobotDetail(true);
    }
  };

  const robotMatcher = (robot: DeviceDataType) => {
    if (!selectedSite && !selectRobotType && !selectRobotName) {
      return robot;
    }

    if (selectedSite && !selectRobotType && !selectRobotName) {
      return robot.siteId === selectedSite;
    }

    if (!selectedSite && selectRobotType && !selectRobotName) {
      return robot.modelType === selectRobotType;
    }

    if (!selectedSite && !selectRobotType && selectRobotName) {
      return robot.name.includes(selectRobotName);
    }

    if (selectedSite && selectRobotType && !selectRobotName) {
      return (
        robot.siteId === selectedSite && robot.modelType === selectRobotType
      );
    }

    if (selectedSite && !selectRobotType && selectRobotName) {
      return (
        robot.siteId === selectedSite && robot.name.includes(selectRobotName)
      );
    }

    if (!selectedSite && selectRobotType && selectRobotName) {
      return (
        robot.modelType === selectRobotType &&
        robot.name.includes(selectRobotName)
      );
    }

    return (
      robot.siteId === selectedSite &&
      robot.modelType === selectRobotType &&
      robot.name.includes(selectRobotName)
    );
  };

  return (
    <div
      className="pt-3 pb-7"
      onMouseMove={(event) => {
        setModalPosition([event.clientX, event.clientY + scroll]);
      }}
      onMouseLeave={(event) => {
        setModalPosition([event.clientX, event.clientY]);
        setShowModal(false);
      }}
    >
      {showModal && modalPosition[0] !== 0 && (
        <div
          className="bg-[#2c3e50] p-2 text-white text-xs rounded-xl"
          style={{
            position: "absolute",
            left: `${modalPosition[0]}px`,
            top: `${modalPosition[1] + 40}px`,
          }}
        >
          최근 업데이트:{" "}
          {modalData ? dayjs(modalData).format("MM-DD HH:mm:ss") : "-"}
        </div>
      )}

      <div className="text-center text-sm w-[98%]">
        <div className="flex items-center font-bold text-center shadow-sm bg-neutral-200 rounded-xl">
          <div className="py-3 w-[7%]">
            <Trans i18nKey="bookmark" />
          </div>
          <div className="py-3 w-[11%]">
            <Trans i18nKey="robot_type" />
          </div>
          <div className="py-3 w-[25%]">
            <Trans i18nKey="robot_name" />
          </div>
          <div className="px-2 py-3 w-[10%]">
            <Trans i18nKey="status" />
          </div>
          <div className="py-3 w-[23%]">
            <Trans i18nKey="current_task" />
          </div>
          <div className="py-3 w-[12%]">
            <Trans i18nKey="estimated_work_time" /> (
            <Trans i18nKey="second" />)
          </div>
          <div className="py-3 w-[12%]">
            <Trans i18nKey="battery_level" /> (%)
          </div>
        </div>

        <div>
          {syncRobotList && syncRobotList[0]?.modelType
            ? Array.isArray(filteredRobotList) &&
              filteredRobotList.map((item: any, index: number) => (
                <div
                  key={index}
                  onClick={openMenu}
                  data-detail={JSON.stringify(item)}
                  data-name="list"
                  className={`flex items-center bg-white hover:cursor-pointer hover:bg-[#d3ece8] ${
                    localStorage.login === "true" &&
                    localStorage.verified === "true"
                      ? "justify-center mt-1 rounded-xl shadow-sm"
                      : "blur-sm "
                  }`}
                >
                  <div className="py-3 w-[7%]">
                    <button
                      onMouseEnter={() => setBookmarkHoverIdx(index)}
                      onMouseLeave={() => setBookmarkHoverIdx(null)}
                      onClick={() => handleBookmarkBtn(item)}
                    >
                      {bookmarkDevices?.includes(item.deviceId) ||
                      index === bookmarkHoverIdx ? (
                        <IoStarSharp size="24" color="#F6C36B" />
                      ) : (
                        <IoStarOutline
                          size="24"
                          style={{
                            filter:
                              "invert(90%) sepia(84%) saturate(948%) hue-rotate(308deg) brightness(98%) contrast(98%)",
                          }}
                        />
                      )}
                    </button>
                  </div>
                  <div className="py-3 w-[11%]">{item?.modelType ?? "-"}</div>
                  <div className="py-3 w-[25%]">{item?.name ?? "-"}</div>
                  <div
                    className="w-[10%] flex justify-center"
                    onMouseOver={() => {
                      setModalData(item.lastUpdateTime);
                      setShowModal(true);
                    }}
                    onMouseLeave={() => {
                      setShowModal(false);
                    }}
                  >
                    {item?.robotStateColor === "RED" && (
                      <div className="text-xl text-red-500">●</div>
                    )}
                    {item?.robotStateColor === "GREEN" && (
                      <div className="text-xl text-emerald-500">●</div>
                    )}
                    {item?.robotStateColor === "ORANGE" && (
                      <div className="text-xl text-orange-400">●</div>
                    )}
                    {item?.robotStateColor === "GRAY" && (
                      <div className="text-xl text-gray-400">●</div>
                    )}
                    {!item.robotStateColor && "-"}
                  </div>

                  <div
                    className="py-3 w-[23%]"
                    onMouseOver={() => {
                      setModalData(item?.lastUpdateTime ?? "-");
                      setShowModal(true);
                    }}
                    onMouseLeave={() => {
                      setShowModal(false);
                    }}
                  >
                    {item?.robotStateString ?? "-"}
                  </div>

                  <div className="py-3 w-[12%]">
                    {item?.standardLocation &&
                    item.standardLocation.remainExpectedTime
                      ? Math.floor(
                          Number(item.standardLocation.remainExpectedTime)
                        )
                      : "-"}
                  </div>

                  <div className="py-3 w-[12%]">{item.batteryLevel ?? "-"}</div>
                </div>
              ))
            : !(login === "false") && (
                <div className="mt-2">
                  <TableSkeleton />
                </div>
              )}
        </div>
      </div>
    </div>
  );
}
