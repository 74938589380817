const { version, buildNumber } = require("../../../package.json");

export const LOCALE = "ko";

export const PAGE_VIEW_CNT_ERROR_ROBOT = 6;
export const PAGE_VIEW_CNT_POI = 6;
export const PAGE_VIEW_CNT_USED_ROBOT = 8;

export const REST_API_URL =
  process.env.REACT_APP_REST_API_URL ?? "https://staging-api.sollink.ai";

export const APP_VERSION = version;
export const APP_BUILD_NUMBER = buildNumber;
