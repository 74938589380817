import Skeleton from "./Skeleton";

const TableSkeleton = () => {
  return (
    <>
      {Array.from({ length: 10 }, (_, i) => (
        <div
          key={i}
          className="flex items-center justify-between w-full py-2 my-2 bg-white rounded-xl"
        >
          <div className="w-[11%] flex justify-center py-1">
            <Skeleton borderRadius={5} className="w-[85%] h-[18px]" />
          </div>
          <div className="w-[25%] flex justify-center">
            <Skeleton borderRadius={5} className="w-[85%] h-[18px]" />
          </div>
          <div className="px-2 w-[10%] flex justify-center">
            <Skeleton borderRadius={5} className="w-[85%] h-[18px]" />
          </div>
          <div className="w-[30%] flex justify-center">
            <Skeleton borderRadius={5} className="w-[85%] h-[18px]" />
          </div>
          <div className="w-[12%] flex justify-center">
            <Skeleton borderRadius={5} className="w-[85%] h-[18px]" />
          </div>
          <div className="w-[12%] flex justify-center">
            <Skeleton borderRadius={5} className="w-[85%] h-[18px]" />
          </div>
        </div>
      ))}
    </>
  );
};

export default TableSkeleton;
