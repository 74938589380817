import axios from "axios";

const createAxiosInstance = (baseUrl: string) => {
  const instance = axios.create({
    baseURL: baseUrl,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    withCredentials: true,
  });

  instance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response) {
        console.error(error.response.data);

        if (error.response.status === 400 || error.response.status === 500) {
          console.error(error.response.data);
        }

        return Promise.reject(error);
      } else if (error.request) {
        console.error("No response received from the server");
      } else {
        console.error("Error setting up the request:", error.message);
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

const client = createAxiosInstance(
  process.env.REACT_APP_REST_API_URL ?? "https://staging-api.sollink.ai"
);
const isClient = createAxiosInstance(
  process.env.REACT_APP_IS_REST_API_URL ?? "https://is-staging.sollink.ai"
);

export const baseApi = { client, isClient };
