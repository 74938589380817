import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import logo from "../shared/assets/image/SOLlink.png";
import { useTranslation } from "react-i18next";
import { Checkbox } from "antd";
import { TSignupParams, TSignupInputParams } from "../shared/types";
import { checkExistEmail, create } from "../features/login/api";
import { toast } from "react-toastify";
import { removeCookie } from "../shared/util/cookie";
import { signOut } from "firebase/auth";
import useUserStore from "../shared/store/userStore";
import useLoginStore from "../features/login/model/loginStore";
import { auth } from "../shared/lib";
import { useNavigate } from "react-router-dom";
import { CheckboxChangeEvent } from "antd/es/checkbox";

export default function SignUp() {
  const { userInfo, googleUserInfo } = useUserStore();

  const [inputData, setInputData] = useState<TSignupInputParams>({
    name: "",
    phone: "",
    approverEmail: "",
  });
  const [isRequestEmail, setIsRequestEmail] = useState<boolean>(false);
  const [isSignup, setIsSignup] = useState<boolean | null>(null);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputData({
      ...inputData,
      [e.target.name]: e.target.value,
    });
  };

  const handleRequestEmail = (e: CheckboxChangeEvent) => {
    setIsRequestEmail(e.target.checked);
    setInputData((prev) => ({
      ...prev,
      approverEmail: "",
    }));
  };

  const handleSubmit = async (
    event: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    const { name, phone, approverEmail } = inputData;

    if (isRequestEmail && approverEmail === "") {
      toast.error("이메일을 입력해주세요");
      return;
    }

    const { data: isExistApproverEmail } = await checkExistEmail({
      email: inputData.approverEmail as string,
    });

    if (isRequestEmail && !isExistApproverEmail) {
      toast.error("해당 이메일로 가입된 계정이 없습니다.");
      return;
    }

    const userInfo: TSignupParams = {
      uid: googleUserInfo?.uid as string,
      email: googleUserInfo?.email as string,
      name,
      ...(phone && { phone }),
      ...(isRequestEmail && approverEmail && { approverEmail }),
    };

    const { data: isSuccessCreate } = await create(userInfo);
    if (isSuccessCreate) {
      setIsSignup(false);
    }
  };

  const handleHomeButton = () => {
    window.location.href = "https://sollink.ai/total";
  };

  const { updateIsShowUserIcon, updateUserInfo } = useUserStore();

  const { updateIsLoggedIn } = useLoginStore();

  const logout = () => {
    signOut(auth)
      .then(() => {
        localStorage.setItem("login", "false"); // 제거 예정
        updateIsLoggedIn(false); // 제거 예정
        updateUserInfo(null);
        removeCookie("idToken");
        removeCookie("accessToken");
        updateIsShowUserIcon(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!googleUserInfo) {
      return navigate("/");
    }

    setIsSignup(!userInfo);
  }, [googleUserInfo, userInfo]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      setIsSignup(null);
      logout();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return isSignup !== null ? (
    <div className="w-[100%] bg-[#08B29520]">
      <div className="h-[100vh] flex flex-col justify-center items-center drop-shadow-md">
        <div className="bg-white w-[360px] p-10 rounded-xl flex flex-col items-center">
          <img src={logo} className="w-[180px]" />

          {isSignup ? (
            <>
              <div className="my-2 text-xs text-gray-800">
                {t("sign_up_and_sexperience_solink")}
              </div>
              <form
                onSubmit={handleSubmit}
                className="flex flex-col justify-center items-center text-sm w-[300px] py-2"
              >
                <div className="w-full">
                  <div className="">
                    <span className="text-[#f00]">*</span>
                    <span className="text-xs">이름</span>
                  </div>
                  <input
                    name="name"
                    type="text"
                    placeholder="이름을 입력해주세요."
                    required
                    className="w-full h-8 p-3 mt-1.5 border bg-[#F9FAFB] rounded-lg placeholder-[#B5B5B5] text-xs"
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </div>

                <div className="w-full mt-5">
                  <div>
                    <span className="text-xs">휴대전화번호</span>
                  </div>
                  <input
                    name="phone"
                    type="tel"
                    placeholder="휴대전화번호를 입력해주세요 ex. 01012345678"
                    className="w-full h-8 p-3 mt-1.5 border bg-[#F9FAFB] rounded-lg placeholder-[#B5B5B5] text-xs"
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </div>

                <div className="flex items-center w-full mt-5">
                  <span className="flex-grow text-xs">
                    가입 승인 담당자를 지정하고 싶습니다.
                  </span>

                  <Checkbox
                    checked={isRequestEmail}
                    onChange={(e) => handleRequestEmail(e)}
                  ></Checkbox>
                </div>

                {isRequestEmail ? (
                  <input
                    name="approverEmail"
                    type="text"
                    placeholder="가입 승인 요청할 이메일을 입력해주세요."
                    className="w-full h-8 p-3 mt-2.5 border bg-[#F9FAFB] rounded-lg placeholder-[#B5B5B5] text-xs"
                    onChange={handleChange}
                    autoComplete="off"
                  />
                ) : null}

                <input
                  type="submit"
                  className="w-full py-2 bg-[#08B295] text-xs text-white cursor-pointer mt-7 rounded-3xl"
                  value="가입 요청하기"
                />
              </form>
            </>
          ) : (
            <>
              <p className="my-2 text-xs text-gray-800">
                관리자에게 회원 권한 승인 요청을 보냈습니다. <br></br>
                승인완료는 최대 일주일까지 소요됩니다.
              </p>
              <button
                onClick={handleHomeButton}
                className="w-full py-2 bg-[#08B295] text-xs text-white mt-7 rounded-3xl"
              >
                솔링크 콘솔 홈으로 이동
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  ) : null;
}
