import { DoneTaskList, ReturnData } from "../../../shared/types";
import { baseApi } from "../../../shared/api";

export interface workHistoryParams {
  siteId: string;
  startDate: string;
  endDate: string;
  robotModelTypeEn?: string;
  alarmType?: string;
}

const { isClient } = baseApi;

export const getWorkHistoryData = async (
  params: workHistoryParams
): Promise<any> => {
  try {
    const res = await isClient.get(`/task/complete-list`, { params });
    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error("완료된 작업 이력을 불러오지 못했습니다.");
  }
};

interface Params {
  siteId: string;
  taskId: string;
}
interface StopParams extends Params {
  activation: "START" | "STOP" | "USER_STOP";
}

interface siteWithDatePrams {
  siteId: string;
  startDate: string;
  endDate: string;
}

export async function postStopTask(data: StopParams): Promise<any> {
  try {
    const res = await isClient.post(`/task/command`, data);

    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error("태스크 중지 에러입니다.");
  }
}

export async function deleteTask(data: Params): Promise<ReturnData> {
  try {
    const res = await isClient.delete(`/task/delete`, { data });

    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error("태스크 삭제 에러입니다.");
  }
}

export async function doneTask(
  data: siteWithDatePrams
): Promise<DoneTaskList[]> {
  try {
    const res = await isClient.get(
      `/task/complete-list?siteId=${data.siteId}&startDate=${data.startDate}&endDate=${data.endDate}`
    );

    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error("완료된 스케줄을 불러오지 못했습니다.");
  }
}
