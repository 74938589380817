import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import MostUsedBarChart from "./MostUsedBarChart";

import { Tooltip } from "react-tooltip";
import { useBlurStyle } from "../../../../shared/hooks";
import useUserStore from "../../../../shared/store/userStore";

import { PeriodWithSiteId, UsedRobotType } from "../../../../shared/types";
import { getRobotMinMaxByPeriod } from "../../api";
import { LOCALE, PAGE_VIEW_CNT_USED_ROBOT } from "../../../../shared/constants";
import { numberFormat } from "../../../../shared/util/format";
import { Pagination, Skeleton } from "../../../../shared/ui";
import { useDashboardStore } from "../../../../features/chart/model";

export default function MostUsedRobots(props: any) {
  const blurStyle = useBlurStyle();
  // const {startDate, endDate} = usePeriod(null);
  const { selectedUserAuthSite } = useUserStore();
  const { currentDate, oneMonthAgoDate } = useDashboardStore();

  const [loading, setLoading] = useState<boolean>(true);
  const [robotList, setRoboList] = useState<UsedRobotType[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [max, setMax] = useState<number>(0);
  const skeletonDummyData = [162, 278, 214, 320, 105, 195, 90];
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedUserAuthSite.length === 0) {
      return;
    }

    const data: PeriodWithSiteId = {
      startDate:
        props.startDate === "null" || !props.startDate
          ? oneMonthAgoDate
          : props.startDate,
      endDate:
        props.endDate === "null" || !props.endDate
          ? currentDate
          : props.endDate,
      siteIds: selectedUserAuthSite,
    };

    getRobotMinMaxByPeriod(data) //
      .then((res) => {
        const temp = res
          .filter((robot: UsedRobotType) => robot.cnt > 0)
          .sort((a: any, b: any) => b.cnt - a.cnt);
        setLoading(false);
        setRoboList([...temp]);
        setTotalPages(Math.ceil(temp.length / PAGE_VIEW_CNT_USED_ROBOT));

        if (temp[0]) {
          setMax(temp[0].cnt);
        } else {
          setMax(0);
        }
      });
  }, [
    selectedUserAuthSite,
    currentDate,
    oneMonthAgoDate,
    props.startDate,
    props.endDate,
  ]);

  const [isShowTooltip1, setIsShowTooltip1] = useState(false);
  const [isShowTooltip2, setisShowTooltip2] = useState(false);
  return (
    <div className="flex flex-col justify-between px-5 py-2">
      <div className="flex pt-2">
        <div className="pt-2 pl-2 mr-8">
          <Tooltip
            anchorSelect={"#MostUsedRobotsTooltip1"}
            place={"right"}
            content="지난 1개월 동안 가장 많은 서비스를 수행한 로봇과 그 서비스 수행 건수입니다."
          />

          <Tooltip
            anchorSelect={"#MostUsedRobotsTooltip2"}
            place={"right"}
            content="지난 1개월 동안 가장 적게 서비스를 수행한 로봇과 그 서비스 수행 건수입니다."
          />

          <div
            id={"MostUsedRobotsTooltip1"}
            className="text-[#08B295] text-sm cursor-default"
          >
            <Trans i18nKey="most_used_robot" />
          </div>
          <div className={`font-bold text-gray-800 text-lg ${blurStyle}`}>
            {/* <Trans i18nKey="robot_2" /> | 7 <Trans i18nKey="case" /> */}
            {robotList[0]?.robotName} |{" "}
            {numberFormat(robotList[0]?.cnt, LOCALE)}
            {t("case")}
          </div>
        </div>

        <div className="p-2">
          <div
            id={"MostUsedRobotsTooltip2"}
            className="text-[#08B295] text-sm cursor-default"
          >
            <Trans i18nKey="least_used_robot" />
          </div>
          <div className={`font-bold text-gray-800 text-lg ${blurStyle}`}>
            {/* <Trans i18nKey="robot_9" /> | 1 <Trans i18nKey="case" /> */}
            {robotList[robotList.length - 1]?.robotName} |{" "}
            {numberFormat(robotList[robotList.length - 1]?.cnt, LOCALE)}
            {t("case")}
          </div>
        </div>
      </div>

      {robotList.length > 0 ? (
        <>
          <div
            className={`w-[97%] h-[100%] pl-5 pt-0 pb-2 mt-3 ${blurStyle}`}
            style={{ display: "flex" }}
          >
            {loading ? (
              skeletonDummyData.map((item, i) => (
                <div key={i} className="flex pl-3">
                  <Skeleton
                    width={50}
                    height={20}
                    borderRadius={3}
                    className="my-1 mr-2"
                  />
                  <Skeleton
                    width={item}
                    height={20}
                    borderRadius={3}
                    className="my-1"
                  />
                </div>
              ))
            ) : (
              <MostUsedBarChart
                robotList={robotList.slice(
                  (currentPage - 1) * PAGE_VIEW_CNT_USED_ROBOT,
                  currentPage * PAGE_VIEW_CNT_USED_ROBOT
                )}
                max={max}
              />
            )}
          </div>
          <div className={blurStyle}>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onClickPrev={() => setCurrentPage((prev) => prev - 1)}
              onClickNext={() => setCurrentPage((prev) => prev + 1)}
            />
          </div>
        </>
      ) : (
        <div
          style={{
            fontSize: "60px",
            textAlign: "center",
            fontWeight: "bold",
            fontFamily: "SUIT",
            height: "400px",
            lineHeight: 5,
            color: "#e0e0e0",
          }}
        >
          NO DATA
        </div>
      )}
    </div>
  );
}
