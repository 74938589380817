import { useEffect, useRef, useState } from "react";
import useCardstore from "../../../shared/store/cardStore";
import "../../../app/animation.css";

import {
  getAllSiteList,
  getSiteListWithAuth,
} from "../../../api/firestore/site";
import { useUserData } from "../../../shared/hooks";
import { RobotType } from "../../../shared/types";
import { getAllRobotList } from "../../../api/firestore/robot";
import { SiteType } from "../../../shared/types/site";
import { MapOverlay } from "../../../entities/mapContent/ui";
import { useMapstore } from "../model";

export default function Map() {
  const mapRef = useRef<HTMLInputElement>(null);

  const {
    mode,
    center,
    zoom,
    markerInfo,
    isMarkerClick,
    changeCenter,
    changeZoom,
    changeMarkerInfo,
    updateIsMarkerClick,
  } = useMapstore();

  const {
    cardCenter,
    cardInfo,
    isShowCardInfoWindow,
    updateIsShowCardInfoWindow,
    changeCardInfo,
  } = useCardstore();

  const [markers, setMarkers] = useState<any>([]);
  const { naver } = window;
  const [map, setMap] = useState<any>(null);
  const [robotList, setRobotList] = useState<RobotType[]>([]);

  const [totalSiteList, setTotalSiteList] = useState<any>([]);
  const [authSiteList, setAuthSiteList] = useState<any>([]);

  const { user } = useUserData();

  // 전체 사이트
  useEffect(() => {
    getAllSiteList().then((data) => {
      setTotalSiteList(data);
    });
    getAllRobotList(setRobotList);
  }, []);

  // 사용자 권한 있는 사이트
  useEffect(() => {
    if (!user) return;
    getAuthSite(user.auth_sites);
  }, [user]);

  // 권한 있는 사이트 가져오는 함수
  function getAuthSite(authSite: string[]) {
    getSiteListWithAuth(authSite) //
        .then((res) => setAuthSiteList(res.map((s) => s.name as string)));
  }

  // map: init
  useEffect(() => {
    const location = new naver.maps.LatLng(center.lat, center.lng);
    const map = new naver.maps.Map(mapRef.current, {
      center: location,
      zoom: zoom,
      maxZoom: 11,
      mapTypeId: naver.maps.MapTypeId.NORMAL,
      tileSize: new naver.maps.Size(64, 64),
      disableKineticPan: false,
      mapDataControl: false,
      scaleControl: false,
      logoControl: false,
      logoControlOptions: {
        position: naver.maps.Position.RIGHT_BOTTOM,
      },
      background: "#ffffff",

      tileSpare: 8,
      tileDuration: 700,

      zoomControl: true,
      zoomControlOptions: {
        position: naver.maps.Position.BOTTOM_RIGHT,
        style: naver.maps.ZoomControlStyle.SMALL,
      },
      mapTypeControl: true,
      mapTypeControlOptions: {
        position: naver.maps.Position.BOTTOM_RIGHT,
        style: naver.maps.ZoomControlStyle.DROPDOWN,
        mapTypeIds: [
          naver.maps.MapTypeId.NORMAL,
          naver.maps.MapTypeId.SATELLITE,
          naver.maps.MapTypeId.HYBRID,
        ],
      },
      margin: [10, 10, 10, 10],
    });

    setMap(map);
  }, [mode]);

  // map: 현재 보고 있는 좌표 저장
  useEffect(() => {
    if (map) {
      naver.maps.Event.addListener(map, "idle", () => {
        const currentCenter = map.getCenter();
        const currentZoomLevel = map.getZoom();
        localStorage.setItem("lat", String(currentCenter.y));
        localStorage.setItem("lng", String(currentCenter.x));
        localStorage.setItem("zoom", String(currentZoomLevel));
        changeCenter({ lat: currentCenter.y, lng: currentCenter.x });
        changeZoom(currentZoomLevel);
      });
    }
  }, [map]);

  // map: 마커 생성
  useEffect(() => {
    if (map && totalSiteList.length !== 0) {
      const tempMarkers: any = [];
      setMarkers([]);

      // 이미 생성된 마커는 삭제 (색깔 변경 시 마커 삭제하고 새로 생성하는 것)
      for (let i = 0; i < markers.length; i++) {
        markers[i].setMap(null);
      }

      // 마커 생성
      totalSiteList.map((item: SiteType) => {
        const color = "GREEN";
        const robotCnt = robotList.filter(
            (el: RobotType) => el.site_id === item.id
        );
        const size = robotCnt.length * 1 + 12;
        const newAddress = item.road_address.split(" ").slice(0, 2).join(" ");
        const marker = new naver.maps.Marker({
          position: new naver.maps.LatLng(
              Number(item.latitude),
              Number(item.longitude)
          ),
          map: map,
          icon: {
            anchor: new naver.maps.Point(size / 2, size / 2),
            content: `<svg width=${size} height=${size} viewBox="0 0 ${
                size * 2
            } ${
                size * 2
            }"  opacity="0.6" xmlns="http://www.w3.org/2000/svg" id="icon-${color.toLowerCase()}-pulse"><circle cx=${size} cy=${size} r=${size} fill=${color} /></svg>`,
          },
          siteName: authSiteList.includes(item.name) ? item.name : item.alias,
          roadAddress: authSiteList.includes(item.name)
              ? item.road_address
              : `${newAddress} ******`,
        });
        tempMarkers.push(marker);
      });
      setMarkers(tempMarkers);
    }
  }, [map, totalSiteList, authSiteList]);

  // map: 마커 click, hover 이벤트
  useEffect(() => {
    let currentMarker: {
      x: null | number;
      y: null | number;
    } = { x: null, y: null };

    let currentMousePosition: {
      x: null | number;
      y: null | number;
    } = { x: null, y: null };

    if (map && markers.length !== 0) {
      markers.map((marker: any) => {
        // 마커 infoWindow
        const infowindow = new naver.maps.InfoWindow({
          content: MapOverlay({
            siteName: marker.siteName,
            roadAddress: marker.roadAddress,
            isVisibleOperationButton: authSiteList.includes(marker.siteName),
          }),
        });

        naver.maps.Event.addListener(marker, "click", (event: any) => {
          infowindow.open(
              map,
              naver.maps.LatLng(marker.position.y, marker.position.x)
          );
          currentMarker = { x: event.offset.x, y: event.offset.y };
          updateIsMarkerClick(true);
          updateIsShowCardInfoWindow(false);
          changeMarkerInfo(
              marker.siteName,
              marker.roadAddress,
              Number(marker.position._lat),
              Number(marker.position._lng)
          );
          changeCardInfo({
            siteName: null,
            roadAddress: null,
          });
        });

        if (isShowCardInfoWindow) {
          naver.maps.Event.clearListeners(marker, "mouseover");
          naver.maps.Event.clearListeners(map, "mouseover");
        } else {
          if (isMarkerClick) {
            naver.maps.Event.clearListeners(marker, "mouseover");
            naver.maps.Event.clearListeners(map, "mouseover");
            const { siteName, roadAddress, lat, lng } = markerInfo;
            const tempInfowindow = new naver.maps.InfoWindow({
              content: MapOverlay({
                siteName: siteName,
                roadAddress: roadAddress,
                isVisibleOperationButton: authSiteList.includes(siteName),
              }),
            });

            tempInfowindow.open(map, naver.maps.LatLng(lat, lng));

            naver.maps.Event.addListener(map, "click", () => {
              infowindow.close();
              tempInfowindow.close();
              updateIsMarkerClick(false);
              changeCardInfo({
                siteName: null,
                roadAddress: null,
              });
            });
          } else {
            naver.maps.Event.addListener(marker, "mouseover", (event: any) => {
              infowindow.open(
                  map,
                  naver.maps.LatLng(marker.position._lat, marker.position._lng)
              );
              currentMarker = { x: event.offset.x, y: event.offset.y };
              changeCardInfo({
                siteName: null,
                roadAddress: null,
              });
            });
            naver.maps.Event.addListener(map, "mousemove", () => {
              if (
                  currentMousePosition.x &&
                  currentMousePosition.y &&
                  currentMarker.x &&
                  currentMarker.y
              ) {
                if (
                    !(
                        currentMousePosition.x < currentMarker.x + 125 &&
                        currentMousePosition.x > currentMarker.x - 125 &&
                        currentMousePosition.y > currentMarker.y - 165 &&
                        currentMousePosition.y < currentMarker.y + 10
                    )
                ) {
                  infowindow.close();
                }
              }
            });
          }
        }
      });
      naver.maps.Event.addListener(map, "mousemove", (event: any) => {
        currentMousePosition = { x: event.offset.x, y: event.offset.y };
      });
    }
  }, [
    map,
    markers,
    isMarkerClick,
    isShowCardInfoWindow,
    naver.maps,
    updateIsMarkerClick,
    updateIsShowCardInfoWindow,
    changeMarkerInfo,
    changeCardInfo,
    markerInfo,
    authSiteList,
  ]);

  // map: 카드뷰 click 이벤트
  useEffect(() => {
    if (map) {
      // 카드뷰 infoWindow 내용
      const { siteName, roadAddress } = cardInfo;
      if (!siteName || !roadAddress) return;
      const cardInfowindow = new naver.maps.InfoWindow({
        content: MapOverlay({
          siteName: siteName,
          roadAddress: roadAddress,
          isVisibleOperationButton: authSiteList.includes(siteName),
        }),
      });

      // 카드뷰 클릭 시 좌표 이동
      if (
          isShowCardInfoWindow &&
          cardInfo.siteName &&
          cardCenter.lat &&
          cardCenter.lng
      ) {
        const newCenter = new naver.maps.LatLng(cardCenter.lat, cardCenter.lng);
        map.panTo(newCenter, {
          duration: 500,
          easing: "easeOutCubic",
        });
      }

      // 카드뷰 infoWindow 열기
      if (cardInfo.siteName) {
        const cardMarker = new naver.maps.Marker({
          position: new naver.maps.LatLng(cardCenter.lat, cardCenter.lng),
          map: map,
          icon: { url: "" },
        });
        cardInfowindow.open(map, cardMarker);
        updateIsShowCardInfoWindow(true);
        updateIsMarkerClick(false);
      }

      // 카드뷰 infoWindow 닫기
      naver.maps.Event.addListener(map, "click", () => {
        cardInfowindow.close();
        updateIsShowCardInfoWindow(false);
        changeCardInfo({
          siteName: null,
          roadAddress: null,
        });
      });
    }
  }, [
    map,
    cardCenter,
    isShowCardInfoWindow,
    authSiteList,
    cardInfo,
    naver.maps.InfoWindow,
    naver.maps.Event,
    naver.maps.LatLng,
    naver.maps.Marker,
    updateIsShowCardInfoWindow,
    updateIsMarkerClick,
    changeCardInfo,
  ]);

  return <div ref={mapRef} className="w-[100%] h-[100vh]"></div>;
}
