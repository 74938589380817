import { useEffect, useState } from "react";

import { siteRobotType } from "../../../shared/types";
import { AuthType, Permission } from "../../../shared/types/user";
import useUserStore from "../../../shared/store/userStore";
import { getSiteRobots } from "../api";

export function useRestRobotData(userData: AuthType | null) {
  const { userStatus, selectedUserAuthSite } = useUserStore();
  const [robotData, setRobotData] = useState<siteRobotType[]>([]);
  const [robotFilterData, setRobotFilterData] = useState<siteRobotType[]>([]);
  const [filterRobotType, setFilterRobotType] = useState<string>("");
  const [filterRobotName, setFilterRobotName] = useState<string>("");

  const fetchSiteRobots = async () => {
    if (!userData || !selectedUserAuthSite.length) return;

    if (userStatus === Permission.SUPER_ADMIN) {
      const robotData = await getSiteRobots(selectedUserAuthSite.join(","));
      setRobotData(robotData);
      return;
    }

    if (userStatus === Permission.ENGINEER || userStatus === Permission.USER) {
      const robotData = await getSiteRobots(selectedUserAuthSite.join(","));
      setRobotData(robotData);
      return;
    }
  };

  const selectedSiteRobotData = async (siteId: string) => {
    const robotData = await getSiteRobots(siteId);
    setRobotData(robotData);
  };

  const findRobotByDeviceId = (deviceId: string) => {
    return robotData?.find((item) => item.deviceId === deviceId);
  };

  useEffect(() => {
    if (!userData) return;
    fetchSiteRobots();
  }, [userData, userStatus, selectedUserAuthSite]);

  useEffect(() => {
    setRobotFilterData(robotData);
    setFilterRobotType("전체");
  }, [robotData]);

  useEffect(() => {
    const filterData = robotData?.filter(
      (item) =>
        (!filterRobotType ||
          filterRobotType === "전체" ||
          item.modelType === filterRobotType) &&
        (!filterRobotName ||
          item.name.toLowerCase().includes(filterRobotName.toLowerCase()))
    );
    setRobotFilterData(filterData);
  }, [robotData, filterRobotType, filterRobotName]);

  return {
    fetchSiteRobots,
    robotData,
    robotFilterData,
    selectedSiteRobotData,
    findRobotByDeviceId,
    filterRobotType,
    filterRobotName,
    setRobotData,
    setFilterRobotType,
    setFilterRobotName,
  };
}
