import React, { useEffect, useState } from "react";
//hall call
//car call
import errorPage from "../../../pages/ErrorPage";
import { AiOutlineBars } from "react-icons/ai";
import useUserStore from "../../../shared/store/userStore";
import { LuLayoutTemplate } from "react-icons/lu";
import { FcSerialTasks } from "react-icons/fc";
import { getPresetNodeList, getUsersetNodeList } from "../api";
import { useWorkflowStore } from "../model";
/*SVG*/

const ItemPreset = (props: any) => {
  const { robotInfo } = useWorkflowStore();
  const { selectedUserAuthSite } = useUserStore();

  const [presetNodeList, setPresetNodeList] = useState<any>([]);
  const [usersetNodeList, setUsersetNodeList] = useState<any>([]);

  const onDragStart = (event: any, nodeType: any) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.setData("id", event.target.id);
    event.dataTransfer.setData("presetID", event.target.title);
    event.dataTransfer.effectAllowed = "move";
  };

  useEffect(() => {
    if (props.isReset) {
      setPresetNodeList([]);
      setUsersetNodeList([]);
    }
  }, [props.isReset]);

  useEffect(() => {
    if (selectedUserAuthSite) {
      getPresetNodeListAPI(selectedUserAuthSite[0]);
    }
  }, [selectedUserAuthSite]);

  useEffect(() => {
    if (selectedUserAuthSite) {
      getUsersetNodeListAPI(selectedUserAuthSite[0]);
    }

    if (props.reRender) {
      props.resetReRender(false, "preset");
    }
  }, [selectedUserAuthSite, props.reRender]);

  const getPresetNodeListAPI = async (siteId: string) => {
    await getPresetNodeList(siteId)
      .then((res) => {
        if (res !== null && res !== undefined) {
          setPresetNodeList(res);
        }
      })
      .catch((error) => {
        errorPage();
      });
  };
  const getUsersetNodeListAPI = async (siteId: string) => {
    await getUsersetNodeList(siteId)
      .then((res) => {
        if (res !== null && res !== undefined) {
          setUsersetNodeList(res);
        }
      })
      .catch((error) => {
        errorPage();
      });
  };

  return (
    <aside className={"wf-body-left-contents"} >
      {/* PRESET */}
      {presetNodeList.length > 0 ? (
        <div style={styles.sidebarItemHeadingContainer}>
          <div style={{ display: "flex" }}>
            <AiOutlineBars size="20" color="rgb(100, 100, 100)" />
            <span style={styles.sidebarItemHeading}> PRESET </span>
          </div>
        </div>
      ) : (
        ""
      )}
      {presetNodeList.length > 0
        ? presetNodeList.map((item: any, idx: number) => {
            return (
              <div
                key={`node_${idx}`}
                id={`PRESET|${item.presetName}|${item.presetId}`}
                draggable={true}
                onDragStart={(event) => onDragStart(event, "default")}
              >
                <div style={styles.parent}>
                  <div style={styles.iconParent}>
                    <FcSerialTasks size={20} />
                  </div>
                  {item.presetName}
                </div>
              </div>
            );
          })
        : ""}
      {/* USERSET */}
      {usersetNodeList.length > 0 ? (
        <div style={styles.sidebarItemHeadingContainer}>
          <div style={{ display: "flex" }}>
            <AiOutlineBars size="20" color="rgb(100, 100, 100)" />
            <span style={styles.sidebarItemHeading}> USERSET </span>
          </div>
        </div>
      ) : (
        ""
      )}
      {usersetNodeList.length > 0
          ? usersetNodeList.map((item: any, idx: number) => {
            return (
                <div
                    key={`node_${idx}`}
                    id={`USERSET|${item.usersetId}`}
                    draggable={true}
                    onDragStart={(event) => onDragStart(event, "default")}
                >
                  <div style={styles.parent}>
                    <div style={styles.iconParent}>
                      <LuLayoutTemplate size={20}/>
                    </div>
                    {item.usersetName}
                  </div>
                </div>
            );
          })
          : <div className={"text-gray-400 text-[14px]"}>등록된 USERSET 가 없습니다.</div>}
    </aside>
  );
};

export default ItemPreset;

const styles = {
  parent: {
    padding: 7,
    fontSize: 14,
    background: "#fff",
    cursor: "move",
    marginTop: 10,
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    border: "1px solid #ddd",
    fontFamily: "Gmarket Sans, Medium",
  },
  iconParent: {
    borderRight: "1px solid rgb(230, 230, 230)",
    padding: 5,
    paddingRight: 10,
    paddingLeft: 5,
    marginRight: 10,
  },
  splitIcon: {
    transform: "rotate(90deg)",
  },
  joinIcon: {
    transform: "rotate(-90deg)",
  },
  sidebarItemHeading: {
    fontSize: 16,
    fontWeight: "bold",
    marginLeft: "10px",
    lineHeight: "normal",
  },
  sidebarItemHeadingContainer: {
    marginTop: 24,
    marginBottom: 12,
  },
};
