import { baseApi } from "../../../shared/api";

const { client } = baseApi;
export async function getSiteScheduleData(siteIds: string): Promise<any> {
  try {
    const res = await client.get(`schedule/all/by-sites?siteIds=${siteIds}`);
    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error("사이트별 스케줄 데이터를 불러오지 못했습니다.");
  }
}

export async function getScheduleData(scheduleId: string): Promise<any> {
  try {
    const res = await client.get(`schedule/one/${scheduleId}`);
    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error("스케줄 데이터를 불러오지 못했습니다.");
  }
}

export async function postScheduleData(scheduleData: Object): Promise<any> {
  try {
    const res = await client.post("/schedule", scheduleData);
    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error("스케줄 데이터를 저장하지 못했습니다.");
  }
}

export async function putScheduleData(scheduleData: Object): Promise<any> {
  try {
    const res = await client.put("/schedule", scheduleData);
    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error("스케줄 데이터를 수정하지 못했습니다.");
  }
}

export async function deleteScheduleData(scheduleId: string): Promise<any> {
  try {
    const res = await client.delete(`/schedule/${scheduleId}`);
    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error("스케줄 데이터를 삭제하지 못했습니다.");
  }
}
