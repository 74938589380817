import { useEffect, useState } from "react";

import useModalstore from "../../../../shared/store/modalStore";
import useDatastore from "../../../../shared/store/dataStore";
import { Button } from "../../../../shared/ui";
import { DeviceDataType } from "../../../../shared/types";
import { getReportList } from "../../api/robot";

interface ItemType {
  displayName: string;
  startTime: string;
  actualCleaningAreaSquareMeter: number;
  durationSeconds: number;
}

interface PropsType {
  detailData?: DeviceDataType | null;
}

export default function ReportModal({ detailData }: PropsType) {
  const { closeModal } = useModalstore();
  const { robotDetailData } = useDatastore();

  const [reportList, setReportList] = useState<ItemType[] | null>(null);
  const [loading, setLoading] = useState<boolean | null>(null);
  useEffect(() => {
    onGetReportList();
  }, []);

  function onGetReportList() {
    setLoading(true);
    const param = detailData === undefined ? robotDetailData : detailData;
    getReportList(param).then((res) => {
      setReportList(res.robotTaskReports);
      setLoading(false);
    });
  }

  return (
    <div className="flex flex-col justify-around h-[30rem]">
      <section className="flex justify-center w-full">
        <div className="flex flex-col w-full mx-2">
          <h3 className="my-3 mx-0 font-bold text-[#868686]">청소 기록</h3>

          <ul
            id="scroll"
            className="h-[21rem] w-full overflow-auto rounded border-[1px] border-[#d1d1d1]"
          >
            {!reportList && (
              <li className="flex justify-center items-center h-full text-[#868686]">
                {loading
                  ? "청소 기록을 불러오는 중입니다."
                  : "청소 기록이 존재하지 않습니다."}
              </li>
            )}

            {reportList &&
              reportList.length > 0 &&
              reportList.map((r, index) => (
                <li
                  key={index}
                  className="flex justify-between items-center px-4 py-2 m-2 bg-[#f6f6f6] rounded-md"
                >
                  <div className="flex-[0.4]">
                    <p className="overflow-hidden text-sm whitespace-nowrap text-ellipsis">
                      {r.displayName}
                    </p>

                    <div className="text-xs">
                      {r.startTime.slice(0, 10)} {r.startTime.slice(11, -1)}
                    </div>
                  </div>

                  <div className="flex flex-[0.6] text-xs">
                    <div className="pl-[6px]">
                      청소영역 {r.actualCleaningAreaSquareMeter.toFixed(2)}m²
                    </div>
                    <div className="pl-[6px]">
                      청소시간 {r.durationSeconds}s
                    </div>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </section>

      <section className="w-full flex justify-center items-center mx-0 my-[10px]">
        <Button
          outlined
          label="닫기"
          type="button"
          size="small"
          onClick={closeModal}
        />
      </section>
    </div>
  );
}
