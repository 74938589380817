import { useEffect, useState } from "react";
import {
  getSiteScheduleData,
  getScheduleData,
  postScheduleData,
  putScheduleData,
  deleteScheduleData,
} from "../api";
import useUserStore from "../../../shared/store/userStore";
import { scheduleType } from "../../../shared/types";
import { toast } from "react-toastify";
import useScheduleStore from "./scheduleStore";

export default function useScheduleData() {
  const [scheduleData, setScheduleData] = useState<scheduleType | null>(null);

  const { selectedUserAuthSite } = useUserStore();
  const { siteScheduleData, updateSiteScheduleData } = useScheduleStore();

  useEffect(() => {
    selectedUserAuthSite.length > 0 && fetchSiteScheduleData();
  }, [selectedUserAuthSite]);

  const fetchSiteScheduleData = async () => {
    const data = await getSiteScheduleData(selectedUserAuthSite.join(","));
    updateSiteScheduleData(data);
  };

  const fetchScheduleData = async (scheduleId: string) => {
    const data = await getScheduleData(scheduleId);
    setScheduleData(data);
  };

  const saveScheduleData = async (scheduleData: Object) => {
    const res = await postScheduleData(scheduleData);
    if (res === "SUCCESS") {
      toast.success("저장되었습니다.");
      fetchSiteScheduleData();
    }
  };

  const editScheduleData = async (scheduleData: Object) => {
    const res = await putScheduleData(scheduleData);
    if (res === "SUCCESS") {
      toast.success("수정되었습니다.");
      fetchSiteScheduleData();
    }
  };

  const removeScheduleData = async (scheduleId: string) => {
    const res = await deleteScheduleData(scheduleId);
    if (res === "SUCCESS") {
      toast.success("삭제되었습니다.");
      fetchSiteScheduleData();
    }
  };

  return {
    scheduleData,
    siteScheduleData,
    fetchScheduleData,
    saveScheduleData,
    editScheduleData,
    removeScheduleData,
  };
}
