import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { auth } from "../lib";

import { getUserById } from "../../api/firestore/user";
import { UserType } from "../../shared/types";

export function useUserData() {
  const [user, setUser] = useState<UserType | null>(null);

  useEffect(() => {
    onAuthStateChanged(auth, (userData) => {
      if (userData) {
        getUserData(userData.uid, userData.emailVerified);
      }
    });
  }, []);

  async function getUserData(uid: string, emailVerified: boolean) {
    const res = await getUserById(uid);
    setUser({ ...res, id: uid, emailVerified } as UserType);
  }

  return { user };
}
