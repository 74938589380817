import React, { ChangeEvent, useEffect, useState } from "react";
import "../app/animation.css";

import dayjs from "dayjs";
import { DeviceDataType, FirestoreCollenctions } from "../shared/types";

import { Modal, ModalPortal, InquiryModal } from "../shared/ui/Modal";

import useRobotstore from "../shared/store/robotStore";
import { useTranslation } from "react-i18next";
import useUserStore from "../shared/store/userStore";

import { AuthType, Permission } from "../shared/types/user";
import { SiteType } from "../shared/types/site";
import { getUserById } from "../api/firestore/user";

import { useSearchParams } from "react-router-dom";
import { getAllSiteListOnSnapshot } from "../api/firestore/site";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../shared/lib";
import { SearchInput } from "../shared/ui";
import useErrorStore from "../shared/store/errorStore";

import Detail from "../features/command/ui/Detail";
import { RobotTable } from "../widgets/robotOperation/ui";
import { useAuth, useRobotData } from "../shared/hooks";
import { useLoginStore } from "../features/login/model";

export default function RobotPage() {
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();
  const { hasAuthSite, hasAuthRobot, login } = useAuth();

  // 전역 상태
  const { userInfo } = useLoginStore();
  const { isShowRobotDetail, updateIsShowRobotDetail, selectedRobot } =
    useRobotstore();
  const { userStatus, updateIsShowUserIcon } = useUserStore();
  const { robotDetailError, updateRobotDetailError } = useErrorStore();
  // 로봇, 사이트
  const [userData, setUserData] = useState<AuthType | null>(null);
  const [siteList, setSiteList] = useState<SiteType[]>([]);
  const [filterSiteList, setfilterSiteList] = useState<
    { name: string; id: string }[]
  >([]);

  const { socketData, syncRobotList } = useRobotData(userData);

  // 필터
  const [selectedSite, setSelectedSite] = useState<string>("");
  const [selectRobotType, setSelectRobotType] = useState<string>("");
  const [robotTypeFilter, setRobotTypeFilter] = useState<string[]>([]);
  const [selectRobotName, setSelectRobotName] = useState("");

  // 지도에서 로봇 운영 클릭 시
  useEffect(() => {
    if (!searchParams.get("siteName") || !siteList) return;

    const siteName = searchParams.get("siteName");
    const site = siteList.find((item) => item.name === siteName);
    if (!site || !site.id) return;

    setSelectedSite(site.id);
    setSearchParams("");
  }, [searchParams, siteList, setSearchParams, syncRobotList]);

  useEffect(() => {
    if (!userInfo) return;
    const fetchData = async () => {
      const data = await getUserById(userInfo.uid);
      setUserData(data);
    };
    fetchData();
  }, [userInfo]);

  useEffect(() => {
    if (!userData) return;

    if (userStatus === Permission.SUPER_ADMIN) {
      getAllSiteListOnSnapshot(setSiteList);
      return;
    }

    const fetchData = async () => {
      const siteData: SiteType[] = [];
      // TODO: 공통함수 빼기
      userData.auth_sites.map(async (siteId) => {
        const docRef = doc(db, FirestoreCollenctions.SITES, siteId);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
          const data = {
            id: docSnapshot.id,
            ...(docSnapshot.data() as SiteType),
          };

          siteData.push(data);
        } else {
          return null;
        }

        setSiteList(siteData as SiteType[]);
      });
    };

    if (userStatus === Permission.ENGINEER || userStatus === Permission.USER) {
      fetchData();
    }
  }, [userData, userStatus]);

  useEffect(() => {
    if (socketData.length > 0) {
      const temp2: string[] = [];

      const temp = socketData.map((item: any) => item.modelType);

      const map = new Map();

      for (const item of temp) {
        if (!map.has(item)) {
          map.set(item, true);
          temp2.push(item);
        }
      }

      setRobotTypeFilter(temp2);
    }
  }, [socketData]);

  useEffect(() => {
    setfilterSiteList(
      siteList.map((item) => ({ name: item.name, id: item.id || "" }))
    );
  }, [siteList]);

  const handleSelectType = (event: ChangeEvent<HTMLSelectElement>) => {
    const target = event.target;
    setSelectRobotType(target.value);
  };

  const handleChange = (
    setValue: (value: string) => void,
    item?: { name: string; id: string }
  ) => {
    if (item) {
      setValue(item.name);
      setSelectedSite(item.id);
      return;
    }
    setValue("사이트 전체");
    setSelectedSite("");
  };

  return (
    <div
      style={{ overflow: isShowRobotDetail ? "hidden" : "auto" }}
      className="pl-[75px] flex bg-neutral-50"
      onClick={() => updateIsShowUserIcon(false)}
    >
      <div
        className="w-full h-[100vh] py-10 px-12"
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          const target = event.target as HTMLDivElement;
          const name = target.parentElement?.dataset.name;
          if (isShowRobotDetail && name !== "list") {
            updateIsShowRobotDetail(false);
          }
        }}
      >
        <div className="fixed top-0 z-20 w-full pt-10 pb-3 text-2xl text-gray-700 bg-neutral-50">
          {t("robot_operation")}
        </div>

        <section
          className="flex pt-[51px] gap-5 items-center justify-between pb-3"
          data-name="bg"
        >
          <>
            <SearchInput
              siteList={filterSiteList}
              handleChange={handleChange}
              setSelectedSite={setSelectedSite}
            />

            <select
              className="w-full min-w-[130px] max-w-[200px] px-3 py-[8px]   text-sm border border-white rounded-lg shadow-sm"
              value={selectRobotType}
              onChange={handleSelectType}
              data-name="robotType"
            >
              <option hidden>로봇 타입 선택</option>
              <option value="">{t("robot_types_all")}</option>
              {robotTypeFilter.length !== 0 &&
                robotTypeFilter.map((item: string, index: number) => {
                  return (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  );
                })}
            </select>

            <input
              className="w-full min-w-[130px] max-w-[200px] px-3 py-2  text-sm border border-white rounded-lg shadow-sm"
              type="text"
              placeholder={t("robot_name_searching")}
              value={selectRobotName}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const { value } = event.target;
                setSelectRobotName(value);
              }}
            />
            <div className="flex justify-end w-full gap-2 pr-10 text-xs">
              <span>{t("last_update")} </span>
              <span>{dayjs(new Date()).format("MM-DD HH:mm:ss")}</span>
            </div>
          </>
        </section>

        <RobotTable
          selectRobotType={selectRobotType}
          selectRobotName={selectRobotName}
          selectedSite={selectedSite}
          siteList={siteList as SiteType[]}
          syncRobotList={syncRobotList as DeviceDataType[]}
        />
      </div>
      {isShowRobotDetail && (
        <div className="w-[100%] z-30 h-[100vh] fixed">
          <div
            onClick={() => {
              updateIsShowRobotDetail(false);
              robotDetailError && window.location.reload();
              updateRobotDetailError(false);
            }}
            className="fixed w-[100%] h-[100vh] bg-black opacity-[0.15]"
          ></div>
        </div>
      )}
      <Detail
        command={
          syncRobotList?.find((r) => r.deviceId === selectedRobot)
            ?.robotReference
        }
      />
      <ModalPortal>
        <Modal />
      </ModalPortal>
      {(hasAuthSite === "false" || hasAuthRobot === "false") &&
        !(login === "false") && <InquiryModal />}
    </div>
  );
}
