import { ChangeEvent, FormEvent, useState } from "react";
import { toast } from "react-toastify";

import useModalstore from "../../../../shared/store/modalStore";
import { RobotCommandType } from "../../../../shared/types";
import { Button } from "../../../../shared/ui";
import { onStartJob } from "../../api/robot";

type PropsType = {
  deviceId: string | number;
  command: RobotCommandType;
};

export default function VolumeModal({ deviceId, command }: PropsType) {
  const { closeModal } = useModalstore();
  const [soundValue, setSoundValue] = useState<number>(3);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSoundValue(Number(e.target.value));
  };

  const onSendVideoUrl = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const robotData = {
      command,
      deviceId,
      soundValue,
    };

    onStartJob(robotData) //
      .then((res) => {
        toast.success("작업요청이 완료되었습니다.");
        closeModal();
      })
      .catch((e) => toast.error(`ERROR:: ${e.message}`));
  };

  return (
    <form
      className="flex flex-col justify-evenly h-[12rem]"
      onSubmit={onSendVideoUrl}
    >
      <section>
        <div>
          <h3 className="my-3 mx-0 font-bold text-[#868686]">볼륨</h3>
          <input
            className="w-full p-2 rounded-md border-[1px] border-[#d1d1d1]"
            placeholder="볼륨 레벨을 입력하세요."
            autoFocus
            type="number"
            value={soundValue}
            onChange={onChange}
          />
        </div>
      </section>

      <section className="flex items-center justify-center w-full mx-0 my-3">
        <Button
          outlined
          label="취소"
          type="button"
          size="small"
          onClick={closeModal}
        />
        <Button outlined label="전송" type="submit" size="small" primary />
      </section>
    </form>
  );
}
