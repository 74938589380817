import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRobotData, usePeriod, useUserData } from "../../../shared/hooks";
import { AuthType } from "../../../shared/types/user";
import { useTranslation, Trans } from "react-i18next";
import i18next from "i18next";
import useUserStore from "../../../shared/store/userStore";
import "./notification.css";

import Datepicker, { DateValueType } from "react-tailwindcss-datepicker";
import { PeriodType } from "../../../shared/types/dashBoard";

import { TbRefresh } from "react-icons/tb";
import Empty from "../../../shared/ui/Empty";
import { Button } from "antd";
import wariningIcon from "../../../shared/assets/icon/icon_warning.svg";
import Spinner from "../../../shared/ui/Loading/Spinner";
import { toast } from "react-toastify";
import moment from "moment";
import { getWorkHistoryData, workHistoryParams } from "../api";

const Notification = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { userStatus, selectedUserAuthSite, changeSelectedUserAuthSites } =
    useUserStore();

  const [workHistoryData, setWorkHistoryData] = useState<any>([]);

  const [userData, setUserData] = useState<AuthType | null>(null);
  const [selectRobotType, setSelectRobotType] = useState<string>("");
  const [selectRobotName, setSelectRobotName] = useState("");
  const [selectErrorType, setSelectErrorType] = useState<string>("");

  const [startDate, endDate, dateFormat, getToday] = usePeriod();
  const lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1);
  lastMonth.setDate(lastMonth.getDate() + 1);

  const [period, setPeriod] = useState<PeriodType>({
    startDate: dateFormat(lastMonth),
    endDate: getToday(),
  });

  const { user } = useUserData();
  const { socketData } = useRobotData(userData);
  const [robotTypeFilter, setRobotTypeFilter] = useState<
    { value: string; label: string }[]
  >([]);

  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [lastUpdated, setLastUpdated] = useState<string>("");

  const fetchWorkHistoryData = async () => {
    setLoading(true);
    try {
      const params: workHistoryParams = {
        siteId: selectedUserAuthSite[0],
        startDate: period.startDate as string,
        endDate: period.endDate as string,
        ...(selectErrorType && { alarmType: selectErrorType }),
        ...(selectRobotType && { robotModelTypeEn: selectRobotType }),
      };
      const data = await getWorkHistoryData(params);
      setWorkHistoryData(data);
      setLoading(false);
      setLastUpdated(moment().format("YYYY. M. D. HH:mm:ss"));
    } catch (error) {
      toast.error(error as any);
    }
  };

  const ERROR_TYPE_LIST = ["NOTICE", "ERROR"];

  useEffect(() => {
    if (socketData.length > 0) {
      const types = socketData.map((item) => ({
        label: item.modelType,
        value: item.modelTypeEn,
      }));

      const filterTypes = [
        ...new Set(types.map((type) => JSON.stringify(type))),
      ].map((type) => JSON.parse(type));

      setRobotTypeFilter(filterTypes);
    }
  }, [socketData]);

  useEffect(() => {
    setPeriod({ startDate, endDate });
  }, [startDate, endDate]);

  const handleSelectErrorType = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectErrorType(event.target.value);
  };

  const handleSelectRobotType = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectRobotType(event.target.value);
  };

  const handleChangeRobotName = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectRobotName(event.target.value);
  };

  const handleChangeDate = (newValue: DateValueType) => {
    navigate(`/history?start=${newValue?.startDate}&end=${newValue?.endDate}`);
  };

  const handleClickRefresh = async () => {
    setRefreshing(true);

    await fetchWorkHistoryData();
    setTimeout(() => {
      setRefreshing(false);
    }, 400);
  };

  useEffect(() => {
    if (selectedUserAuthSite.length !== 1) return;

    fetchWorkHistoryData();
  }, [selectedUserAuthSite, period, selectErrorType, selectRobotType]);

  return (
    <div>
      <section className="flex gap-5 pb-3" data-name="bg">
        <>
          <select
            className="w-full min-w-[130px] max-w-[200px] px-3 py-[8px]   text-sm border border-white rounded-lg shadow-sm"
            value={selectErrorType}
            onChange={handleSelectErrorType}
            data-name="robotType"
          >
            <option hidden>에러 타입 선택</option>
            <option value="">{t("error_types_all")}</option>
            {ERROR_TYPE_LIST.map((item: string, index: number) => {
              return (
                <option key={index} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
          <select
            className="w-full min-w-[130px] max-w-[200px] px-3 py-[8px]   text-sm border border-white rounded-lg shadow-sm"
            value={selectRobotType}
            onChange={handleSelectRobotType}
            data-name="robotType"
          >
            <option hidden>로봇 타입 선택</option>
            <option value="">{t("robot_types_all")}</option>
            {robotTypeFilter.length !== 0 &&
              robotTypeFilter.map(
                (item: { value: string; label: string }, index: number) => {
                  return (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  );
                }
              )}
          </select>

          <input
            className="w-full min-w-[130px] max-w-[200px] px-3 py-2  text-sm border border-white rounded-lg shadow-sm"
            type="text"
            placeholder={t("robot_name_searching")}
            value={selectRobotName}
            onChange={handleChangeRobotName}
          />

          <div className="pr-12 min-w-[300px] datepicker-notification">
            <Datepicker
              value={period}
              inputClassName={
                "w-full  py-2 px-3 text-sm focus:bg-[#ebf7f6] placeholder:text-sm rounded-lg sm:text-sm shadow-sm "
              }
              placeholder={t("please_select_a_period")}
              primaryColor={"teal"}
              useRange={false}
              showShortcuts={true}
              onChange={handleChangeDate}
              i18n={`${i18next.resolvedLanguage}`}
              dateLooking="middle"
              configs={{
                shortcuts: {
                  today: `${t("today")}`,
                  yesterday: `${t("yesterday")}`,
                  currentMonth: `${t("currentMonth")}`,
                  pastMonth: `${t("pastMonth")}`,
                  past: (period) => `${t("the_last")} ${period}${t("days")}`,
                },
              }}
            />
          </div>

          <div className="pr-3 min-w-[400px] mt-[5px] flex justify-end ml-auto">
            <span className="mt-[5px]">마지막 업데이트 : {lastUpdated}</span>

            <div className="ml-3">
              <Button
                onClick={handleClickRefresh}
                disabled={period.startDate === null}
                type="primary"
                ghost
              >
                <TbRefresh
                  size="24"
                  color={period.startDate === null ? "#D9D9D9" : "#08B295"}
                  className={refreshing ? "animate-spin-fast" : ""}
                />
              </Button>
            </div>
          </div>
        </>
      </section>
      <div className="pt-3 pb-7">
        <div className="text-center text-sm w-[100%]">
          {!loading && !refreshing ? (
            workHistoryData.length > 0 ? (
              <>
                <div className="flex items-center font-bold text-center shadow-sm bg-neutral-200 rounded-xl">
                  <div className="px-2 py-3 w-[15%]">
                    <Trans i18nKey="Time" />
                  </div>
                  <div className="py-3 w-[10%]">
                    <Trans i18nKey="time_required" />
                  </div>
                  <div className="py-3 w-[20%]">
                    <Trans i18nKey="task_name" />
                  </div>
                  <div className="py-3 w-[5%]">
                    <Trans i18nKey="robot_type" />
                  </div>
                  <div className="py-3 w-[15%]">
                    <Trans i18nKey="robot_name" />
                  </div>
                  <div className="py-3 w-[5%]">
                    <Trans i18nKey="alarm_classification" />
                  </div>
                  <div className="py-3 w-[20%]">
                    <Trans i18nKey="details" />
                  </div>
                  <div className="py-3 w-[10%]">
                    <Trans i18nKey="completion_status" />
                  </div>
                </div>
                {workHistoryData.map(
                  (item: any, idx: number) =>
                    item.robotNameList.some((name: string) =>
                      name.toLowerCase().includes(selectRobotName.toLowerCase())
                    ) && (
                      <div key={idx}>
                        <div
                          className={`flex items-center bg-white hover:cursor-pointer hover:bg-[#d3ece8] ${
                            localStorage.login === "true" &&
                            localStorage.verified === "true"
                              ? "justify-center mt-1 rounded-xl shadow-sm"
                              : "blur-sm "
                          }`}
                        >
                          <div className="py-3 w-[15%]">
                            <span className="block">
                              {new Date(item.startTime).toLocaleString()}
                            </span>
                            <span className="block">
                              {new Date(item.lastUpdateTime).toLocaleString()}
                            </span>
                          </div>
                          <div className="py-3 w-[10%]">
                            {item.ellapsedTimeTotal.split(".")[0]}
                          </div>
                          <div className="w-[20%] flex justify-center">
                            {item.taskName}
                          </div>

                          <div key={idx} className="py-3 w-[5%]">
                            {item.robotModelTypeList?.map(
                              (type: string, idx: number) => (
                                <span className="block" key={idx}>
                                  {type}
                                </span>
                              )
                            )}
                          </div>

                          <div className="py-3 w-[15%]">
                            {item.robotNameList?.map(
                              (name: string, idx: number) => (
                                <span className="block" key={idx}>
                                  {name}
                                </span>
                              )
                            )}
                          </div>

                          <div
                            className={`py-3 w-[5%] ${
                              item.alarmType === "ERROR" ? "text-red-500" : ""
                            }`}
                          >
                            {item.alarmType}
                          </div>
                          <div className="py-3 w-[20%]">
                            {item.taskStateAlias}
                          </div>
                          <div
                            className={`py-3 w-[10%] ${
                              item.completeStatus === "비정상"
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {item.completeStatus}
                          </div>
                        </div>
                      </div>
                    )
                )}
              </>
            ) : (
              <div className="flex items-center justify-center w-full pt-[80px]">
                <Empty
                  mainText={t("There_are_no_completed_works")}
                  subText={t(
                    "The_selected_site_does_not_have_any_completed_works_during_the_specified_period"
                  )}
                >
                  {<img src={wariningIcon} className="w-[48px] py-4" />}
                </Empty>
              </div>
            )
          ) : (
            <div className="mt-[200px]">
              <Spinner />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Notification;
