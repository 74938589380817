import { Dispatch, SetStateAction } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";

import { getListByReference } from "./common";
import { FirestoreCollenctions, SiteType } from "../../shared/types";
import { db } from "../../shared/lib";

export async function getAllSiteList() {
  try {
    const siteQuery = query(
      collection(db, FirestoreCollenctions.SITES),
      where("is_deleted", "==", false)
    );

    const results = await getDocs(siteQuery);
    return results.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    })) as SiteType[];
  } catch (error) {
    console.error(error);
    throw new Error("사이트 목록을 불러올 수 없습니다.");
  }
}

export async function getAllSiteListOnSnapshot(
  setData: Dispatch<SetStateAction<SiteType[]>>
) {
  try {
    const siteQuery = query(
      collection(db, FirestoreCollenctions.SITES),
      where("is_deleted", "==", false)
    );

    onSnapshot(siteQuery, (snap) => {
      const results = snap.docs.map(
        (doc) => ({ id: doc.id, ...doc.data() } as SiteType)
      );

      setData(results);
    });
  } catch (error) {
    console.error(error);
    throw new Error("사이트 목록을 불러올 수 없습니다.");
  }
}

export async function getSiteListWithAuth(siteData: string[]) {
  try {
    const res = await getListByReference<SiteType>(siteData);
    return res as SiteType[];
  } catch (error) {
    throw new Error("사이트 목록을 불러올 수 없습니다.");
  }
}

export async function getSite(siteList: string[]) {
  const list = [];
  try {
    return siteList.map(async (id) => {
      const docRef = doc(db, FirestoreCollenctions.SITES, id);
      list.push(await getDoc(docRef));
    });
  } catch (error) {
    console.error(error);
    throw new Error("사이트를 없습니다.");
  }
}
