import { getAuth, sendEmailVerification } from "firebase/auth";
import { useEffect, useState } from "react";
import logo from "../shared/assets/image/SOLlink.png";
import { Link } from "react-router-dom";
import useUserStore from "../shared/store/userStore";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../shared/lib";
import { useTranslation } from "react-i18next";
import { FirestoreCollenctions } from "../shared/types";
import { useLoginStore } from "../features/login/model";

export default function Verification() {
  const [isEmailSended, setIsEmailSended] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const { t } = useTranslation();
  const auth = getAuth();
  const { updateIsLoggedIn, changeUserInfo, userEmail } = useLoginStore();

  const { changeUserStatus } = useUserStore();

  const getUserStatus = async (user: any) => {
    const docRef = doc(db, FirestoreCollenctions.USERS, user.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      changeUserStatus(docSnap.data().permission);

      localStorage.setItem(
        "hasAuthSite",
        docSnap.data().auth_sites.length > 0 ? "true" : "false"
      );
      localStorage.setItem(
        "hasAuthRobot",
        docSnap.data().auth_robots.length > 0 ? "true" : "false"
      );
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      if (auth.currentUser) {
        sendEmailVerification(auth.currentUser);
        setIsEmailSended(true);
      }
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    if (isEmailSended) {
      const intervalId = setInterval(() => {
        auth.currentUser?.reload();
        if (auth.currentUser) {
          if (auth.currentUser.emailVerified) {
            setIsVerified(true);

            localStorage.setItem("login", "true");
            localStorage.setItem("verified", "true");
            updateIsLoggedIn(true);
            changeUserInfo(auth.currentUser);
            getUserStatus(auth.currentUser);
            setTimeout(() => setIsCompleted(true), 2000);
            clearInterval(intervalId);
          }
        }
      }, 1000);
    }
  }, [isEmailSended]);

  return (
    <div className="w-[100%] bg-[#08B29520]">
      <div className="h-[100vh] flex flex-col justify-center items-center drop-shadow-md">
        <div className="bg-white w-[360px] py-10 rounded-xl flex flex-col items-center">
          {!isCompleted ? (
            <>
              <img src={logo} className="w-[180px]" />
              <div className="my-2 text-xs text-center text-gray-800">
                <div>
                  {t("to_use_Solink_services_please_verify_your_email")}
                </div>
              </div>
              <form
                onSubmit={handleSubmit}
                className="flex flex-col justify-center items-center text-sm w-[300px] py-2"
              >
                <div className="text-sm text-gray-600 font-bold self-start my-1 w-[97%]">
                  {t("email")}
                </div>
                <input
                  name="email"
                  type="text"
                  readOnly
                  disabled
                  value={userEmail || ""}
                  className="p-2 px-3 my-1 w-[97%] border text-gray-400"
                />
                <input
                  disabled={isEmailSended}
                  type="submit"
                  value={
                    isEmailSended
                      ? t("transmission_complete")
                      : t("send_authentication_mail")
                  }
                  className={
                    isEmailSended
                      ? "bg-gray-200 text-gray-500 w-[97%] rounded-full p-2 text-sm mt-2"
                      : "hover:cursor-pointer bg-[#08B295] text-white w-[97%] rounded-full p-2 text-sm mt-2"
                  }
                />
              </form>
              <div className="text-xs font-bold text-cyan-500">
                {isEmailSended &&
                  !isVerified &&
                  t("verification_email_has_been_sent_Please_check")}
                {isVerified && t("verification_is_complete")}
              </div>
            </>
          ) : (
            <>
              <img src={logo} className="w-[180px]" />
              <div className="mt-5 text-xs text-center fade-in-effect">
                <div>{t("verification_is_complete")}!</div>
                <div>{t("now_try_using_solink_services")}</div>
              </div>
              <Link
                to="/"
                className="mt-5 px-3 py-2 bg-[#08B295] text-white rounded-xl text-sm fade-in-effect"
              >
                {t("navigate_to_the_solink_homepage")}
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
