import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { GoogleAuthProvider, signInWithPopup, getAuth } from 'firebase/auth';

import logo from '../../../shared/assets/image/SOLlink.png';
import googlelogo from '../../../shared/assets/image/google.png';
import { useTranslation } from 'react-i18next';
import { useLoginStore } from '../model';
import { checkExistUser } from '../api';
import { setCookie } from '../../../shared/util/cookie';
import useUserStore from '../../../shared/store/userStore';
import { Pathname } from '../../../shared/types';

export default function LoginModal() {
  const { updateIsShowLoginModal, updateIsLoggedIn } = useLoginStore();
  const { updateGoogleUserInfo, updateUserInfo } = useUserStore();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const auth = getAuth();

  const handleGoogleLogIn = async () => {
    try {
      const provider = new GoogleAuthProvider();

      const result = await signInWithPopup(auth, provider);

      const credential = GoogleAuthProvider.credentialFromResult(result);
      if (!credential) throw new Error('로그인할 수 없습니다.');

      const accessToken = credential.accessToken;
      if (!accessToken) throw new Error('로그인할 수 없습니다.');

      const user = result.user;
      const idToken = await user.getIdToken();

      setCookie('accessToken', accessToken);
      setCookie('idToken', idToken);

      const { data, statusCode } = await checkExistUser();
      const userApproveState = data?.state?.name;

      updateUserInfo(data);
      updateIsShowLoginModal(false);
      updateIsLoggedIn(true); // 제거 예정
      updateGoogleUserInfo(user);

      if (
        (statusCode === 200 && !data) ||
        userApproveState === 'PENDING_APPROVAL'
      ) {
        return navigate(Pathname.SIGNUP);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const closeLoginModal = (event: any) => {
    const name = event.target.dataset.name;
    if (pathname === '/' && name === 'background') {
      updateIsShowLoginModal(false);
    }
  };

  const [effectStyle, setEffectStyle] = useState(
    'bg-white w-[360px] rounded-xl py-10 flex flex-col justify-center items-center scale',
  );
  useEffect(() => {
    if (
      effectStyle ===
      'bg-white w-[360px] rounded-xl py-10 flex flex-col justify-center items-center'
    ) {
      setEffectStyle(
        'bg-white w-[360px] rounded-xl py-10 flex flex-col justify-center items-center scale',
      );
    } else {
      setEffectStyle(
        'bg-white w-[360px] rounded-xl py-10 flex flex-col justify-center items-center',
      );
      setTimeout(
        () =>
          setEffectStyle(
            'bg-white w-[360px] rounded-xl py-10 flex flex-col justify-center items-center scale',
          ),
        100,
      );
    }
  }, [pathname]);

  return (
    <div
      className="fixed w-[100%] h-[100vh] bg-[#00000025] z-[200] flex justify-center items-center scale"
      onClick={closeLoginModal}
      data-name="background"
    >
      <div className={effectStyle}>
        <img src={logo} className="w-[180px]" />

        {localStorage.login === 'false' ? (
          <>
            <span className="m-2 text-xs">
              {t('sign_in_now_and_use_solink')}
            </span>
            <div className="text-xs mt-5 mb-2 text-gray-800 border-t w-[90%] text-center pt-6">
              {t('start_in_3_seconds_with_simple_sign_in')}
            </div>
            <img
              src={googlelogo}
              className="w-[160px] my-1 hover:cursor-pointer"
              alt="google"
              onClick={handleGoogleLogIn}
            />
          </>
        ) : (
          <>
            <div className="my-6 text-sm text-center text-gray-800">
              <div>{t('this_account_has_not_been_authenticated_by_email')}</div>
              <div>
                {t('please_authenticate_your_email_to_use_the_service')}
              </div>
            </div>
            <Link
              to="/verification"
              className="bg-[#08B295] px-3 py-2 rounded-xl text-sm text-white"
            >
              {t('authenticate')}
            </Link>
          </>
        )}
      </div>
      {/* <div className="flex flex-col justify-center items-center bg-white p-9 rounded-2xl w-[320px]"> */}

      {/* </div> */}
    </div>
  );
}
