import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useBlurStyle } from "../../../../shared/hooks";
import { ErrorRobotType } from "../../../../shared/types";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

type PropsType = {
  robotData: ErrorRobotType[];
  max: number;
};

export default function MostErrorBarChart({ robotData, max }: PropsType) {
  const blurStyle = useBlurStyle();
  const [labelList, setLabelList] = useState<string[]>([]);

  useEffect(() => {
    if (robotData.length !== 0) {
      const labels = robotData?.map((item: any) => item.robotName);
      setLabelList(labels);
    }
  }, [robotData]);

  const options = {
    indexAxis: "y" as const,
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 500,
    },
    responsiveAnimationDuration: 0,
    plugins: {
      legend: {
        display: false,
        position: "bottom" as const,
      },
      tooltip: {
        boxPadding: 10,
        caretSize: 0,
        displayColors: false,
        callbacks: {
          title: (tooltipItems: any) => {
            const { dataIndex } = tooltipItems[0];
            return labelList[dataIndex];
          },
          label: (tooltipItems: any) => {
            return tooltipItems.formattedValue;
          },
        },
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        max: max,
        display: false,
        grid: { display: false },
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          // stepSize: 5,
        },
        grid: { display: false },
      },
    },
  };

  const labels = robotData?.map(
    (item: any) => item.robotName.slice(0, 5) + "..."
  );

  const data = {
    labels,
    datasets: [
      {
        label: "에러",
        data: robotData.map((item: any) => item.errorDatas[0].cnt),
        backgroundColor: "#85B093",
      },
    ],
  };

  return (
    <div
      className={`w-[95%] h-[200px] self-center p-3 px-5 md:p-0 ${blurStyle}`}
    >
      <Bar options={options} data={data} />
    </div>
  );
}
