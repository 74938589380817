import React, { useEffect, useState } from "react";
//hall call
//car call
import errorPage from "../../../pages/ErrorPage";
import { IoMoveSharp } from "react-icons/io5";
import { GoWorkflow } from "react-icons/go";
import { getTaskNodeList } from "../api";
import { useWorkflowStore } from "../model";
/*SVG*/

const ItemTask = (props: any) => {
  const { robotInfo } = useWorkflowStore();

  const [nodeList, setNodeList] = useState<any>([]);

  const onDragStart = (event: any, nodeType: any) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.setData("id", event.target.id);
    event.dataTransfer.effectAllowed = "move";
  };

  useEffect(() => {
    if (props.isReset) {
      setNodeList([]);
    }
  }, [props.isReset]);

  useEffect(() => {
    if (robotInfo) {
      getTaskNodeListAPI(robotInfo.deviceId);
    }

    if (props.reRender) {
      props.resetReRender(false, "task");
    }
  }, [robotInfo, props.reRender]);

  const getTaskNodeListAPI = async (deviceId: string) => {
    await getTaskNodeList(deviceId)
      .then((res) => {
        if (res !== null && res !== undefined) {
          console.log("res = ", res);

          setNodeList(res);
        }
      })
      .catch((error) => {
        errorPage();
      });
  };

  const iconMapping = (code: string) => {
    const name = code.replace("NODE_", "");
    switch (name) {
      case "MOVE": {
        return <IoMoveSharp size="20" color="rgb(100, 100, 100)" />;
      }
      case "STOP": {
        break;
      }

      case "HOME": {
        break;
      }
      case "HALL_CALL": {
        break;
      }
      case "CAR_CALL": {
        break;
      }
      case "OPEN_DOOR": {
        break;
      }
      case "KEEP_OPEN": {
        break;
      }
      case "CHECK": {
        break;
      }
      case "CHECK_SELECT": {
        break;
      }
      case "SET_VALUE": {
        break;
      }
    }
  };
  return (
    <aside className={"wf-body-left-contents"}>
      {/* TASK */}
      {nodeList.length > 0
        ? nodeList.map((item: any, idx: number) => {
            return (
              <div
                key={`node_${idx}`}
                id={`TASK|${item.taskId}`}
                draggable={true}
                onDragStart={(event) => onDragStart(event, "default")}
              >
                <div style={styles.parent}>
                  <div style={styles.iconParent}>
                    <GoWorkflow size={20} />
                  </div>
                  {item.taskName}
                </div>
              </div>
            );
          })
          : <div className={"text-gray-400 text-[14px]"}>등록된 TASK 가 없습니다.</div>}
    </aside>
  );
};

export default ItemTask;

const styles = {
  parent: {
    padding: 7,
    fontSize: 14,
    background: "#fff",
    cursor: "move",
    marginTop: 10,
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    border: "1px solid #ddd",
    fontFamily: "Gmarket Sans, Medium",
  },
  iconParent: {
    borderRight: "1px solid rgb(230, 230, 230)",
    padding: 5,
    paddingRight: 10,
    paddingLeft: 5,
    marginRight: 10,
  },
  splitIcon: {
    transform: "rotate(90deg)",
  },
  joinIcon: {
    transform: "rotate(-90deg)",
  },
  sidebarItemHeading: {
    fontSize: 16,
    fontWeight: "bold",
    marginLeft: "10px",
    lineHeight: "normal",
  },
  sidebarItemHeadingContainer: {
    marginTop: 24,
    marginBottom: 12,
  },
};
