import useUserStore from "../../../shared/store/userStore";
import { Suspense } from "react";

import {
  AllRobotsDonutChart,
  MostUsedLineChart,
  MostUsedDestinationBarChart,
  SuccessRateBarChart,
  MostUsedRobots,
  MostErrorRobots,
  ServiceBarChart,
} from "../../../features/chart/ui";
import { AllRobotsSkeleton } from "../../../shared/ui/Skeleton";

export default function ChartContainer(period: any) {
  const { selectedUserAuthSite } = useUserStore();

  const siteQuery = selectedUserAuthSite.join(",");

  const startDate = period.period.startDate;
  const endDate = period.period.endDate;

  return (
    <section className="px-12 pt-3 pb-10">
      <section className="flex flex-col w-full h-[400px] tab:flex-row mb-1">
        <div className="w-full tab:w-[65.5%] h-[400px] rounded-md tab:mt-0 tab:mr-1 bg-white shadow-sm">
          <SuccessRateBarChart />
        </div>
        <div className="w-full tab:w-[35.5%] h-[400px] rounded-md tab:mt-0 tab:mr-1 bg-white shadow-sm">
          <MostUsedRobots startDate={startDate} endDate={endDate} />
        </div>
      </section>
      <section className="flex flex-col w-full h-[400px] tab:flex-row mb-1">
        <div className="w-full tab:w-[50%] h-[400px] rounded-md tab:mt-0 tab:mr-1 bg-white shadow-sm">
          <ServiceBarChart />
        </div>
        <div className="w-full tab:w-[50%] h-[400px] rounded-md tab:mt-0 tab:mr-1 bg-white shadow-sm">
          <MostUsedDestinationBarChart
            startDate={startDate}
            endDate={endDate}
          />
        </div>
      </section>
      <section className="flex flex-col w-full h-[400px] tab:flex-row mb-1">
        <div className="w-full tab:w-[100%] h-[400px] rounded-md tab:mt-0 tab:mr-1 bg-white shadow-sm">
          <MostErrorRobots startDate={startDate} endDate={endDate} />
        </div>
      </section>
      <section className="flex flex-col w-full h-[400px] tab:flex-row mb-1">
        <div className="w-full tab:w-[65.5%] h-[400px] rounded-md tab:mt-0 tab:mr-1 bg-white shadow-sm">
          <MostUsedLineChart startDate={startDate} endDate={endDate} />
        </div>
        <div className="w-full tab:w-[35.5%] h-[400px] rounded-md tab:mt-0 tab:mr-1 bg-white shadow-sm">
          <Suspense fallback={<AllRobotsSkeleton />}>
            {siteQuery ? (
              <AllRobotsDonutChart siteQuery={siteQuery} />
            ) : (
              <AllRobotsSkeleton />
            )}
          </Suspense>
        </div>
      </section>
    </section>
  );
}
