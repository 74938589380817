import { useEffect, useState } from "react";
import {
  AgoraVideoPlayer,
  createClient,
  createMicrophoneAndCameraTracks,
  ClientConfig,
  IAgoraRTCRemoteUser,
} from "agora-rtc-react";

export const CALL_STATE = {
  NONE: 0,
  CALLING: 1,
  IN_CALL: 2,
  HANGUP: 3,
};

const config: ClientConfig = { mode: "rtc", codec: "vp8" };
const useClient = createClient(config);
const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();

const appId = "fcdbd1d06ba24c7db3e917fd50972ff9"; //ENTER APP ID HERE
const uid = 11;

const FomeVideo = ({ deviceId }: { deviceId: string }) => {
  if (!deviceId) return <div>deviceId is null</div>;

  const client = useClient();
  const { ready, tracks } = useMicrophoneAndCameraTracks();

  const [channelName, setChannelName] = useState(deviceId);

  const [users, setUsers] = useState<IAgoraRTCRemoteUser[]>([]);

  const [callState, setCallState] = useState<number>(CALL_STATE.NONE);

  useEffect(() => {
    return () => {
      client.leave();
      client.removeAllListeners();

      if (tracks) {
        tracks.forEach((track) => {
          track.close();
        });
      }
    };
  }, []);

  useEffect(() => {
    if (ready && tracks) {
      init(channelName);
    }
  }, [channelName, client, ready, tracks]);

  const init = async (name: string) => {
    client.on("user-published", async (user, mediaType) => {
      await client.subscribe(user, mediaType);
      if (user.uid != uid) {
        setCallState(CALL_STATE.CALLING);
        alert(
          "포미에서 전화를 걸어왔습니다. 영상 통화 화면의 버튼을 눌러 수락해주세요."
        );
      }
      if (mediaType === "video") {
        setUsers((prevUsers) => {
          return [...prevUsers, user];
        });
      }
      if (mediaType === "audio") {
        user.audioTrack?.play();
      }
    });

    client.on("user-unpublished", (user, type) => {
      if (type === "audio") {
        user.audioTrack?.stop();
      }
      if (type === "video") {
        setUsers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
      }
    });

    client.on("user-left", (user) => {
      setUsers((prevUsers) => {
        return prevUsers.filter((User) => User.uid !== user.uid);
      });
      onClickHangup();
    });

    const tokenResponse = await fetch(
      "https://us-central1-orionstar-mini-vcwv.cloudfunctions.net/getAgoraToken",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          channelName: name,
          uid: uid,
        }),
      }
    );
    const tokenJson = await tokenResponse.json();
    const token = tokenJson.rtcToken;
    await client.join(appId, name, token, uid);
  };

  const onClickHangup = async () => {
    // TODO: 뒤로갈때 실행
    await client.leave();
    client.removeAllListeners();

    setCallState(CALL_STATE.HANGUP);
    setUsers([]);

    setTimeout(() => {
      setCallState(CALL_STATE.NONE);
    }, 800);
  };

  useEffect(() => {
    if (callState == CALL_STATE.HANGUP && users.length == 0) {
      init(channelName);
    }
  }, [callState, users]);

  const onClickAccept = async () => {
    if (callState != CALL_STATE.CALLING) return;
    setCallState(CALL_STATE.IN_CALL);
    if (tracks) await client.publish(tracks);
  };

  return (
    <div className="video-connection homecare-robot">
      <div className="content-container">
        <div className="video-screen">
          <div
            className="pip"
            style={{
              zIndex: callState != CALL_STATE.IN_CALL ? -1 : 1,
              display: callState == CALL_STATE.IN_CALL ? "block" : "none",
            }}
          >
            <div id="localVideo">
              {tracks && (
                <AgoraVideoPlayer
                  className="vid"
                  videoTrack={tracks[1]}
                  style={{ width: "150px", height: "150px" }}
                />
              )}
            </div>
          </div>
          <div
            id="mainVideo"
            style={{
              zIndex: callState != CALL_STATE.IN_CALL ? -1 : 0,
              display: callState == CALL_STATE.IN_CALL ? "block" : "none",
            }}
          >
            {users.length > 0 &&
              users.map((user) => {
                if (user.videoTrack) {
                  return (
                    <AgoraVideoPlayer
                      className="vid"
                      videoTrack={user.videoTrack}
                      style={{ width: "100%", height: "100%" }}
                      key={user.uid}
                    />
                  );
                } else return null;
              })}
          </div>
          {(callState == CALL_STATE.NONE ||
            callState == CALL_STATE.CALLING) && (
            <>
              <div className="title-container">
                <div className="mrs-logo"></div>
                <div className="title-box">
                  <h1>마로솔 통합 관제시스템</h1>
                  <p>Bigwave Robotics Inc. All Rights Reserved</p>
                </div>
              </div>
              {callState == CALL_STATE.CALLING && (
                <div className="btn btn-container">
                  <button
                    id="closeSelf"
                    className="btn-call connection"
                    style={{ display: "inline-block" }}
                    onClick={onClickAccept}
                  >
                    <i></i>
                    <span>받기</span>
                  </button>
                </div>
              )}
            </>
          )}
          {callState == CALL_STATE.HANGUP && (
            <div className="called-container" style={{ display: "inherit" }}>
              <div className="called-title-box">
                <h1>통화가 종료되었습니다</h1>
              </div>
              <div className="none-user-profile-box">
                <div>포미</div>
                <span>포미</span>
              </div>
            </div>
          )}
          {callState == CALL_STATE.IN_CALL && (
            <>
              <div className="btn btn-container" style={{ zIndex: 1 }}>
                <button
                  id="closeSelf"
                  className="btn-call disconnect"
                  style={{ display: "inline-block" }}
                  onClick={onClickHangup}
                >
                  <i></i>
                  <span>끊기</span>
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FomeVideo;
