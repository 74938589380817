import {
  AllRoborts,
  ChartRobotType,
  ErrorRobotType,
  MostUsedDestination,
  PeriodWithSiteId,
  RobotServiceCount,
  SuccessRate,
  UsedRobotType,
} from "../../../shared/types";
import { baseApi } from "../../../shared/api";

const { client } = baseApi;
export async function getAllRobots(query: string): Promise<AllRoborts> {
  try {
    const res = await client.get(
      `/dashboard/auth/total/robot/cnt?siteIds=${query}`
    );

    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error("해당 기간의 데이터를 받아올 수 없습니다.");
  }
}

export async function getRobotAvgByPeriod(
  data: PeriodWithSiteId
): Promise<RobotServiceCount> {
  try {
    const { startDate, endDate, siteIds } = data;
    const res = await client.get(
      `/dashboard/auth/total/robot/service/monthly/cnt?startDate=${startDate}&endDate=${endDate}&siteIds=${siteIds.join(
        ","
      )}`
    );

    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error("해당 기간의 데이터를 받아올 수 없습니다.");
  }
}

export async function getRobotMinMaxByPeriod(
  data: PeriodWithSiteId
): Promise<UsedRobotType[]> {
  try {
    const { startDate, endDate, siteIds } = data;
    const res = await client.get(
      `/dashboard/auth/total/robot/service/cnt/between-dates?startDate=${startDate}&endDate=${endDate}&siteIds=${siteIds.join(
        ","
      )}`
    );

    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error("해당 기간의 데이터를 받아올 수 없습니다.");
  }
}

export async function getUsageTimeByPeriod(
  data: PeriodWithSiteId
): Promise<ChartRobotType[]> {
  try {
    const { startDate, endDate, siteIds } = data;

    const res = await client.get(
      `/dashboard/auth/total/robot/service/hourly/cnt?startDate=${startDate}&endDate=${endDate}&siteIds=${siteIds.join(
        ","
      )}`
    );
    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error("해당 기간의 데이터를 받아올 수 없습니다.");
  }
}

export async function getErrorRobotByPeriod(
  data: PeriodWithSiteId
): Promise<ErrorRobotType[]> {
  try {
    const { startDate, endDate, siteIds } = data;
    const res = await client.get(
      `/dashboard/auth/total/robot/error/cnt?startDate=${startDate}&endDate=${endDate}&siteIds=${siteIds.join(
        ","
      )}`
    );

    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error("해당 기간의 데이터를 받아올 수 없습니다.");
  }
}

export async function getSuccessRateByPeriod(
  data: PeriodWithSiteId
): Promise<SuccessRate[]> {
  try {
    const { startDate, endDate, siteIds } = data;
    const res = await client.get(
      `/dashboard/auth/total/robot/service/error/monthly/cnt?startDate=${startDate}&endDate=${endDate}&siteIds=${siteIds.join(
        ","
      )}`
    );

    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error("해당 기간의 데이터를 받아올 수 없습니다.");
  }
}

export async function getDestinationsByPeriod(
  data: PeriodWithSiteId
): Promise<MostUsedDestination[]> {
  try {
    const { startDate, endDate, siteIds } = data;

    const res = await client.get(
      `/dashboard/auth/total/robot/service/poi/cnt?startDate=${startDate}&endDate=${endDate}&siteIds=${siteIds.join(
        ","
      )}`
    );

    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error("해당 기간의 데이터를 받아올 수 없습니다.");
  }
}
