import { ContentPoiType, ContentType } from "../../../shared/types";
import { baseApi } from "../../../shared/api";
const { client } = baseApi;

export async function getContentsList(deviceId: string) {
  try {
    const res = await client.get(
      `/robot/dogu/mission/pois/contents/${deviceId}`
    );
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export async function postPlayContents(contentsData: {
  deviceId: string;
  poiList: ContentPoiType[];
  contentList: ContentType[];
}) {
  try {
    const res = await client.post(
      `/robot/dogu/mission/pois/contents`,
      contentsData
    );
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export async function postPoiSync(deviceId: string) {
  try {
    const res = await client.post("/robot-poi/sync", { deviceId: deviceId });
    return res.data;
  } catch (error) {
    console.error(error);
  }
}
