import React, { useEffect, useState } from "react";
import { Button, Drawer, Input, Select } from "antd";
import { RiAddLine } from "react-icons/ri";
import { FiMinus } from "react-icons/fi";
import { FaSave } from "react-icons/fa";
import { useWorkflowStore } from "../model";

const settingSidebar = (props: any) => {
  const { nodeList } = useWorkflowStore();

  const [selectNode, setSelectNdoe] = useState<any>(null);

  const [title, setTitle] = useState<string>("");
  const [fullName, setFullName] = useState<string>("");
  const [action, setAction] = useState<string>("");

  const [conditionList, setConditionList] = useState<any>([
    { name: "", operator: "", value: "" },
  ]);

  useEffect(() => {
    setSelectNdoe(props.nodeData);

    if (props.nodeData !== null && props.nodeData !== undefined) {
      if (
        props.nodeData.condition !== null &&
        props.nodeData.condition !== undefined
      ) {
        if (props.nodeData.condition.length > 0) {
          setConditionList([...props.nodeData.condition]);
        }
      } else {
        setConditionList([{ name: "", operator: "", value: "" }]);
      }
    }
  }, [props.nodeData]);

  useEffect(() => {
    if (selectNode !== undefined && selectNode !== null) {
      setTitle(selectNode.data.label.props.title);
      setFullName(selectNode.data.label.props.fullName);
      setAction(selectNode.data.label.props.action);
    }
  }, [selectNode]);

  const onSettingSidebarClose = () => {
    setConditionList([{ name: "", operator: "", value: "" }]);

    props.onNodeDoubleClick(false);
  };

  const conditionDel = (idx: number) => {
    conditionList.splice(idx, 1);
    setConditionList([...conditionList]);
  };
  const conditionAdd = () => {
    const data = { name: "", operator: "", value: "" };

    if (conditionList.length < 10) {
      setConditionList([...conditionList, data]);
    }
  };

  const inputName = (e: any, idx: number) => {
    const val = e.target.value;

    const newList = [...conditionList];
    newList[idx].name = val;
    setConditionList(newList);
  };

  const selectOperator = (value: any, idx: number) => {
    const newList = [...conditionList];
    newList[idx].operator = value;
    setConditionList(newList);
  };

  const inputValue = (e: any, idx: number) => {
    const val = e.target.value;

    const newList = [...conditionList];
    newList[idx].value = val;
    setConditionList(newList);
  };

  const settingDiv = () => {
    let html;

    switch (action) {
      case "CHECK": {
        html = (
          <div>
            <div style={{ marginBottom: "40px" }}>
              <span>조건 설정</span>
            </div>
            <div style={{ right: 0 }}>
              <Button size={"small"} onClick={() => conditionAdd()}>
                <RiAddLine size={15} style={{ alignItems: "center" }} />
              </Button>
            </div>

            <div>
              {/*10개*/}
              {conditionList.map((data: any, idx: number) => {
                return (
                  <div key={idx} style={{ marginTop: "10px" }}>
                    <Input
                      placeholder={"KEY"}
                      size={"middle"}
                      style={{ width: 100, marginRight: 5 }}
                      onChange={(e) => inputName(e, idx)}
                      value={data.name}
                    />
                    <Select
                      placeholder={"연산자"}
                      size={"middle"}
                      style={{ width: 80, marginRight: 5 }}
                      options={[
                        { label: "==", value: "==" },
                        { label: "!=", value: "!=" },
                        { label: "<=", value: "<=" },
                        { label: ">=", value: ">=" },
                        { label: ">", value: ">" },
                        { label: "<", value: "<" },
                      ]}
                      onChange={(value) => selectOperator(value, idx)}
                      value={data.operator}
                    />
                    <Input
                      placeholder={"VALUE"}
                      size={"middle"}
                      style={{ width: 100, marginRight: 5 }}
                      onChange={(e) => inputValue(e, idx)}
                      value={data.value}
                    />

                    {idx > 0 && (
                      <Button
                        size={"small"}
                        style={{ height: 30, float: "inline-end" }}
                        onClick={() => conditionDel(idx)}
                      >
                        <FiMinus size={15} style={{ alignItems: "center" }} />
                      </Button>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        );

        break;
      }
      default: {
        break;
      }
    }

    return html;
  };

  const save = (e: any) => {
    props.callBackSidebarData(props.nodeData.id, conditionList);
    props.onNodeDoubleClick(false);
  };

  return (
    <Drawer
      onClose={onSettingSidebarClose}
      open={props.open}
      title={
        <div>
          <span>{title} 설정</span>
        </div>
      }
      style={{
        backgroundColor: "white",
        borderLeft: "1px solid #eee",
        zIndex: "1000",
        position: "relative",
      }}
      footer={
        <div style={{ display: "flex" }}>
          <Button
            className={"buttonSwitch"}
            icon={<FaSave />}
            style={{ marginLeft: "5px", alignItems: "center", display: "flex" }}
            type={"primary"}
            onClick={(e) => save(e)}
          >
            확인
          </Button>
          <Button
            className={"buttonSwitch"}
            icon={<FaSave />}
            style={{ marginLeft: "5px", alignItems: "center", display: "flex" }}
            onClick={onSettingSidebarClose}
          >
            취소
          </Button>
        </div>
      }
    >
      <div>{settingDiv()}</div>
    </Drawer>
  );
};

export default settingSidebar;
