import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  Title,
  Legend,
} from "chart.js";
import { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Trans } from "react-i18next";

import { useBlurStyle } from "../../../shared/hooks";
import "../../../app/animation.css";

import { useSuspenseQuery } from "@tanstack/react-query";

import { Tooltip } from "react-tooltip";
import { numberFormat } from "../../../shared/util/format";
import { ModelTypes } from "../../../shared/types";
import useUserStore from "../../../shared/store/userStore";
import { Skeleton } from "../../../shared/ui";
import { LOCALE } from "../../../shared/constants";
import { getAllRobots } from "../api";

ChartJS.register(CategoryScale, LinearScale, Title, ArcElement, Legend);

interface AllRobotsProps {
  siteQuery: string;
}

export default function AllRobots({ siteQuery }: AllRobotsProps) {
  const blurStyle = useBlurStyle();

  const [robotType, setRobotType] = useState<ModelTypes[]>([]);
  const [robotCount, setRobotCount] = useState<number | null>(null);
  const { selectedUserAuthSite } = useUserStore();

  const {
    data: dataList,
    status,
    refetch,
    error,
  } = useSuspenseQuery({
    queryKey: ["allRobots"],
    queryFn: () => getAllRobots(siteQuery),
    retryOnMount: true,
  });

  useEffect(() => {
    refetch();
  }, [selectedUserAuthSite]);

  useEffect(() => {
    if (selectedUserAuthSite.length !== 0) {
      if (!dataList.modelTypes) return;
      setRobotType(dataList.modelTypes.sort((a: any, b: any) => b.cnt - a.cnt));
      setRobotCount(dataList.totalCnt);
    }
  }, [dataList, selectedUserAuthSite]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 500,
    },
    pieceLabel: {
      render: "label",
      fontColor: "#000",
      position: "outside",
      arc: true,
    },
    legend: {
      position: "bottom",
    },
    responsiveAnimationDuration: 0,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        color: "#fff" as const,
      },
      title: {
        display: false,
        text: "Title",
      },
    },
    scales: {
      x: {
        display: false,
      },
    },
  };

  const colors = [
    "#d4d4d8",
    "#71717a80",
    "#BDD1BD",
    "#85B093",
    "#568F7C",
    "#326D6C",
    "#173C4C",
    "#32788a",
    "#409cb3",
    "#60c3db",
    "#71e3e3",
    "#71e3c5",
  ];

  const data = {
    labels: robotType.map((item: any) => item.modelType),
    datasets: [
      {
        data: robotType.map((item: any) => item.cnt),
        backgroundColor: colors.map((item) => item),
        borderWidth: 1,
        cutout: 50,
      },
    ],
  };

  const [isShowTooltip, setIsShowTooltip] = useState(false);
  return (
    <div className="h-[100%] flex flex-col px-2 pt-2">
      <div className="flex px-3 py-2">
        <div className="p-2 mr-5">
          <Tooltip
            anchorSelect={"#allRobotsDonutChartTooltip"}
            place={"right"}
            content="솔링크의 로봇 등록 현황을 나타냅니다."
          />
          <div>
            <div
              id={"allRobotsDonutChartTooltip"}
              className="text-[#08B295] text-sm cursor-default"
            >
              <Trans i18nKey="full_registration_robot" />
            </div>

            <div
              className={`flex items-center text-lg font-bold mb-0 ${blurStyle}`}
            >
              {robotCount && (
                <div className="text-gray-800">
                  {numberFormat(robotCount, LOCALE)}
                  <Trans i18nKey="unit" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/*    <div className="flex justify-between items-center text-[#08B295]">*/}
      {/*        /!* <div>*/}
      {/*  <Trans i18nKey="robot_type_and_registration_status" />*/}
      {/*</div> *!/*/}
      {/*    </div>*/}

      <div
        className={`w-[97%] h-[100%] pl-5 pt-0 pb-2 mt-0 ${blurStyle}`}
        style={{ display: "flex" }}
      >
        <div className="w-[55%] h-[200px] self-center p-0 mr-5 justify-center items-end">
          {robotCount ? (
            <Doughnut options={options} data={data} />
          ) : (
            <div className="w-[200px] h-[200px] flex justify-center items-center">
              <Skeleton className="flex justify-center items-center w-[200px] h-[200px] rounded-full">
                <div className="rounded-full bg-white w-[100px] h-[100px]"></div>
              </Skeleton>
            </div>
          )}
        </div>

        <div className={`w-[45%] mt-0 ${blurStyle}`}>
          <div className="flex text-[0.8rem] justify-center text-center w-[90%] mt-2">
            <div className="py-1 font-bold rounded-l-lg w-[60%] bg-neutral-100">
              로봇 타입
            </div>
            <div className="py-1 font-bold rounded-r-lg w-[40%] bg-neutral-100">
              수
            </div>
          </div>

          <div className="w-[90%] h-[240px] text-gray-800 overflow-hidden hover:overflow-auto">
            {
              // Loading ? (
              //   <Skeleton className="w-[100%] h-[100%] mt-2 rounded-md" />
              // ) : (
              robotType.map((item: any, index: number) => (
                <div
                  key={index}
                  className="flex justify-center items-center text-[0.7rem] text-center border-b w-[100%]"
                >
                  <div className="py-2 w-[60%] flex justify-center items-center">
                    <div
                      style={{ backgroundColor: colors[index] }}
                      className="w-[0.75rem] h-[0.75rem] mr-2 rounded"
                    ></div>
                    <div>{item.modelType}</div>
                  </div>

                  <div className="py-2 w-[40%]">
                    {numberFormat(item.cnt, LOCALE)}
                  </div>
                </div>
              ))
              //)
            }
          </div>
        </div>
      </div>
    </div>
  );
}
// }
