import React, {ReactEventHandler, useCallback, useEffect, useRef, useState} from "react";
import {
    addEdge,
    Controls,
    MarkerType,
    MiniMap,
    ReactFlow,
    useEdgesState,
    useNodesState,
    useReactFlow,
    EdgeLabelRenderer,
    BaseEdge
} from "reactflow";
import {Button, Input, Modal, Radio, RadioChangeEvent, Select, Tabs} from "antd";
import ItemBuilder from "./ItemBuilder";
import ItemPreset from "./ItemPreset";
import ItemTask from "./ItemTask";
import {GrPowerReset} from "react-icons/gr";
import {FaPlay, FaSave, FaStop} from "react-icons/fa";
import {MdDeleteForever} from "react-icons/md";
import useUserStore from "../../../shared/store/userStore";
import {useUserData} from "../../../shared/hooks";
import {
    AuthType,
    FirestoreCollenctions,
    Permission,
} from "../../../shared/types";
import {
    collection,
    doc,
    getDoc,
    getDocs,
    query,
    where,
} from "firebase/firestore";
import {db} from "../../../shared/lib";
import {
    deleteTaskNode,
    deleteUsersetNode,
    getTaskNode,
    getUsersetNode,
    postTaskNode,
    postUsersetNode,
    putTaskNode,
    putUsersetNode,
    startWorkflow,
} from "../api";
import {
    actionIndexParser,
    workflowParser,
} from "../../../shared/util/workflowParser";
import {toast} from "react-toastify";
import SettingSidebar from "./SettingSidebar";
import {RiErrorWarningFill, RiListSettingsLine} from "react-icons/ri";
import TextArea from "antd/es/input/TextArea";
import {getUserById} from "../../../api/firestore/user";
import ".//workflow.css";

import {SearchInput} from "../../../shared/ui";
import errorPage from "../../../pages/ErrorPage";
import {NodeLabelList, NodeLabelStyle} from "./NodeLabelList";
import SettingWorkModal from "../../../features/schedule/ui/SettingWorkModal";
import {useRestRobotData} from "../../../features/robotManagement/model";
import {useLoginStore} from "../../../features/login/model";
import {useWorkflowStore} from "../model";
import ConditionEdge from "./ConditionEdge";
import {NodeEdgeValidation} from "../../../shared/util/nodeEdgeValidation";
import {getAllSiteList} from "../../../api/firestore/site";

import {Simulate} from "react-dom/test-utils";
import keyDown = Simulate.keyDown;

const lineTypeOptions = [
    {
        value: "straight",
        label: "직선",
    },
    {
        value: "step",
        label: "직각선",
    },
    {
        value: "default",
        label: "곡선",
    },
];

let num = 2;


const edgeTypes = {
    conditionEdge: ConditionEdge
};


const Flow = () => {
    const {userInfo} = useLoginStore();
    const {userStatus, updateIsShowUserIcon, selectedUserAuthSite} =
        useUserStore();
    const {user} = useUserData();
    // 로봇, 사이트
    const [userData, setUserData] = useState<AuthType | null>(null);
    const {
        robotInfo,
        robotCmdList,
        nodeList,
        edgeList, setElvList, elvList, changeEdgeList, edgeType,
        changeNodeList,
        changeRobotInfo,
        changeRobotCmdList,
        changeEdgeType,
        changeRobotType
    } = useWorkflowStore();
    const {changeSelectedUserAuthSites, changeUserStatus} = useUserStore();

    const reactFlowWrapper = useRef(null);

    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [reactFlowInstance, setReactFlowInstance] = useState<any>(null);

    const [nodeSettingBox, setNodeSettingBox] = useState<boolean>(false);

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [isTaskSaveModalOpen, setIsTaskSaveModalOpen] =
        useState<boolean>(false);
    const [isUsersetSaveModalOpen, setIsUsersetSaveModalOpen] =
        useState<boolean>(false);

    const [selectRobot, setSelectRobot] = useState<any>({});
    const [selectRobotValid, setSelectRobotValid] = useState<any>("");
    const [selectSiteValid, setSelectSiteValid] = useState<any>("");

    const [siteList, setSiteList] = useState<{ name: string; id: string }[]>([]);

    const [isReset, setIsReset] = useState<boolean>(false);

    const [tabNumber, setTabNumber] = useState<string>("1");

    const [taskNm, setTaskNm] = useState<string>("");
    const [taskDec, setTaskDec] = useState<string>("");
    const [usersetNm, setUsersetNm] = useState<string>("");
    const [usersetDec, setUsersetDec] = useState<string>("");

    const [taskId, setTaskId] = useState<number | null>();
    const [usersetId, setUsersetId] = useState<number | null>();

    const [resultJson, setResultJson] = useState<any>(null);

    const [commandTaskId, setCommandTaskId] = useState<string>("");

    const [useStartWf, setUseStartWf] = useState<boolean>(true);
    const [useStopWf, setUseStopWf] = useState<boolean>(true);

    const [preTaskNm, setPreTaskNm] = useState<string>("");
    const [preUsersetNm, setPreUsersetNm] = useState<string>("");

    const [taskReRender, setTaskReRender] = useState<boolean>(false);
    const [presetReRender, setPresetReRender] = useState<boolean>(false);

    const [init, setInit] = useState<boolean>(false);

    // const [lineType, setLineType] = useState<string>('default')

    const {confirm} = Modal;

    /*우측 창*/
    const [selectNodeInfo, setSelectNodeInfo] = useState<any>();
    const [selectEdgeInfo, setSelectEdgeInfo] = useState<any>();
    const [useCheckSelectNode, setUseCheckSelectNode] = useState<any>();

    //copy node
    const [copyNode, setCopyNode] = useState<string[]>([]);
    const [useCopy, setUseCopy] = useState<boolean>(false);


    const {setViewport} = useReactFlow();
    const {robotData, robotFilterData, setFilterRobotType} =
        useRestRobotData(userData);

    const [robotRadio, setRobotRadio] = useState<string>('single');


    const mobileCheck = /iPhone|iPad|iPod|Android/i.test(
        window.navigator.userAgent
    );


    const getId = () => {

        // return `action${Math.floor(Math.random() * 10000000)}`;

        const uuid = `action${num}`;
        num++;

        return uuid;
    }

    useEffect(() => {


    }, []);

    useEffect(() => {
        if (selectedUserAuthSite.length > 1) {
            onReset();
        }
    }, [selectedUserAuthSite.length]);

    useEffect(() => {
        if (nodeList.length > 0 && edgeList.length > 0) {
            setUseStartWf(false);
        }
    }, [nodeList, edgeList]);

    useEffect(() => {
        setFilterRobotType("엘리베이터");
    }, [robotData]);

    useEffect(() => {
        setElvList(robotFilterData);
    }, [robotFilterData]);

    useEffect(() => {

        num = 1;

        setNodes([]);
        setEdges([]);
        changeNodeList([]);
        changeEdgeList([]);

        setTaskNm('')

        if (taskId || usersetId) {
            setTaskId(null);
            setUsersetId(null);
        }
    }, [robotInfo]);

    useEffect(() => {
        if (!userInfo) return;
        const fetchData = async () => {
            const data = await getUserById(userInfo.uid);
            setUserData(data);
        };
        fetchData();
    }, [userInfo]);

    useEffect(() => {
        if (user?.permission === Permission.SUPER_ADMIN) {
            getAllSiteList().then((data) => {
                changeSelectedUserAuthSites(data.map((site) => site.id as string));
                setSiteList(
                    data.map((doc) => ({id: doc.id as string, name: doc.name}))
                );
            });
            return;
        }

        const fetchData = async (inputEmail: string) => {
            const sitesRef = collection(db, FirestoreCollenctions.USERS);
            const q = query(sitesRef, where("email", "==", inputEmail));

            const querySnapshot = await getDocs(q);
            return querySnapshot.docs.map((item) => item.data());
        };

        if (
            user &&
            (user.permission === Permission.ENGINEER ||
                userStatus === Permission.USER)
        ) {
            const temp: any = [];

            fetchData(user.email).then(async (res) => {
                if (res[0].auth_sites) {
                    for (let i = 0; i < res[0].auth_sites.length; i++) {
                        const docRef = doc(
                            db,
                            FirestoreCollenctions.SITES,
                            res[0].auth_sites[i]
                        );
                        const sites: any = await getDoc(docRef);
                        temp.push({name: sites.data()?.name, id: sites.id});
                    }
                }

                changeSelectedUserAuthSites(temp.map((item: any) => item.id));

                setSiteList(temp);
            });
        }
    }, [user, userStatus]);

    const onConnect = (params: any) => {

        const type = edgeType;

        params["style"] = {
            strokeWidth: 1.5,
            //stroke: '#3498db'
            //stroke: '#000',
        };
        params["animated"] = true;
        params["markerEnd"] = {
            type: MarkerType.ArrowClosed,
            width: 20,
            height: 20,
        }
        params['type'] = type;
        const {source, target} = params;
        const sourceNode = nodeList.filter((el: any) => el.id === source);

        let maxConnections = 1;
        if (sourceNode[0].data.label.props.action === 'CHECK_SELECT') {
            maxConnections = 5;

            //params['type'] = 'conditionEdge'
            params['label'] = 'IF'

        }


        const connectionFromSource = edges.filter((el) => el.source === source);


        if (connectionFromSource.length >= maxConnections) {
            toast.error("최대 연결 수를 초과하였습니다.");
            return false;
        }

        setEdges((eds: any) => addEdge(params, eds));
    };

    const onDragOver = useCallback((event: any) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";
    }, []);

    const onDrop = (event: any) => {
        if (!mobileCheck) {
            event.preventDefault();
        }

        let type = event.target.title;
        let name = event.target.id;
        let presetId = null;

        if (!mobileCheck) {
            type = event.dataTransfer.getData("application/reactflow");
            name = event.dataTransfer.getData("id");
            presetId = event.dataTransfer.getData("presetID");
        }

        // check if the dropped element is valid
        if (typeof type === "undefined" || !type) {
            return;
        }

        const position = reactFlowInstance.screenToFlowPosition({
            x: !mobileCheck ? event.clientX : event.changedTouches[0].clientX,
            y: !mobileCheck ? event.clientY : event.changedTouches[0].clientY,
        });

        const nameStr = name.split("|");

        const uuid = getId();

        let data = {

            label: <NodeLabelList key={uuid} title={nameStr[2]} id={uuid} fullName={`${nameStr[0]}|${nameStr[1]}`}
                                  type={nameStr[0]} action={nameStr[1]}/>
        };

        if (nameStr[0] === "PRESET") {
            data = {
                label: <NodeLabelList key={uuid} title={nameStr[1]} id={uuid} fullName={`${nameStr[0]}|${nameStr[1]}`}
                                      presetId={nameStr[2]}
                                      type={nameStr[0]} action={nameStr[1]}/>
            }
        }

        console.log('nameStr = ', nameStr);

        const style = NodeLabelStyle(nameStr[0], nameStr[1]);

        if (nameStr[1] === 'CHECK_SELECT') {
        }

        console.log('style = ', style)


        if (nameStr[0] === 'TASK') {

            setEdges([]);
            setNodes([]);

            getTaskNodeApi(nameStr[1]);
        } else if (nameStr[0] === "USERSET") {
            setEdges([]);
            setNodes([]);

            getUsersetNodeApi(nameStr[1]);
        } else {
            const newNode = {
                id: uuid,
                type,
                position,
                data: data,
                ...style,
            };

            if (type === 'input') {

                const check = nodeList.filter((item: any) => item.type === 'input');

                if (check.length > 0) {
                    toast.error("START 노드가 이미 존재합니다.")
                } else {
                    setNodes((nds) => nds.concat(newNode));
                    const copyNodeList = JSON.parse(JSON.stringify(nodeList));
                    copyNodeList.push(newNode);
                    changeNodeList(copyNodeList);
                }
            } else {
                setNodes((nds) => nds.concat(newNode));
                const copyNodeList = JSON.parse(JSON.stringify(nodeList));
                copyNodeList.push(newNode);
                changeNodeList(copyNodeList);
            }

        }
    };

    useEffect(() => {
        const delNode = nodeList.filter((node: any) =>
            nodes.some((nodeList) => nodeList.id === node.id)
        );

        if (nodeList.length === 0) {
            changeNodeList(nodes);
        } else {
            changeNodeList(delNode);
        }

        console.log('fdsafsdfasdf ===', nodes)

    }, [nodes.length]);

    useEffect(() => {
        changeEdgeList(edges);
    }, [edges.length]);

    const getTaskNodeApi = async (id: number) => {
        await getTaskNode(id)
            .then((res) => {
                if (res !== null && res !== undefined) {
                    setTaskId(id);
                    reStoreNodeId(res);
                    onRestore('TASK', res);
                }
            })
            .catch((error) => {
                errorPage();
            });
    };

    const getUsersetNodeApi = async (id: number) => {
        await getUsersetNode(id)
            .then((res) => {
                if (res !== null && res !== undefined) {
                    setUsersetId(id);
                    reStoreNodeId(res);
                    onRestore('USERSET', res);
                }
            })
            .catch((error) => {
                errorPage();
            });
    };

    const reStoreNodeId = (res: any) => {
        const nodes = res.nodeDataNodes;

        const actionList: number[] = [];
        nodes.forEach((item: any, idx: number) => {
            actionList.push(Number(item.id.replace('action', '')));
        });

        const maxNum = Math.max(...actionList);

        num = maxNum + 1;
    }

    const onNodeDragEnd = useCallback((event: any, node: any) => {

            if (nodeList.length > 0) {

                const newNodeList = JSON.parse(JSON.stringify(nodeList));

                newNodeList.forEach((item: any, idx: number) => {
                    if (item.id === node.id) {
                        item["position"] = node.position;
                        item["positionAbsolute"] = node.positionAbsolute;
                    }
                });
                changeNodeList(newNodeList);
            }
        },
        [nodeList]
    );

    const onNodeMultiSelection = useCallback((event: any, nodes: any) => {

        if (nodeList.length > 0) {
            const newNodeList = JSON.parse(JSON.stringify(nodeList));

            newNodeList.forEach((item: any, idx: number) => {
                nodes.forEach((node: any, i: number) => {
                    if (item.id === node.id) {
                        item['position'] = node.position;
                        item['positionAbsolute'] = node.positionAbsolute;
                    }
                });
            });
            changeNodeList(newNodeList);
        }

    }, [nodeList]);

    const onNodeClick = (event: any, node: any) => {
        const selectName = node.data.label.props.title;
        const noJson = ["START", "END", "CLEAN", "MOVE"];

        if (!noJson.includes(selectName)) {
            // nodeJson.forEach((item, idx) => {
            //     if (item.key === selectName) {
            //         console.log('value = ', item.value)
            //     }
            // })
        }
    };

    const callBackSidebarData = (id: string, data: any) => {
        const newNodes = JSON.parse(JSON.stringify(nodeList));

        newNodes.forEach((item: any, i: number) => {
            if (item.id === id) {
                item["condition"] = data;
            }
        });

        changeNodeList(newNodes);
    };

    const onNodeDoubleClick = (event: any, node: any) => {
        const noSettingSider = ["CHECK"];
        const selectName = node.data.label.props.action;

        if (noSettingSider.includes(selectName)) {
            const nodeId = node.id;
            nodeList.forEach((item: any, idx: number) => {
                if (item.id === nodeId) {
                    setSelectNodeInfo(item);
                }
            });
            setNodeSettingBox(true);
        }
    };

    const onEdgeDoubleClick = (event: any, edge: any) => {
        nodeList.forEach((item: any, idx: number) => {
            if (item.id === edge.source) {
                setSelectNodeInfo(item);
            }
        })

        setSelectEdgeInfo(edge);
        setUseCheckSelectNode(true);
        setNodeSettingBox(true)
    }


    const onTaskSave = () => {

        if (robotInfo) {
            setSelectRobotValid("");

            const {isValidation, msg} = NodeEdgeValidation(nodeList, edgeList);

            if (nodeList.length === 0) {
                return toast.error("노드정보가 없습니다.");
            }

            if (edgeList.length === 0) {
                return toast.error("연결정보가 없습니다.");
            }

            if (isValidation) {
                return toast.error(msg)
            }
            if (!isValidation) {
                setIsTaskSaveModalOpen(true);
            }
        } else {
            toast.error("로봇을 선택하세요.");
            setSelectRobotValid("error");
        }
    };

    const onUsersetSave = () => {
        if (selectedUserAuthSite.length === 1) {
            setSelectSiteValid('')

            const {isValidation, msg} = NodeEdgeValidation(nodeList, edgeList);

            if (nodeList.length === 0) {
                return toast.error("노드정보가 없습니다.");
            }

            if (edgeList.length === 0) {
                return toast.error("연결정보가 없습니다.");
            }

            if (isValidation) {
                return toast.error(msg)
            }


            setIsUsersetSaveModalOpen(true);
        } else {
            toast.error("사이트를 선택하세요.");
            setSelectSiteValid("error");
        }
    };

    useEffect(() => {
        if (robotInfo) {
            setSelectRobotValid("");
        }
    }, [robotInfo]);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleChange = (
        setValue: (value: string) => void,
        item?: { name: string; id: string }
    ) => {
        if (item) {
            changeSelectedUserAuthSites([item.id]);
            setValue(item.name);
            changeRobotCmdList(null);
            changeRobotInfo(null);
            setSelectRobot({});
            setInit(true);
            return;
        }
        setInit(false);
        changeSelectedUserAuthSites(siteList.map((item: any) => item.id));
        setValue("사이트 전체");
    };

    const onReset = () => {

        num = 1;

        setEdges([]);
        setNodes([]);
        setSelectRobot({});
        changeSelectedUserAuthSites([]);
        changeRobotCmdList(null);
        changeRobotInfo(null);
        setIsReset(true);
        setTaskDec("");
        setTabNumber("1");
        setTaskNm("");
        setUsersetNm("");
        setUsersetDec("");
        setTaskId(null);
        setUsersetId(null);

        changeEdgeType('default')

        setUseStartWf(true);

    }

    const onDeleteModalOpen = () => {
        if (taskId || usersetId) {
            setIsDeleteModalOpen(true);
        }
    };
    const onDelete = async () => {
        if (taskId != null) {
            await deleteTaskNode(taskId)
                .then((res) => {
                    if (res === "SUCCESS") {
                        setIsDeleteModalOpen(false);
                        toast.success("삭제하였습니다.");

                        setEdges([]);
                        setNodes([]);
                        // setIsReset(true);

                        setTaskDec("");
                        setTaskNm("");
                        setTaskId(null);

                        setUsersetNm("");
                        setUsersetDec("");
                        setUsersetId(null);

                        setTaskReRender(true);

                        num = 1;

                    } else {
                        toast.success("삭제에 실패하였습니다.");
                    }
                })
                .catch((error) => {
                    errorPage();
                });
        }

        if (usersetId != null) {
            await deleteUsersetNode(usersetId)
                .then((res) => {
                    if (res === "SUCCESS") {
                        setIsDeleteModalOpen(false);
                        toast.success("삭제하였습니다.");
                        // onReset();

                        setEdges([]);
                        setNodes([]);
                        // setIsReset(true);

                        setTaskDec("");
                        setTaskNm("");
                        setTaskId(null);

                        setUsersetNm("");
                        setUsersetDec("");
                        setUsersetId(null);

                        setPresetReRender(true);

                        num = 1;

                    } else {
                        toast.success("삭제에 실패하였습니다.");
                    }
                })
                .catch((error) => {
                    errorPage();
                });
        }
    };

    const deleteModelCancel = () => {
        setIsDeleteModalOpen(false);
    };

    const onTabNubmer = (idx: string) => {
        setTabNumber(idx);
    };

    const taskName = (e: any) => {
        setTaskNm(e.target.value);
    };
    const taskDescription = (e: any) => {
        setTaskDec(e.target.value);
    };

    const taskModelCancel = () => {
        setIsTaskSaveModalOpen(false);
    };

    const confirmModal = (e: any, type: string) => {
        if (taskId && type === "task") {
            let taskTitle = "생성";

            if (preTaskNm === taskNm) {
                taskTitle = "수정";
            } else {
                taskTitle = "생성";
            }
            let content = "";
            let titleText = "";

            if (taskTitle === "수정") {
                titleText = "TASK 수정";
                content = "정말로 수정하시겠습니까?";
            } else {
                titleText = "TASK 생성";
                content =
                    "TASK 이름을 변경하시면 신규 TASK로 생성됩니다. 그래도 저장 하시겠습니까?";
            }

            confirm({
                title: titleText,
                icon: (
                    <div style={{alignItems: "center", marginRight: 5}}>
                        <RiErrorWarningFill size={20}/>
                    </div>
                ),
                content: content,
                okText: "저장",
                cancelText: "취소",
                onOk: () => taskSave(taskTitle),
            });
        }
        if (!taskId && type === "task") {
            taskSave("생성");
        }

        /////////////////////////////////////////////////////////////////////

        if (usersetId && type === "userset") {
            let usersetTitle = "생성";

            if (preUsersetNm === usersetNm) {
                usersetTitle = "수정";
            } else {
                usersetTitle = "생성";
            }

            let content = "";
            let titleText = "";

            if (usersetTitle === "수정") {
                titleText = "USERSET 수정";
                content = "정말로 수정하시겠습니까?";
            } else {
                titleText = "USERSET 수정";
                content =
                    "USERSET 이름을 변경하시면 신규 USERSET으로 생성됩니다. 그래도 저장 하시겠습니까?";
            }

            confirm({
                title: titleText,
                icon: (
                    <div style={{alignItems: "center", marginRight: 5}}>
                        <RiErrorWarningFill size={20}/>
                    </div>
                ),
                content: content,
                okText: "저장",
                cancelText: "취소",
                onOk: () => usersetSave(usersetTitle),
            });
        }
        if (!usersetId && type === "userset") {
            usersetSave("생성");
        }
    };

    const taskSave = async (title: string) => {
        //nodes, edges, json, robotInfo, taskNm, taskDec

        let json = [];
        ///action index
        const {newNewNodes, newNewEdges} = actionIndexParser(nodeList, edgeList);

        json = workflowParser(newNewNodes, edgeList, robotInfo, selectedUserAuthSite[0], 'TASK');

        changeNodeList(newNewNodes);
        changeEdgeList(newNewEdges);

        // setResultJson(json);

        if (title === '수정') {
            const data = {
                taskId: Number(taskId),
                deviceId: robotInfo.deviceId,
                taskName: taskNm,
                taskMessage: json,
                nodeDataNodes: newNewNodes,
                nodeDataEdges: newNewEdges,
                description: taskDec
            }


            await putTaskNode(data)
                .then((res) => {
                    if (res === "SUCCESS") {
                        setIsTaskSaveModalOpen(false);
                        toast.success("저장하였습니다.");
                        //onReset();
                        setTaskReRender(true);
                    } else {
                        toast.success("저장에 실패하였습니다.");
                    }
                })
                .catch((error) => {
                    errorPage();
                });
        } else {
            const data = {
                deviceId: robotInfo.deviceId,
                taskName: taskNm,
                taskMessage: json,
                nodeDataNodes: newNewNodes,
                nodeDataEdges: newNewEdges,
                description: taskDec
            }
            await postTaskNode(data)
                .then((res) => {
                    if (res.result === "SUCCESS") {
                        setIsTaskSaveModalOpen(false);
                        toast.success("저장하였습니다.");

                        setPreTaskNm(taskNm);
                        setTaskId(res.taskId);
                        //onReset();
                    } else {
                        toast.success("저장에 실패하였습니다.");
                    }

                    setTaskReRender(true);
                })
                .catch((error) => {
                    errorPage();
                });
        }
    };

    const usersetName = (e: any) => {
        setUsersetNm(e.target.value);
    };
    const usersetDescription = (e: any) => {
        setUsersetDec(e.target.value);
    };

    const usersetModelCancel = () => {
        setIsUsersetSaveModalOpen(false);
    };

    const usersetSave = async (title: string) => {

        let json: any[] = [];

        const newNodeList = JSON.parse(JSON.stringify(nodeList));

        newNodeList.forEach((item: any, idx: number) => {
            if (Object.prototype.hasOwnProperty.call(item, 'variable')) {
                delete item.variable
            }
            if (Object.prototype.hasOwnProperty.call(item, 'selectElv')) {
                delete item.selectElv
            }
            if (Object.prototype.hasOwnProperty.call(item, 'targetFloor')) {
                delete item.targetFloor
            }
            if (Object.prototype.hasOwnProperty.call(item, 'startingFloor')) {
                delete item.startingFloor
            }
            if (Object.prototype.hasOwnProperty.call(item, 'condition')) {
                delete item.condition
            }
            if (Object.prototype.hasOwnProperty.call(item, 'outPoi')) {
                delete item.outPoi
            }
            if (Object.prototype.hasOwnProperty.call(item, 'insidePoi')) {
                delete item.insidePoi
            }
        });

        changeNodeList(newNodeList);

        const {newNewNodes, newNewEdges} = actionIndexParser(newNodeList, edgeList);

        json = workflowParser(newNodeList, edgeList, robotInfo, selectedUserAuthSite[0], 'USERSET');

        changeNodeList(newNewNodes);
        changeEdgeList(newNewEdges);

        // setResultJson(json);

        if (title === '수정') {
            const data = {
                usersetId: Number(usersetId),
                siteId: selectedUserAuthSite[0],
                usersetName: usersetNm,
                nodeDataNodes: newNewNodes,
                nodeDataEdges: newNewEdges,
                description: usersetDec,
                usersetMessage: json
            }


            await putUsersetNode(data)
                .then((res) => {
                    if (res === "SUCCESS") {
                        setIsUsersetSaveModalOpen(false);
                        toast.success("저장하였습니다.");
                        //onReset();
                        setPresetReRender(true);
                    } else {
                        toast.success("저장에 실패하였습니다.");
                    }
                })
                .catch((error) => {
                    errorPage();
                });
        } else {
            const data = {
                siteId: selectedUserAuthSite[0],
                usersetName: usersetNm,
                nodeDataNodes: newNewNodes,
                nodeDataEdges: newNewEdges,
                description: usersetDec,
                usersetMessage: json
            }

            await postUsersetNode(data)
                .then((res) => {
                    if (res.result === "SUCCESS") {
                        setIsUsersetSaveModalOpen(false);
                        toast.success("저장하였습니다.");
                        //onReset();
                        setPreUsersetNm(usersetNm);
                        setUsersetId(res.usersetId);
                        setPresetReRender(true);
                    } else {
                        toast.success("저장에 실패하였습니다.");
                    }
                })
                .catch((error) => {
                    errorPage();
                });
        }
    };


    const onRestore = useCallback((type: string, res: any) => {
            const restoreFlow = async (type: string, res: any) => {

                if (type === 'TASK') {

                    setPreTaskNm(res.taskName);

                    setTaskNm(res.taskName);
                    setTaskDec(res.description);

                    setUsersetNm("");
                    setUsersetDec("");
                    setUsersetId(null);
                }

                if (type === "USERSET") {
                    setPreUsersetNm(res.usersetName);

                    setUsersetNm(res.usersetName);
                    setUsersetDec(res.description);

                    setTaskNm("");
                    setTaskDec("");
                    setTaskId(null);
                }

                const nodes = res.nodeDataNodes;
                const edges = res.nodeDataEdges;

                nodes.forEach((item: any, i: number) => {
                    const nameStr = (item.data.label.props.fullName).split('|');

                    const data = {
                        label: <NodeLabelList key={item.id} title={item.data.label.props.title} id={item.id}
                                              fullName={item.data.label.props.fullName}
                                              type={item.data.label.props.type} action={nameStr[1]}
                                              item={item}/>
                    };


                    const style = NodeLabelStyle(nameStr[0], nameStr[1]);

                    // const newNode = {
                    //     id: item.id,
                    //     type: item.type,
                    //     position: item.position,
                    //     data: data,
                    //     //variable: value,
                    //     ...style
                    // };

                    const newNode: any = {};

                    Object.keys(item).forEach((key: any, index: number) => {
                        if (key === "data") {
                            newNode.data = data;
                        } else if (key === "style") {
                            newNode.style = style.style;
                        } else {
                            newNode[key] = item[key];
                        }
                    });

                    setNodes((nds) => nds.concat(newNode));
                });

                //setNodes(newNodeList || []);
                setEdges(edges || []);
            };

            restoreFlow(type, res);
        },
        [setNodes, setEdges, setViewport]
    );

    const startWf = () => {
        const {newNewNodes} = actionIndexParser(nodeList, edgeList);

        const json = workflowParser(
            newNewNodes,
            edgeList,
            robotInfo,
            selectedUserAuthSite[0],
            null
        );

        const data = {
            siteId: selectedUserAuthSite[0],
            activation: "START",
            actionList: json,
        };

        startWorkflowApi(data);
    };

    const startWorkflowApi = async (data: object) => {
        await startWorkflow(data)
            .then((res) => {
                if (res !== null && res !== undefined) {
                    if (res.result === "SUCCESS") {
                        toast.success("실행되었습니다.");
                        setCommandTaskId(res.taskId);
                        setUseStopWf(false);
                    }
                }
            })
            .catch((error) => {
                errorPage();
            });
    };

    const stopWf = () => {
        const data = {
            siteId: selectedUserAuthSite[0],
            activation: "STOP",
            taskId: commandTaskId,
        };

        stopWorkflowApi(data);
    };

    const stopWorkflowApi = async (data: object) => {
        await stopWorkflowApi(data)
            .then((res) => {
                if (res !== null && res !== undefined) {
                    if (res === "SUCCESS") {
                        toast.success("중지되었습니다.");
                    }
                }
            })
            .catch((error) => {
                errorPage();
            });
    };

    const resetReRender = (value: boolean, type: string) => {
        if (type === "task") {
            setTaskReRender(value);
        }
        if (type === "preset") {
            setPresetReRender(value);
        }
    };

    const onSelectLineType = (value: string, option: any) => {
        changeEdgeType(value);
    }

    const onMoveNodeEnd = (elements: any) => {
        setCopyNode(elements.nodes);
    }

    const copyNodeFunc = () => {

        copyNode.forEach((item: any) => {
            if (item.data.label.props.action !== 'START') {
                item.id = getId();
                setNodes((nds) => nds.concat(item));
            }

        });

    }

    useEffect(() => {
        const handleKeyDown = (event: any) => {
            if (event.ctrlKey && event.keyCode === 86) {
                console.log('CTRL V~~~~')
                if (useCopy) {
                    copyNodeFunc();
                    setUseCopy(false);
                }

            }
            if (event.ctrlKey && event.keyCode == 67) {
                console.log('CTRL C~~~~', useCopy)
                setUseCopy(true);
            }
        };
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };

    }, [useCopy]);


    const handleRadioChange = (e: RadioChangeEvent) => {
        console.log('radio = ', e.target.value);
        setRobotRadio(e.target.value);
        changeRobotType(e.target.value);
    }

    return (
        <>

            <div className={"wf-header"}>
                <div className={"wf-header-left"}>
                    <div className={"mr-8"}>
                        <SearchInput
                            siteList={siteList}
                            handleChange={handleChange}
                            isReset={isReset}
                            setInit={setInit}
                            setIsReset={setIsReset}
                        />
                    </div>
                    <div className={"mr-8"}>
                        <Radio.Group onChange={handleRadioChange} value={robotRadio}>
                            <Radio value={'single'}>단일 로봇</Radio>
                            <Radio value={'multiple'}>다중 로봇</Radio>
                        </Radio.Group>
                    </div>
                    { robotRadio === 'single' && <div>
                        <span style={{marginRight: "10px"}}>로봇 선택</span>
                        <Input
                            size={"small"}
                            style={{width: 150}}
                            readOnly
                            onClick={showModal}
                            value={selectRobot === null ? "" : selectRobot.name}
                            status={selectRobotValid}
                        />
                    </div> }
                </div>
                <div className={"wf-header-right"}>
                    <Button
                        className={"buttonSwitch"}
                        icon={<GrPowerReset/>}
                        onClick={onReset}
                        //type={"primary"}
                        style={{
                            alignItems: "center",
                            display: "flex",
                            marginLeft: "15px",
                        }}
                    >
                        리셋
                    </Button>

                    <Button
                        className={"buttonSwitch"}
                        icon={<FaSave/>}
                        style={{
                            marginLeft: "5px",
                            alignItems: "center",
                            display: "flex",
                        }}
                        type={"primary"}
                        onClick={() => onTaskSave()}
                    >
                        TASK 저장
                    </Button>
                    <Button
                        className={"buttonSwitch"}
                        icon={<FaSave/>}
                        style={{
                            marginLeft: "5px",
                            alignItems: "center",
                            display: "flex",
                        }}
                        type={"primary"}
                        onClick={() => onUsersetSave()}
                    >
                        USERSET 저장
                    </Button>

                    <Button
                        className={"buttonSwitch"}
                        icon={<MdDeleteForever size={20}/>}
                        style={{
                            marginLeft: "5px",
                            alignItems: "center",
                            display: "flex",
                        }}
                        //type={"primary"}
                        onClick={onDeleteModalOpen}
                    >
                        삭제
                    </Button>
                </div>

            </div>

            <div className={"wf-body-left"}>
                <div className={"wf-body-left-child"}>
                    <Tabs
                        defaultActiveKey="2"
                        onChange={(idx) => onTabNubmer(idx)}
                        size={"middle"}
                        items={[
                            // {
                            //   key: "1",
                            //   label: "BUILDER",
                            //   children: <ItemBuilder onDrop={onDrop} isReset={isReset} />,
                            // },
                            {
                                key: "3",
                                label: "USERSET",
                                children: (
                                    <ItemPreset
                                        onDrop={onDrop}
                                        isReset={isReset}
                                        reRender={presetReRender}
                                        resetReRender={resetReRender}
                                    />
                                ),
                            },
                            {
                                key: "2",
                                label: "TASK",
                                children: (
                                    <ItemTask
                                        onDrop={onDrop}
                                        isReset={isReset}
                                        reRender={taskReRender}
                                        resetReRender={resetReRender}
                                    />
                                ),
                            },
                        ]}
                    />
                </div>
            </div>

            <div
                className="reactflow-wrapper"
                ref={reactFlowWrapper}
            >
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    onInit={setReactFlowInstance}
                    className="intersection-flow"
                    onDragOver={onDragOver}
                    onNodeDragStop={(event, node) => onNodeDragEnd(event, node)}
                    onSelectionDragStop={(e, nodes) => onNodeMultiSelection(e, nodes)}
                    onDrop={!mobileCheck ? (event) => onDrop(event) : undefined}
                    onNodeDoubleClick={(event, node) => onNodeDoubleClick(event, node)}
                    //.onNodeClick={(event, node) => onNodeClick(event, node)}

                    onSelectionChange={(elements) => onMoveNodeEnd(elements)}

                    deleteKeyCode={["Backspace", "Delete"]}
                    fitView
                >
                    <Controls position={"top-right"} style={{top: '150px'}}/>

                    <MiniMap style={{marginBottom: "5%"}}/>


                    <div
                        style={{
                            bottom: 0,
                            border: "1px solid #fff",
                            position: "absolute",
                            background: "#fff",
                            width: "100%",
                            height: "49px",
                            zIndex: 100,
                        }}
                    >
                        <div style={styles.bottomStyle}>
                            <Button
                                icon={<FaPlay/>}
                                style={{
                                    marginLeft: "5px",
                                    alignItems: "center",
                                    display: "flex",
                                }}
                                onClick={startWf}
                                disabled={useStartWf}
                            >
                                실행
                            </Button>
                            <Button
                                icon={<FaStop/>}
                                style={{
                                    marginLeft: "10px",
                                    alignItems: "center",
                                    display: "flex",
                                }}
                                onClick={stopWf}
                                disabled={useStopWf}
                            >
                                중지
                            </Button>
                        </div>
                    </div>
                </ReactFlow>
            </div>

            <div className={"wf-body-right"}>
                <div className={"wf-body-right-top"}>
                    <span className={"wf-body-right-top-title"}>연결선 형태</span>
                    <Select
                        className={"lineTypeS"}
                        size={"middle"}
                        suffixIcon={null}
                        style={{width: 100, height: 32}}
                        onChange={(value, option) => onSelectLineType(value, option)}
                        options={lineTypeOptions}
                        value={edgeType}
                    />
                </div>
                <div className={"wf-body-right-bottom"}>
                    <ItemBuilder onDrop={onDrop} isReset={isReset}/>
                </div>
            </div>

            <SettingSidebar onNodeDoubleClick={setNodeSettingBox} useCheckSelectNode={useCheckSelectNode}
                            open={nodeSettingBox} nodeData={selectNodeInfo} edgeData={selectEdgeInfo}
                            callBackSidebarData={callBackSidebarData}/>

            {/*<Drawer*/}
            {/*    placement={'top'}*/}
            {/*    open={true}*/}
            {/*    height={200}*/}

            {/*/>*/}

            <SettingWorkModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                modalTitle={"로봇선택"}
                useWork={false}
                setSelectRobot={setSelectRobot}
                workflowFlag={true}
                isReset={isReset}
                setIsReset={setIsReset}
            />

            <Modal
                open={isTaskSaveModalOpen}
                //onOk={taskSave}
                onOk={(e) => confirmModal(e, "task")}
                okText={"확인"}
                onCancel={taskModelCancel}
                cancelText={"취소"}
                width={400}
            >
                <div style={styles.title}>
                    <RiListSettingsLine size={25}/>
                    <span style={styles.span}>{"TASK 저장"}</span>
                </div>

                <div style={styles.table}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <span style={{width: "30%"}}>{"TASK 이름"}</span>
                        <Input onChange={(e) => taskName(e)} value={taskNm}/>
                    </div>
                    <div style={{display: "flex", marginTop: "20px"}}>
                        <span style={{width: "30%"}}>{"TASK 설명"}</span>
                        <TextArea
                            rows={3}
                            onChange={(e) => taskDescription(e)}
                            value={taskDec}
                        />
                    </div>
                </div>
            </Modal>
            <Modal
                open={isUsersetSaveModalOpen}
                onOk={(e) => confirmModal(e, "userset")}
                okText={"확인"}
                onCancel={usersetModelCancel}
                cancelText={"취소"}
                width={400}
            >
                <div style={styles.title}>
                    <RiListSettingsLine size={25}/>
                    <span style={styles.span}>{"USERSET 저장"}</span>
                </div>

                <div style={styles.table}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <span style={{width: "40%"}}>{"USERSET 이름"}</span>
                        <Input onChange={(e) => usersetName(e)} value={usersetNm}/>
                    </div>
                    <div style={{display: "flex", marginTop: "20px"}}>
                        <span style={{width: "40%"}}>{"USERSET 설명"}</span>
                        <TextArea
                            rows={3}
                            onChange={(e) => usersetDescription(e)}
                            value={usersetDec}
                        />
                    </div>
                </div>
            </Modal>

            <Modal
                open={isDeleteModalOpen}
                onOk={onDelete}
                okText={"확인"}
                onCancel={deleteModelCancel}
                cancelText={"취소"}
                width={300}
            >
                <div style={styles.title}>
                    <RiListSettingsLine size={25}/>
                    <span style={styles.span}>{"삭제"}</span>
                </div>

                <div style={{height: "50px", marginTop: "20px"}}>
                    삭제 하시겠습니까?
                </div>
            </Modal>
        </>
    );
};

const styles = {
    table: {
        marginTop: "20px",
        // height: '470px'
        //width: '50%'
    },
    title: {
        display: "flex",
        borderBottom: "1px solid #eee",
        paddingBottom: "15px",
    },

    span: {
        fontSize: "18px",
        marginLeft: "10px",
        fontWeight: "bold",
    },
    parent: {
        padding: 7,
        fontSize: 14,
        background: "rgb(245, 247, 249)",
        cursor: "move",
        marginTop: 10,
        borderRadius: 4,
        display: "flex",
        alignItems: "center",
        border: "1px solid #ddd",
    },
    splitIcon: {
        transform: "rotate(90deg)",
    },
    joinIcon: {
        transform: "rotate(-90deg)",
    },
    bottomStyle: {
        display: "flex",
        justifyContent: "center",
        marginTop: "5px",
    },

};

export default Flow;
