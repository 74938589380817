import { useEffect, useState } from "react";
import useUserStore from "../shared/store/userStore";

import { t } from "i18next";

import { getAllSiteList } from "../api/firestore/site";
import { useAuth, useUserData } from "../shared/hooks";
import { FirestoreCollenctions, Permission } from "../shared/types";
import { db } from "../shared/lib";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { SearchInput } from "../shared/ui";
import { ModalPortal, Modal, InquiryModal } from "../shared/ui/Modal";

import SettingCalendar from "../features/schedule/ui/SettingCalendar";
import SettingSchedule from "../features/schedule/ui/SettingSchedule";

const SchedulePage = () => {
  const { userStatus, changeSelectedUserAuthSites } = useUserStore();

  const { user } = useUserData();
  const { hasAuthSite, hasAuthRobot, login } = useAuth();
  const [siteList, setSiteList] = useState<{ name: string; id: string }[]>([]);
  const [init, setInit] = useState<boolean>();

  useEffect(() => {
    if (user?.permission === Permission.SUPER_ADMIN) {
      getAllSiteList().then((data) => {
        changeSelectedUserAuthSites(data.map((site) => site.id as string));
        setSiteList(
          data.map((doc) => ({ id: doc.id as string, name: doc.name }))
        );
      });

      return;
    }

    const fetchData = async (inputEmail: string) => {
      try {
        const sitesRef = collection(db, FirestoreCollenctions.USERS);
        const q = query(sitesRef, where("email", "==", inputEmail));

        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map((item) => item.data());
      } catch (error) {
        console.error(error);
      }
    };

    if (
      user &&
      (user.permission === Permission.ENGINEER ||
        userStatus === Permission.USER)
    ) {
      const list: { name: string; id: string }[] = [];
      try {
        fetchData(user.email).then(async (res) => {
          if (res && res[0].auth_sites) {
            for (let i = 0; i < res[0].auth_sites.length; i++) {
              const docRef = doc(
                db,
                FirestoreCollenctions.SITES,
                res[0].auth_sites[i]
              );

              const sites = await getDoc(docRef);
              list.push({ name: sites.data()?.name, id: sites.id });
            }
          }
          changeSelectedUserAuthSites(
            list.map((item: { name: string; id: string }) => item.id)
          );
          setSiteList(list);
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [user, userStatus]);

  const handleChange = (
    setValue: (name: string) => void,
    item?: { name: string; id: string }
  ) => {
    if (item) {
      changeSelectedUserAuthSites([item.id]);
      setValue(item.name);
      setInit(true);
      return;
    }
    setInit(false);
  };

  return (
    <div className="w-full pl-[75px] flex flex-col bg-neutral-50 h-screen pb-2">
      <div className="fixed top-0 z-20 w-full pt-10 pb-3 pl-12 text-2xl text-gray-700 bg-neutral-50">
        {t("schedule")}
      </div>

      <section
        className="pt-[90px] flex items-center justify-between pb-3 pl-12"
        data-name="bg"
      >
        <SearchInput
          siteList={siteList}
          handleChange={handleChange}
          setInit={setInit}
        />
      </section>

      <section className="px-12 pt-3 pb-10">
        <div style={{ display: "flex" }}>
          <SettingCalendar />
          <SettingSchedule siteList={siteList} />
        </div>
      </section>

      <ModalPortal>
        <Modal />
      </ModalPortal>
      {(hasAuthSite === "false" || hasAuthRobot === "false") &&
        !(login === "false") && <InquiryModal />}
    </div>
  );
};

export default SchedulePage;
