import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Trans, useTranslation } from "react-i18next";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
} from "chart.js";

import { getSuccessRateByPeriod } from "../api";
import { LOCALE } from "../../../shared/constants";
import { numberFormat } from "../../../shared/util/format";
import useUserStore from "../../../shared/store/userStore";

import { useBlurStyle, usePeriod } from "../../../shared/hooks";
import { Pagination, Skeleton } from "../../../shared/ui";
import { PeriodWithSiteId } from "../../../shared/types/dashBoard";

import { excelDataFormat, excelDownload } from "../../../shared/util/excelUtil";

import { Tooltip } from "react-tooltip";

import { useDashboardStore } from "../model";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Legend);

export default function SuccessRateBarChart() {
  const { t } = useTranslation();
  const blurStyle = useBlurStyle();
  const [startDate, endDate] = usePeriod();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [dataResult, setDataResult] = useState<number[]>([]);
  const [dataSuccess, setDataSuccess] = useState<any>([]);
  const [dataError, setDataError] = useState<any>([]);
  const [data1, setData1] = useState<any>([]);
  const [data2, setData2] = useState<any>([]);
  const [avg, setAvg] = useState(0);
  const [dates, setDates] = useState<string[]>([]);

  const { selectedUserAuthSite } = useUserStore();
  const { currentDate, oneYearAgoDate } = useDashboardStore();
  const skeletonDummyData = [50, 62, 78, 98, 50, 105, 95, 57, 113, 70, 19, 56];
  const [loading, setLoading] = useState(true);
  const [originData, setOriginData] = useState<any>([]);

  useEffect(() => {
    if (selectedUserAuthSite.length === 0) {
      return;
    }

    const data: PeriodWithSiteId = {
      startDate: oneYearAgoDate,
      endDate: currentDate,
      siteIds: selectedUserAuthSite,
    };

    getSuccessRateByPeriod(data) //
      .then((res) => {
        const data = [...res];
        const reverseData = [...res.reverse()];
        const tempSuccess = data.map((item: any) => item.data.SUCCESS);
        const tempError = data.map((item: any) => item.data.ERROR);
        const tempData1 = reverseData.slice(0, 6);
        const tempData2 = reverseData.slice(6, 12);
        const tempDates = data.map((item: any) => item.date);
        let tempSuccessCnt = 0;
        let tempErrorCnt = 0;
        for (const item of tempSuccess) {
          tempSuccessCnt += item;
        }
        for (const item of tempError) {
          tempErrorCnt += item;
        }
        setDataResult(data.map((item: any) => item.data.RESULT));
        setDataSuccess(tempSuccess);
        setDataError(tempError);
        setData1(tempData1);
        setData2(tempData2);
        setDates(tempDates);

        setOriginData(data);

        setAvg((tempSuccessCnt / (tempSuccessCnt + tempErrorCnt)) * 100);
        setTotalPages(2);
        setLoading(false);
      });
  }, [selectedUserAuthSite, currentDate, oneYearAgoDate, startDate, endDate]);

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 500,
    },
    responsiveAnimationDuration: 0,
    plugins: {
      legend: {
        display: false,
        position: "bottom" as const,
      },

      datalabels: {
        formatter: (value: any, context: any) => {
          if (context.dataset.label === "실패") {
            return `${numberFormat(dataResult[context.dataIndex], LOCALE)}%`;
          } else {
            return "";
          }
        },
        display: true,
        offset: 1,
        align: "bottom",
        anchor: "start",
        color: "#fff",
      },
    },
    layout: {
      padding: 10,
    },
    scales: {
      x: {
        grid: { display: false },
        stacked: true,
      },
      y: {
        display: false,
        stacked: true,
        ticks: {
          // stepSize: 5,
        },
        grid: { display: false },
      },
    },
  };

  const labels = dates.map((item: string) => item.slice(5, 7) + "월");
  const data = {
    labels,
    datasets: [
      {
        label: "성공",
        data: dataResult.map((item: number) => Math.round(item)),
        backgroundColor: "#85B093",
        stack: "Stack 1",
      },
      {
        label: "실패",
        data: dataResult.map((item: number) =>
          item === 0 ? 0 : 100 - Math.round(item)
        ),
        backgroundColor: "#326D6C",
        stack: "Stack 1",
      },
    ],
  };

  const [isShowTooltip, setIsShowTooltip] = useState(false);

  const onExcelDown = () => {
    const parseData = excelDataFormat(originData);
    excelDownload(parseData, "test.xlsx");
  };

  return (
    <div className="h-[100%] flex flex-col p-2 px-5 items-center justify-between">
      <div className="w-full">
        <Tooltip
          anchorSelect={"#successRateBarChartTooltip"}
          place={"right"}
          content="지난 1년간 성공적으로 완료된 서비스 건수를 전체 서비스 건수로 나눈
            비율을 나타냅니다."
        />
        <div className="flex self-start pt-2">
          <div className="p-2 mr-5">
            <div
              id={"successRateBarChartTooltip"}
              className="text-[#08B295] text-sm cursor-default"
            >
              <Trans i18nKey="task_success_rate" />
            </div>

            <div className={`text-lg font-bold text-gray-800 ${blurStyle}`}>
              {t("average")} {numberFormat(avg, LOCALE)}%
            </div>
          </div>
        </div>

        <div
          className={`w-[97%] h-[75%] pl-5 pt-0 pb-2 mt-0 ${blurStyle}`}
          style={{ display: "flex" }}
        >
          <div className="w-[90%] h-[300px] self-center p-1 mt-3 mr-5 justify-center items-end">
            {loading ? (
              skeletonDummyData.map((item, index) => (
                <div key={index} className="mx-1">
                  <Skeleton width={22} height={item} borderRadius={2} />
                </div>
              ))
            ) : (
              <Bar options={options} data={data} />
            )}
          </div>

          <div className={`w-[70%] ${blurStyle}`}>
            <div className="flex text-[0.8rem] justify-center text-center w-[100%] mt-3">
              {/* <div className="py-1 font-bold rounded-l-lg w-[40%] bg-neutral-100">
          <Trans i18nKey="robot_name" />
        </div> */}
              {/* <div className="py-1 font-bold w-[30%] bg-neutral-100">
          <Trans i18nKey="command" />
        </div> */}
              <div className="py-1 font-bold w-[30%] rounded-l-lg bg-neutral-100">
                {t("date")}
              </div>
              <div className="py-1 font-bold w-[20%] bg-neutral-100">
                <Trans i18nKey="success" />
                (<Trans i18nKey="case" />)
              </div>
              <div className="py-1 font-bold w-[20%] bg-neutral-100">
                <Trans i18nKey="failure" />
                (<Trans i18nKey="case" />)
              </div>
              <div className="py-1 font-bold rounded-r-lg w-[30%] bg-neutral-100">
                <Trans i18nKey="work_success_rate" />
                <span>(%)</span>
              </div>
            </div>

            {loading ? (
              <Skeleton className="w-full h-[190px] mt-1 rounded-md" />
            ) : (
              <>
                {currentPage === 1 &&
                  data1.map((item: any, index: number) => (
                    <div
                      key={index}
                      className="w-[100%] flex justify-center text-[0.7rem] text-center border-b"
                    >
                      <div className="py-2 w-[30%]">{item.date}</div>
                      <div className="py-2 w-[20%]">
                        {numberFormat(item.data.SUCCESS, LOCALE)}
                      </div>
                      <div className="py-2 w-[20%]">
                        {numberFormat(item.data.ERROR, LOCALE)}
                      </div>
                      <div className="py-2 w-[30%]">
                        {numberFormat(item.data.RESULT, LOCALE)}
                      </div>
                    </div>
                  ))}
                {currentPage === 2 &&
                  data2.map((item: any, index: number) => (
                    <div
                      key={index}
                      className="w-[100%] flex justify-center text-[0.7rem] text-center border-b"
                    >
                      <div className="py-2 w-[30%]">{item.date}</div>
                      <div className="py-2 w-[20%]">
                        {numberFormat(item.data.SUCCESS, LOCALE)}
                      </div>
                      <div className="py-2 w-[20%]">
                        {numberFormat(item.data.ERROR, LOCALE)}
                      </div>
                      <div className="py-2 w-[30%]">
                        {numberFormat(item.data.RESULT, LOCALE)}
                      </div>
                    </div>
                  ))}
                <div className={blurStyle}>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onClickPrev={() => setCurrentPage((prev) => prev - 1)}
                    onClickNext={() => setCurrentPage((prev) => prev + 1)}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
