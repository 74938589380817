import { useEffect, useState } from "react";
import useCardstore from "../../../shared/store/cardStore";
import { DataType, FirestoreCollenctions } from "../../../shared/types";
import CountUp from "react-countup";
import { Trans } from "react-i18next";
import useUserStore from "../../../shared/store/userStore";
import { doc, getDoc } from "firebase/firestore";
import { Permission } from "../../../shared/types/user";

import { SiteType } from "../../../shared/types/site";
import { getAllSiteListOnSnapshot } from "../../../api/firestore/site";
import { getUserById } from "../../../api/firestore/user";
import { useUserData } from "../../../shared/hooks";
import { realTimePerMinSocket } from "../../../shared/api/socketApi";
import { db } from "../../../shared/lib";
import { Skeleton } from "../../../shared/ui";
import BarChart from "./BarChart";
import { getCompanyList } from "../api";

export default function RealtimeCardMenu() {
  const [data, setData] = useState<DataType[]>([]);
  const [list, setList] = useState<any>(null);
  const [chartData, setChartData] = useState<any>([]);
  const [count, setCount] = useState(0);
  const [maxValue, setMaxValue] = useState(-1);

  const { userStatus } = useUserStore();
  const { user } = useUserData();
  const { changeCardCenter, changeCardInfo, updateIsShowCardInfoWindow } =
    useCardstore();

  const [totalSiteList, setTotalSiteList] = useState<SiteType[]>([]);
  const [authSiteList, setAuthSiteList] = useState<string[]>([]);

  // 카드뷰에 들어갈 회사 목록 받아오기
  useEffect(() => {
    getCompanyList().then(setData);
  }, []);

  // 전체 사이트 가져옴 - firebase
  useEffect(() => {
    getAllSiteListOnSnapshot(setTotalSiteList);
  }, []);

  // 사용자 권한 있는 사이트, 도로명주소 가져옴 - firebase
  useEffect(() => {
    if (!user) {
      return;
    }

    if (user.permission === Permission.SUPER_ADMIN) {
      setAuthSiteList(totalSiteList.map((site) => site.name));
    }

    if (user.permission === Permission.ENGINEER || Permission.USER) {
      const temp: string[] = [];
      getUserById(user.id as string).then(async (res) => {
        if (!res) return;

        for (let i = 0; i < res.auth_sites.length; i++) {
          // : 공통함수로 빼기
          const docRef = doc(
            db,
            FirestoreCollenctions.SITES,
            res.auth_sites[i] as any
          );
          const result: any = await getDoc(docRef);
          temp.push(result.data().name);
        }
        setAuthSiteList(temp);
      });
    }
  }, [user]);

  // 차트에 들어갈 data 받아오기
  useEffect(() => {
    realTimePerMinSocket.on("connect", () => {});
    realTimePerMinSocket.on("realtimeDatasPerMin", (data) => {
      setChartData(data.realtimeDatas);
    });
  }, []);

  //  totalSiteList, authSiteList 사용해서 카드뷰에 들어갈 list 생성
  useEffect(() => {
    if (!data.length || !chartData.length || !totalSiteList.length) {
      return;
    }

    const tempList: any = [];

    for (let i = 0; i < data.length; i++) {
      const tempChartData = chartData.find(
        (item: any) => item.branchId === data[i].branchId
      );

      const tempSite = totalSiteList.find(
        (item) => item.road_address === data[i].roadAddress
      );

      if (tempChartData && tempSite) {
        tempList.push({
          // order: 권한 있는 사이트는 0, 아니면 1. 카드뷰 우선 순위 지정
          order: authSiteList.includes(tempSite.name)
            ? Permission.SUPER_ADMIN
            : Permission.ENGINEER,
          siteName: getSiteName(tempSite),
          roadAddress: getRoadAddress(tempSite, data[i]),
          latitude: data[i].latitude,
          longitude: data[i].longitude,
          robotCnt: data[i].robotCnt,
          datas: tempChartData.datas,
          totalCnt: tempChartData.dataSum,
        });
      }

      setList(tempList);
    }
  }, [data, chartData, totalSiteList, authSiteList, userStatus]);

  const getSiteName = (site: SiteType) => {
    if (user?.permission === Permission.SUPER_ADMIN) {
      return site.name;
    }

    if (authSiteList.includes(site.name)) return site.name;

    return site.alias;
  };

  const getRoadAddress = (site: SiteType, data: DataType) => {
    if (user?.permission === Permission.SUPER_ADMIN) {
      return data.roadAddress;
    }

    if (authSiteList.includes(site.name)) return data.roadAddress;

    return data.roadAddressAlias;
  };

  // 데이터 최댓값 찾기 - bar chart의 max값으로 들어감
  useEffect(() => {
    // 데이터 최댓값 찾는 함수
    const findMaxValue = (inputData: any) => {
      const temp: any = [];
      for (let i = 0; i < inputData.length; i++) {
        temp.push(Math.max(...inputData[i].datas));
      }
      const tempMax = temp.sort((a: number, b: number) => b - a)[0];
      return tempMax;
    };
    if (list && list.length !== 0) {
      setMaxValue(findMaxValue(list));
    }
  }, [list]);

  // 로봇 총 개수 구하기 (가장 위 멘트에 넣을 용도)
  useEffect(() => {
    let tempCount = 0;
    if (data.length !== 0) {
      for (let i = 0; i < data.length; i++) {
        tempCount += data[i].robotCnt;
      }
      setCount(tempCount);
    }
  }, [data]);

  const handleClick: any = (event: MouseEvent) => {
    const target = event.currentTarget as HTMLElement;
    const data = target.dataset.info ? JSON.parse(target.dataset.info) : null;
    if (data.latitude && data.longitude) {
      changeCardCenter({
        lat: Number(data.latitude),
        lng: Number(data.longitude),
      });
    }
    localStorage.setItem("lat", String(data.latitude));
    localStorage.setItem("lng", String(data.longitude));
    changeCardInfo({
      siteName: data.siteName,
      roadAddress: data.roadAddress,
    });
    updateIsShowCardInfoWindow(true);
  };

  return (
    <div className="w-[100%] h-[100vh] pt-5 px-2">
      <div className="ml-1">
        <div className="text-2xl text-gray-800">
          <Trans i18nKey="real_time_solink_usage_status" />
        </div>
        <div className="pt-1 pb-3 text-xs text-gray-800">
          <Trans
            i18nKey="robotCount_robots_are_being_used_in_siteCount_across_the_country"
            values={{ siteCount: totalSiteList.length, robotCount: count }}
          />
        </div>
      </div>
      <div
        id={list ? "scroll" : ""}
        className={
          list
            ? "mb-3 pt-3 pb-11 h-[93%] overflow-y-scroll"
            : "mb-3 pt-3 pb-11 h-[93%]"
        }
      >
        {!list
          ? Array.from({ length: 10 }).map((_, index) => (
              <div
                key={index}
                className="p-5 bg-white shadow rounded-xl hover:bg-[#08B29510] mb-3 mx-2 hover:cursor-pointer"
              >
                <div className="text-sm font-bold flex    flex-col justify-between w-[100%]">
                  <div className="w-[100%] pb-1">
                    <Skeleton width={160} height={40} borderRadius={12} />
                    <Skeleton
                      width={243}
                      height={80}
                      borderRadius={12}
                      className="mt-2"
                    />
                  </div>
                  <div className="flex justify-between mt-2">
                    <Skeleton width={175} height={40} borderRadius={12} />
                    <Skeleton width={50} height={40} borderRadius={12} />
                  </div>
                </div>
              </div>
            ))
          : list
              .sort((a: any, b: any) => a.order - b.order)
              .map((item: any, index: number) => {
                if (item.robotCnt) {
                  return (
                    <div
                      key={index}
                      onClick={handleClick}
                      data-info={JSON.stringify(item)}
                      className="p-5 bg-white shadow rounded-xl hover:bg-[#08B29510] mb-3 mx-2 hover:cursor-pointer"
                    >
                      <div className="w-[100%] pb-1">
                        <div className="text-xs text-gray-600">
                          <Trans i18nKey="last_30_minutes_count" />
                        </div>
                        <div className="pb-3 text-2xl">
                          <CountUp
                            end={item.totalCnt}
                            start={item.totalCnt / 2}
                            duration={2}
                          />
                        </div>
                        <BarChart
                          datas={item.datas}
                          index={index}
                          maxValue={maxValue}
                        />
                      </div>
                      <div className="mt-2">
                        <div className="flex justify-between mt-2">
                          <div className="text-sm font-bold">
                            {item.siteName}
                          </div>
                          <div className="text-sm font-bold min-w-fit">
                            <Trans i18nKey="robots_introduced_count" />
                          </div>
                        </div>
                        <div className="flex items-center justify-between">
                          <div className="text-xs text-gray-500">
                            {item.roadAddress}
                          </div>
                          <div className="text-sm text-[#08B295]">
                            {item.robotCnt}대
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
      </div>
    </div>
  );
}
