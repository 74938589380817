import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RobotPage from "../pages/RobotPage";
import StreamviewPage from "../pages/Streamview";
import { useEffect } from "react";
import { User, onAuthStateChanged } from "firebase/auth";

import SignUp from "../pages/SignUp";
import Verification from "../pages/Verification";
import UserPage from "../pages/UserPage";

import { doc, getDoc } from "firebase/firestore";
import DashboardPage from "../pages/DashboardPage";
import TotalPage from "../pages/TotalPage";

import SchedulePage from "../pages/SchedulePage";
import WorkflowPage from "../pages/WorkflowPage";
import BookmarkPage from "../pages/BookmarkPage";

import NotFound from "../pages/NotFound";
import HistoryPage from "../pages/HistoryPage";

import useUserStore from "../shared/store/userStore";
import useRobotstore from "../shared/store/robotStore";
import { auth, db } from "../shared/lib";
import { FirestoreCollenctions, Pathname, Permission } from "../shared/types";
import { LayoutWrapper } from "../widgets/Layout/ui";
import { useDashboardStore } from "../features/chart/model";
import { useLoginStore } from "../features/login/model";
import { getCookie, setCookie } from "../shared/util/cookie";

declare global {
  interface Window {
    naver: any;
  }
}

function App() {
  const { pathname } = useLocation();
  const {
    isLoggedIn,
    updateIsLoggedIn,
    changeUserInfo,
    updateIsShowLoginModal,
  } = useLoginStore();

  const {
    userStatus,
    googleUserInfo,
    userInfo,
    updateIsShowUserIcon,
    changeUserStatus,
    updateGoogleUserInfo,
    updateUserInfo,
  } = useUserStore();
  const { changeSelectedRobot } = useRobotstore();
  const { changeCurrentDate, changeOneMonthAgoDate, changeOneYearAgoDate } =
    useDashboardStore();
  const navigate = useNavigate();

  const getUserStatus = async (user: any) => {
    const docRef = doc(db, FirestoreCollenctions.USERS, user.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      changeUserStatus(docSnap.data().permission);
      if (docSnap.data().permission === Permission.SUPER_ADMIN) {
        localStorage.setItem(
          "hasAuthSite",
          docSnap.data().auth_sites.length > 0 ? "true" : "true"
        );
        localStorage.setItem(
          "hasAuthRobot",
          docSnap.data().auth_robots.length > 0 ? "true" : "true"
        );
        return;
      }
      localStorage.setItem(
        "hasAuthSite",
        docSnap.data().auth_sites.length > 0 ? "true" : "false"
      );
      localStorage.setItem(
        "hasAuthRobot",
        docSnap.data().auth_robots.length > 0 ? "true" : "false"
      );
    }
  };

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const oneYearAgoMonth = month === 12 ? 11 : month + 1;
    const oneMonthAgoMonth = month === 1 ? 12 : month - 1;
    const day = today.getDate();

    changeCurrentDate(
      `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(
        2,
        "0"
      )}`
    );
    changeOneMonthAgoDate(
      `${year}-${String(oneMonthAgoMonth).padStart(2, "0")}-01`
    );
    changeOneYearAgoDate(
      `${year - 1}-${String(oneYearAgoMonth).padStart(2, "0")}-01`
    );
  }, []);

  useEffect(() => {
    const accessToken = getCookie("accessToken");
    if (accessToken && pathname === Pathname.SIGNUP) {
      return navigate(Pathname.MAIN);
    }

    const setIdToken = async (user: User) => {
      const idToken = await user.getIdToken(true);
      setCookie("idToken", idToken);
    };

    onAuthStateChanged(auth, (user) => {
      if (user) {
        updateGoogleUserInfo(user);
        setIdToken(user);

        // 제거 예정
        if (user.emailVerified) {
          localStorage.setItem("login", "true");
          localStorage.setItem("verified", "true");
          updateIsLoggedIn(true);
          changeUserInfo(user);
          getUserStatus(user);
        } else if (!user.emailVerified) {
          localStorage.setItem("login", "true");
          localStorage.setItem("verified", "false");
        }
      } else {
        changeUserStatus(Permission.USER);
        localStorage.setItem("login", "false");
        localStorage.setItem("verified", "false");
      }
    });
  }, []);

  useEffect(() => {
    updateIsShowUserIcon(false);
    changeSelectedRobot(null);
  }, [pathname]);

  useEffect(() => {
    if (localStorage.verified === "false") {
      if (
        pathname === "/dashboard" ||
        pathname === "/robot" ||
        pathname === "/schedule"
      ) {
        updateIsShowLoginModal(true);
      } else {
        updateIsShowLoginModal(false);
      }
    } else {
      updateIsShowLoginModal(false);
    }
  }, [isLoggedIn, pathname]);

  useEffect(() => {
    if (window.location.href.includes("#login")) updateIsShowLoginModal(true);
  }, [window.location.href]);

  useEffect(() => {
    // TODO: 스케줄, 워크플로우, 즐겨찾기, 알림이력 개발 전까지
    if (process.env.REACT_APP_ENV !== "production") return;

    if (
      pathname === "/schedule" ||
      pathname === "/workflow" ||
      pathname === "/bookmark"
    )
      navigate("/");
  }, [pathname]);

  return (
    <>
      {Object.values(Pathname).includes(pathname as Pathname) && (
        <LayoutWrapper />
      )}
      <Routes>
        <Route path={Pathname.MAIN} element={<TotalPage />} />
        <Route path={Pathname.STREAMVIEW} element={<StreamviewPage />} />
        <Route path={Pathname.DASHBOARD} element={<DashboardPage />} />
        <Route path={Pathname.ROBOT} element={<RobotPage />} />
        <Route path={Pathname.SIGNUP} element={<SignUp />} />
        <Route path={Pathname.VERIFICATION} element={<Verification />} />
        <Route path={Pathname.USERPAGE} element={<UserPage />} />
        <Route path={Pathname.HISTORY} element={<HistoryPage />} />
        <Route path={Pathname.SCHEDULE} element={<SchedulePage />} />
        <Route path={Pathname.NOTFOUND} element={<NotFound />} />
        <Route path={Pathname.WORKFLOW} element={<WorkflowPage />} />
        <Route path={Pathname.BOOKMARK} element={<BookmarkPage />} />
      </Routes>

      <ToastContainer
        position="top-center"
        transition={Slide}
        autoClose={2000}
        pauseOnFocusLoss
        hideProgressBar
        draggable
        theme="colored"
      />
    </>
  );
}

export default App;
