import { Dispatch, SetStateAction } from "react";
import {
  DocumentReference,
  collection,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";

import { getDocData, getListByReference } from "./common";
import {
  CommonCommandType,
  FirestoreCollenctions,
  RobotType,
} from "../../shared/types";
import { db } from "../../shared/lib";

export async function getReferenceList<T>(path: DocumentReference[]) {
  try {
    const result: T[] = [];
    path.forEach(async (item) => {
      const dataDoc = await getDoc(item);
      if (dataDoc.exists() && !dataDoc.data().is_deleted) {
        result.push({ ...dataDoc.data(), id: dataDoc.id } as T);
      }
    });

    return result;
  } catch (error) {
    console.log(error);
    throw new Error("불러올 수 없습니다.");
  }
}

export async function getRobotData(data: []) {
  try {
    return getDocData(data, FirestoreCollenctions.ROBOTS);
  } catch (error) {
    console.log(error);
    throw new Error("불러올 수 없습니다.");
  }
}

export async function getAllRobotList(
  setData: Dispatch<SetStateAction<RobotType[]>>
) {
  try {
    // const robotCollection = collectionGroup(db, FirestoreCollenctions.ROBOTS);
    const robotCollection = collection(db, FirestoreCollenctions.ROBOTS); // TODO: 파베 collection name 변경후 수정
    const robotQuery = query(robotCollection, where("is_deleted", "==", false));
    onSnapshot(robotQuery, (snap) => {
      const results = snap.docs.map(
        (doc) => ({ id: doc.id, ...doc.data() } as RobotType)
      );

      setData(results);
    });
  } catch (error) {
    console.error(error);
    throw new Error("로봇 목록을 불러올 수 없습니다.");
  }
}

export async function getCmdList(
  command: DocumentReference,
  deviceId: string,
  setData: Dispatch<SetStateAction<CommonCommandType[]>>
) {
  try {
    onSnapshot(command, (snap) => {
      if (snap.exists()) {
        const response: CommonCommandType[] = [];

        const commandList = snap.data().command_list as CommonCommandType[];

        commandList.forEach(async (cmd) => {
          const isMacro = cmd.command === "COMMAND_MACRO";
          const isPoi = cmd.command === "COMMAND_MOVE";
          if (isMacro || isPoi) {
            const data = await getPoiListByDeviceId(deviceId, isMacro);

            if (isPoi) {
              return response.push({
                ...cmd,
                poiList: data,
              } as CommonCommandType);
            } else {
              return response.push({
                ...cmd,
                macroList: data,
              } as CommonCommandType);
            }
          }

          return response.push(cmd as CommonCommandType);
        });

        setData(response);
      }
    });
  } catch (error) {
    console.error(error);
    throw new Error("POI 정보를 불러올 수 없습니다.");
  }
}

async function getPoiListByDeviceId(deviceId: string, isMacro: boolean) {
  try {
    const poiCollection = collection(db, FirestoreCollenctions.ROBOT_POI);
    const poiQuery = query(
      poiCollection,
      where("device_id", "==", deviceId),
      where("is_deleted", "==", false),
      where("is_macro", "==", isMacro),
      orderBy("index", "asc")
    );

    const poiDoc = await getDocs(poiQuery);

    if (!poiDoc.empty) {
      const data: { id: string; name: string }[] = [];
      poiDoc.docs.forEach((doc) => {
        if (!doc.data().index || doc.data().index <= 0) return;

        data.push({
          id: doc.data().poi_id,
          name: doc.data().mrs_name ?? doc.data().name_kr ?? doc.data().name_en,
        });
      });

      return data;
    }
  } catch (error) {
    console.error(error);
    throw new Error("목록을 불러올 수 없습니다.");
  }
}

export async function getRobotList() {
  try {
    const robotRef = collection(db, FirestoreCollenctions.ROBOT_DEVICES);
    const robotSnap = await getDocs(robotRef);

    return robotSnap.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    })) as RobotType[];
  } catch (error) {
    console.error(error);
    throw new Error("로봇 목록을 불러올 수 없습니다.");
  }
}

export async function getRobotListWithAuth(robotData: string[]) {
  try {
    const res = await getListByReference<RobotType>(robotData);
    return res as RobotType[];
  } catch (error) {
    throw new Error("사이트 목록을 불러올 수 없습니다.");
  }
}

export async function getRobotListBySiteId(siteId: string) {
  try {
    const robotCollection = collection(
      db,
      FirestoreCollenctions.SITES,
      siteId,
      FirestoreCollenctions.ROBOTS
    );
    const robotQuery = query(robotCollection, where("is_deleted", "==", false));
    const result = await getDocs(robotQuery);

    return result.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.error(error);
    throw new Error("로봇 목록을 불러올 수 없습니다.");
  }
}
