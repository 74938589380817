import { useTranslation } from "react-i18next";
import { IoStarSharp, IoLocationOutline } from "react-icons/io5";
import { MdBattery5Bar } from "react-icons/md";
import { TbStairs } from "react-icons/tb";

import { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { CommonCommandType, DeviceDataType } from "../../../shared/types";
import { useUserData } from "../../../shared/hooks";
import useRobotstore from "../../../shared/store/robotStore";
import { deleteUserBookmark } from "../api/bookmark";

import { EVJobOrderButton, JobOrderButton } from "../../command/ui/content";
import { getCmdList } from "../../command/api/robot";

interface PropsType {
  robotData: DeviceDataType;
  fetchUserBookmark: () => void;
}

const BookmarkCard = ({ robotData, fetchUserBookmark }: PropsType) => {
  const { t } = useTranslation();
  const [cmdList, setCmdList] = useState<CommonCommandType[]>([]);
  const [deviceId, setDeviceId] = useState<string>(robotData?.deviceId);

  const { user } = useUserData();
  const { changeSelectedRobot, changeSelectedRobotDetailData } =
    useRobotstore();

  const getStatusClass = (status: string): string => {
    switch (status) {
      case "GREEN":
        return "text-emerald-500";
      case "ORANGE":
        return "text-orange-400";
      case "RED":
        return "text-red-500";
      case "GRAY":
        return "text-gray-400";
      default:
        return "text-gray-400";
    }
  };

  const handleBookmarkBtn = async () => {
    await deleteUserBookmark({ userUuid: user?.id, deviceId });
    fetchUserBookmark();
    toast.success("즐겨찾기가 제거되었습니다.");
  };

  useEffect(() => {
    changeSelectedRobot(robotData.deviceId);
    changeSelectedRobotDetailData(robotData);
    getCmdList(robotData.deviceId).then((res) => setCmdList(res as any));
  }, []);

  useEffect(() => {
    if (deviceId !== robotData.deviceId) {
      getCmdList(robotData.deviceId).then((res) => setCmdList(res as any));
      setDeviceId(robotData.deviceId);
    }
  }, [robotData]);

  const mapImage: { [key: string]: string } = {
    배송로봇: "delivery",
    청소로봇: "cleaning",
    방역로봇: "prevent",
    산업로봇: "industry",
    협동로봇: "coop",
    서빙로봇: "serving",
    안내로봇: "guide",
    물류로봇: "logistics",
  };

  return (
    <div className="w-[48%] h-[300px] rounded-md mb-2 mt-0 mr-1 bg-white shadow-sm flex flex-col p-5 px-8">
      <div className="relative">
        <div className="flex flex-row mt-3">
          <button onClick={handleBookmarkBtn}>
            <IoStarSharp size="24" color="#F6C36B" />
          </button>
          <span className="ml-3 text-2xl font-bold">{robotData.name}</span>
          <span className="mt-2 ml-3 text-gray-300 ">
            {robotData.modelType}
          </span>
        </div>

        <div className="mt-3">
          <span className={getStatusClass(robotData.robotStateColor)}>●</span>
          <span className="ml-2">{robotData.robotStateString}</span>
        </div>

        <div className="flex items-center mt-3 text-gray-300">
          <div className="flex items-center mr-4">
            <TbStairs />
            <span className="ml-2">
              {robotData.standardLocation.floorIndex ?? "-"}
            </span>
          </div>
          <div className="flex items-center mr-4">
            <IoLocationOutline />
            <span className="ml-2">
              {robotData.standardLocation.buildingIndex ?? "-"}
            </span>
          </div>
          <div className="flex items-center">
            <MdBattery5Bar />
            <span className="ml-2">
              {robotData.batteryLevel !== "-"
                ? `${robotData.batteryLevel}%`
                : robotData.batteryLevel}
            </span>
          </div>
        </div>

        <div className="absolute top-[-20px] right-0 flex items-center mt-3 text-gray-300">
          <img
            width="120px"
            height="100px"
            src={`/robotImage/image_robot_${
              mapImage[robotData.modelType] || "cleaning"
            }.jpg`}
            alt="로봇 이미지"
          />
        </div>
      </div>

      <hr className="mt-3" />
      <div className="flex flex-wrap">
        {cmdList && cmdList.length > 0 && (
          <div className="mt-3 mb-7">
            <div className="pb-2 text-sm font-bold">{t("Work_Order")}</div>

            {robotData.modelTypeEn === "ELEVATOR" ? (
              <EVJobOrderButton
                isOnline={
                  robotData?.isOnline || robotData?.robotStateColor === "GREEN"
                }
              />
            ) : (
              <JobOrderButton
                deviceId={robotData.deviceId}
                isOnline={
                  robotData.isOnline || !(robotData.robotStateColor === "GRAY")
                }
                cmdList={cmdList.sort((a, b) => a.order - b.order)}
                detailData={robotData}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default BookmarkCard;
