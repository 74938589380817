import { t } from "i18next";

import { useEffect } from "react";
import useRobotstore from "../../../shared/store/robotStore";
import useErrorStore from "../../../shared/store/errorStore";

const DetailError = () => {
  const { updateIsShowRobotDetail } = useRobotstore();
  const { updateRobotDetailError } = useErrorStore();

  useEffect(() => {
    updateRobotDetailError(true);
  }, []);

  const closeMenu = () => {
    updateIsShowRobotDetail(false);

    window.location.reload();
  };

  return (
    <>
      <div
        onClick={closeMenu}
        className="self-end text-black hover:cursor-pointer"
      >
        ✕
      </div>
      <div className="text-2xl">{t("Detailed_Information")}</div>
      <div className="flex flex-col items-center justify-center w-full h-full gap-4 ">
        <p className="text-lg">데이터를 불러오는데 실패했습니다.</p>
        <button
          className="px-3 py-2 transition duration-300 ease-in-out text-white rounded-lg shadow-lg hover:opacity-90 shadow-[#83d6c8] bg-primary"
          onClick={() => {
            window.location.reload();
          }}
        >
          새로고침
        </button>
      </div>
    </>
  );
};

export default DetailError;
