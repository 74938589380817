import { baseApi } from "../../../shared/api";

const { client } = baseApi;

export const getCompanyList = async () => {
  try {
    const { data } = await client.get("/dashboard/company/branch/total/list");
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
