import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Trans } from "react-i18next";
import { t } from "i18next";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
} from "chart.js";

import { LOCALE } from "../../../shared/constants";
import { numberFormat } from "../../../shared/util/format";
import useUserStore from "../../../shared/store/userStore";
import { useDashboardStore } from "../model";
import { usePeriod, useBlurStyle } from "../../../shared/hooks";

import { getRobotAvgByPeriod } from "../api";

import { Skeleton } from "../../../shared/ui";
import { Tooltip } from "react-tooltip";

import {
  PeriodWithSiteId,
  RobotModels,
  RobotServiceCount,
} from "../../../shared/types/dashBoard";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  ChartDataLabels
);

// move -> guilde
export default function ServiceBarChart() {
  const blurStyle = useBlurStyle();
  const [startDate, endDate] = usePeriod();

  const [dataCleaning, setDataCleaning] = useState<number[]>([]);
  const [dataServeDrawer, setDataServeDrawer] = useState<number[]>([]);
  const [dataDisinfection, setDataDisinfection] = useState<number[]>([]);
  const [dataServeTray, setDataServeTray] = useState<number[]>([]);
  const [dataGuide, setDataGuide] = useState<number[]>([]);
  const [dataNone, setDataNone] = useState<number[]>([]);
  const [dataSum, setDataSum] = useState<number[]>([]);

  const [dates, setDates] = useState<string[]>([]);

  const [dataList, setDataList] = useState<RobotServiceCount | null>(null);
  const { selectedUserAuthSite } = useUserStore();
  const { currentDate, oneYearAgoDate } = useDashboardStore();

  const skeletonDummyData = [
    80, 162, 178, 114, 120, 105, 195, 107, 123, 60, 199, 126,
  ];

  useEffect(() => {
    if (selectedUserAuthSite.length === 0 || !currentDate || !oneYearAgoDate) {
      return;
    }

    const data: PeriodWithSiteId = {
      startDate: oneYearAgoDate,
      endDate: currentDate,
      siteIds: selectedUserAuthSite,
    };

    getRobotAvgByPeriod(data) //
      .then((res) => {
        setDataList(res);
        setDates(
          res.datasByMonth.map((item: any) => item.date.slice(5, 7) + "월")
        );
        const tempDataCleaning = [];
        const tempDataServeDrawer = [];
        const tempDataServeTray: number[] = [];
        const tempDataDisinfection = [];
        const tempDataGuide = [];
        const tempDataNone = [];
        const tempDataSum = [];
        for (const el of res.datasByMonth) {
          let tempCleaningCnt = 0;
          let tempServeDrawerCnt = 0;
          let tempServeTrayCnt = 0;
          let tempDisinfectionCnt = 0;
          let tempGuideCnt = 0;
          let tempNoneCnt = 0;
          let tempSumCnt = 0;

          for (const item of el.datas) {
            tempSumCnt += item.cnt;

            switch (item.robotModelTypeEn) {
              case RobotModels.CLEAN_BOT:
                tempCleaningCnt += item.cnt;
                break;
              case RobotModels.SERVE_BOT_DRAWER:
                tempServeDrawerCnt += item.cnt;
                break;
              case RobotModels.SERVE_BOT_TRAY:
                tempServeTrayCnt += item.cnt;
                break;
              case RobotModels.DISINFECTION_BOT:
                tempDisinfectionCnt += item.cnt;
                break;
              case RobotModels.GUIDE_BOT:
                tempGuideCnt += item.cnt;
                break;
              default:
                tempNoneCnt += item.cnt;
                break;
            }
          }

          tempDataCleaning.push(tempCleaningCnt);
          tempDataServeDrawer.push(tempServeDrawerCnt);
          tempDataServeTray.push(tempServeTrayCnt);
          tempDataDisinfection.push(tempDisinfectionCnt);
          tempDataGuide.push(tempGuideCnt);
          tempDataNone.push(tempNoneCnt);
          tempDataSum.push(tempSumCnt);
        }

        setDataCleaning(tempDataCleaning);
        setDataServeDrawer(tempDataServeDrawer);
        setDataServeTray(tempDataServeTray);
        setDataDisinfection(tempDataDisinfection);
        setDataGuide(tempDataGuide);
        setDataNone(tempDataNone);
        setDataSum(tempDataSum);
      });
  }, [selectedUserAuthSite, currentDate, oneYearAgoDate, startDate, endDate]);

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 800,
    },
    responsiveAnimationDuration: 0,

    plugins: {
      legend: {
        position: "bottom" as const,
      },
      datalabels: {
        formatter: (value: any, context: any) => {
          if (context.dataset.label === "기타") {
            return numberFormat(dataSum[context.dataIndex], LOCALE);
          } else {
            return "";
          }
        },
        display: true,
        offset: 1,
        align: "end",
        anchor: "end",
        color: "#64748b",
      },
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
        bottom: 10,
        top: 30,
      },
    },
    scales: {
      x: {
        grid: { display: false },
        stacked: true,
      },
      y: {
        stacked: true,
        display: false,
      },
    },
  };

  const labels = dates;

  const data = {
    labels,
    plugins: [ChartDataLabels],
    datasets: [
      {
        label: "청소",
        data: dataCleaning,
        backgroundColor: "#326D6C",
        stack: "Stack 1",
      },
      {
        label: "배송",
        data: dataServeDrawer,
        backgroundColor: "#568F7C",
        stack: "Stack 1",
      },
      {
        label: "방역",
        data: dataDisinfection,
        backgroundColor: "#85B093",
        stack: "Stack 1",
      },
      {
        label: "서빙",
        data: dataServeTray,
        backgroundColor: "#7faab8",
        stack: "Stack 1",
      },
      {
        label: "안내",
        data: dataGuide,
        backgroundColor: "#BDD1BD",
        stack: "Stack 1",
      },
      {
        label: "기타",
        data: dataNone,
        backgroundColor: "#d1d5db",
        stack: "Stack 1",
      },
      // {
      //   label: "운영",
      //   data: dataOperation,
      //   backgroundColor: "#326D6C",
      //   stack: "Stack 1",
      // },
    ],
  };

  const [isShowTooltip1, setisShowTooltip1] = useState(false);
  const [isShowTooltip2, setisShowTooltip2] = useState(false);

  return (
    <div className="h-[100%] flex flex-col px-2 pt-2">
      <div className="flex px-3 py-2">
        <div className="p-2 mr-5">
          <div
            id={"serviceTooltip1"}
            className="text-sm text-[#08B295] cursor-default"
          >
            <Trans i18nKey="total_cumulative_number_of_services" />
          </div>

          <div className={`text-lg font-bold text-gray-800 ${blurStyle}`}>
            {numberFormat(dataList?.totalSum as number, LOCALE)}
            {t("case")}
          </div>
        </div>

        <div className="p-2">
          <div
            id={"serviceTooltip2"}
            className="text-sm text-[#08B295] cursor-default"
          >
            <Trans i18nKey="total_average_number_of_services" />
          </div>

          <div className={`text-lg font-bold text-gray-800 ${blurStyle}`}>
            {numberFormat(dataList?.totalAvg as number, LOCALE)}
            {t("case")}
          </div>
        </div>
      </div>

      <Tooltip
        anchorSelect={"#serviceTooltip1"}
        place={"right"}
        content="최근 1년간 로봇이 수행한 모든 서비스 건수를 합산한 값입니다."
      />

      <Tooltip
        anchorSelect={"#serviceTooltip2"}
        place={"right"}
        html="최근 1년간 로봇이 수행한 서비스 건수를 월 평균으로 계산한 값입니다. <br> 로봇의 월 평균 서비스 활동 수준을 확인할 수 있습니다. "
      />

      <div
        className={`w-[95%] h-[300px] self-center md:p-0 ${blurStyle} flex justify-center items-end`}
      >
        {!dataList ? (
          skeletonDummyData.map((item, index) => (
            <div key={index} className="mx-1 mb-12">
              <Skeleton width={22} height={item} borderRadius={2} />
            </div>
          ))
        ) : (
          <Bar options={options} data={data} />
        )}
      </div>
    </div>
  );
}
