import { Modal, Select, Table, Radio, Input } from "antd";
import React, { useEffect, useState } from "react";
import {
  AuthType,
  CommonCommandType,
  CleanRobotCommandType,
  siteRobotType,
} from "../../../shared/types";
import { getUserById } from "../../../api/firestore/user";
import { RiListSettingsLine } from "react-icons/ri";
import { MdBookmarkBorder } from "react-icons/md";

import errorPage from "../../../pages/ErrorPage";

import useUserStore from "../../../shared/store/userStore";
import { getCmdList } from "../../../features/command/api/robot";
import useScheduleStore from "../model/scheduleStore";
import { useRestRobotData } from "../../../features/robotManagement/model";
import { useLoginStore } from "../../../features/login/model";
import { useWorkflowStore } from "../../../features/workflow/model";

const robotColumns = [
  {
    title: "로봇타입",
    dataIndex: "modelType",
    key: "modelType",
    width: 200,
  },
  {
    title: "로봇명",
    dataIndex: "name",
    key: "name",
    width: 400,
  },
];

const SettingWorkModal = (props: any) => {
  const { userInfo } = useLoginStore();

  const { robotInfo, robotCmdList, changeRobotInfo, changeRobotCmdList } =
    useWorkflowStore();

  const { selectedUserAuthSite } = useUserStore();
  const { updateDeviceId } = useScheduleStore();

  const [userData, setUserData] = useState<AuthType | null>(null);

  const [selectRowIdx, setSelectRowIdx] = useState<number | null>();

  const [robotTypeFilter, setRobotTypeFilter] = useState<any>([]);

  const [cmdList, setCmdList] = useState<
    CommonCommandType[] | CleanRobotCommandType[]
  >([]);

  const [robotTableClick, setRobotTableClick] = useState<boolean>(false);

  const [poiList, setPoiList] = useState<any>([]);
  const [hallCallList, setHallCallList] = useState<any>([]);
  const [carCallList, setCarCallList] = useState<any>([]);
  const [cleanModeList, setCleanModeList] = useState<any>([]);
  const [cleanTaskList, setCleanTaskList] = useState<any>([]);
  const [taskList, setTaskList] = useState<any>([]);

  const [poiView, setPoiView] = useState<boolean>(false);
  const [hallCallView, setHallCallView] = useState<boolean>(false);
  const [carCallView, setCarCallView] = useState<boolean>(false);
  const [cleanModeView, setCleanModeView] = useState<boolean>(false);
  const [taskView, setTaskView] = useState<boolean>(false);

  const [selectWork, setSelectWork] = useState<string | null>(null);

  ////선택된 로봇정보 및 작업 데이터
  const [selectRobotData, setSelectRobotData] = useState<object>({}); //로봇정보
  const [selectWorkData, setSelectWorkData] = useState<any>(); //작업정보

  const [selectCleanMode, setSelectCleanMode] = useState<string>("");
  const [selectCleanTask, setSelectCleanTask] = useState<Object>({});
  const [selectPoi, setSelectPoi] = useState<any>({});
  const [selectFromFloor, setSelectFromFloor] = useState<string | null>(null);
  const [selectToFloor, setSelectToFloor] = useState<string | null>(null);
  const [selectCarCallFloor, setSelectCarCallFloor] = useState<string | null>(
    null
  );
  const [selectTask, setSelectTask] = useState<any>({});

  const [modalRobotFilterData, setModalRobotFilterData] = useState<
    siteRobotType[]
  >([]);

  const {
    robotData,
    robotFilterData,
    filterRobotType,
    setRobotData,
    setFilterRobotType,
    setFilterRobotName,
  } = useRestRobotData(userData);

  useEffect(() => {
    setModalRobotFilterData(props.robotFilterData);
    if (!props.workflowFlag) {
      setRobotData(props.robotFilterData);
    }
  }, [props.robotFilterData]);

  useEffect(() => {
    setModalRobotFilterData(robotFilterData);
  }, [robotFilterData]);

  useEffect(() => {
    const typeList = [...new Set(robotData.map((item) => item.modelType))].map(
      (item) => {
        return { value: item, label: item };
      }
    );
    typeList.unshift({ value: "전체", label: "전체" });

    setRobotTypeFilter((prevFilter: { value: string }[]) => [
      ...prevFilter,
      ...typeList.filter(
        (newItem) =>
          !prevFilter.some(
            (prevItem: { value: string }) => prevItem.value === newItem.value
          )
      ),
    ]);
  }, [robotData]);

  useEffect(() => {
    if (!userInfo) return;
    const fetchData = async () => {
      const data = await getUserById(userInfo.uid);
      setUserData(data);
    };
    fetchData();
  }, [userInfo]);

  useEffect(() => {
    if (props.isReset) {
      setSelectRowIdx(null);
      setSelectRobotData({});
      setSelectWorkData(null);
      setCmdList([]);

      props.setIsReset(false);
    }
  }, [props.isReset]);

  const makeSettingData = () => {
    switch (selectWorkData.command) {
      case "COMMAND_MOVE": {
        selectWorkData.poiId = selectPoi.id;
        selectWorkData.poiName = selectPoi.name;
        break;
      }
      case "COMMAND_CLEAN": {
        selectWorkData.cleaningMode = selectCleanMode;
        selectWorkData.executableTask = selectCleanTask;
        break;
      }
      case "COMMAND_HALL_CALL": {
        selectWorkData.targetFloor = selectToFloor;
        selectWorkData.startingFloor = selectFromFloor;
        break;
      }
      case "COMMAND_CAR_CALL": {
        selectWorkData.targetFloor = selectCarCallFloor;
        break;
      }
      case "COMMAND_TASK": {
        selectWorkData.taskId = selectTask.taskId;
        selectWorkData.taskName = selectTask.taskName;
        break;
      }
      // case "COMMAND_TTS": {
      //   selectWorkData.ttsMessage = "";
      //   break;
      // }
      // case "COMMAND_VIDEO": {
      //   selectWorkData.videoLink = "";
      //   break;
      // }
      // case "COMMAND_TURN": {
      //   selectWorkData.turnDirection = "left";
      //   break;
      // }
      // case "COMMAND_SOUND": {
      //   selectWorkData.soundValue = 2;
      //   break;
      // }
      // case "COMMAND_MACRO": {
      //   selectWorkData.macroId = "";
      //   break;
      // }
      default: {
        break;
      }
    }

    return selectWorkData;
  };

  const handleOk = () => {
    if (!selectRowIdx) {
      setSelectRowIdx(null);
      setSelectRobotData({});
      setSelectWorkData(null);
      setCmdList([]);
    }

    if (props.workflowFlag) {
      changeRobotInfo(selectRobotData); //store
      console.log("CMD list = > ", cmdList);

      changeRobotCmdList(cmdList);
    }

    setRobotTableClick(false);

    setFilterRobotType("전체");
    setFilterRobotName("");
    props.setIsModalOpen(false);

    if (props.useWork && selectWorkData) {
      const makeData = makeSettingData();
      props.setSelectWork(makeData);
    }
    props.setSelectRobot(selectRobotData);
  };

  const handleCancel = () => {
    setRobotTableClick(false);

    // setFilterRobotType("전체");
    // setFilterRobotName("");
    // setSelectRowIdx(null);

    // if(selectRowIdx){
    //   setSelectRowIdx(null);
    //   setSelectRobotData({});
    //   setSelectWorkData(null);
    //   setCmdList([]);
    // }
    //
    // if(props.workflowFlag) {
    //   changeRobotInfo(selectRobotData);//store
    //   changeRobotCmdList(cmdList);
    // }

    props.setIsModalOpen(false);
  };

  const onRowSelect = (row: any, index: number | undefined) => {
    (async () => {
      await getCmdListAPI(row.deviceId);
    })();
    setSelectRowIdx(index);
    setRobotTableClick(true);
    updateDeviceId(row.deviceId);
    onViewReset();
    setSelectWork(null);
    setSelectRobotData(row);
  };

  const getCmdListAPI = async (deviceId: string) => {
    await getCmdList(deviceId)
      .then((res) => {
        if (res !== null && res !== undefined) {
          setCmdList(res);
          // if(props.workflowFlag) {
          //   changeRobotCmdList(res)//store
          // }
        }
      })
      .catch((error) => {
        errorPage();
      });
  };

  const handleSelectType = (value: string, option: any) => {
    setFilterRobotType(value);
  };

  const onRobotNameSearch = (value: any) => {
    setFilterRobotName(value.currentTarget.value || "");
  };

  const onViewReset = () => {
    setPoiView(false);
    setCarCallView(false);
    setCleanModeView(false);
    setHallCallView(false);
    setTaskView(false);
  };

  const onClickWork = (item: any) => {
    setSelectWorkData(item); //선택된 작업정보

    switch (item.command) {
      case "COMMAND_MOVE": {
        onViewReset();
        setPoiView(true);

        if (item.poiList.length > 0) {
          item.poiList.forEach((data: any, i: number) => {
            data["value"] = data.id;
            data["label"] = data.name;
          });
        }
        setPoiList(item.poiList);

        break;
      }
      case "COMMAND_HALL_CALL": {
        onViewReset();
        setHallCallView(true);

        if (item.elvFloorList.length > 0) {
          item.elvFloorList.forEach((data: any, i: number) => {
            data["value"] = data.floorName;
            data["label"] = data.floorName;
            data["key"] = data.i;
          });
        }

        setHallCallList(item.elvFloorList);

        break;
      }
      case "COMMAND_CAR_CALL": {
        onViewReset();
        setCarCallView(true);

        if (item.elvFloorList.length > 0) {
          item.elvFloorList.forEach((data: any, i: number) => {
            data["value"] = data.floorName;
            data["label"] = data.floorName;
            data["key"] = data.i;
          });
        }

        setCarCallList(item.elvFloorList);

        break;
      }
      case "COMMAND_CLEAN": {
        onViewReset();
        setCleanModeView(true);

        if (item.cleanModes.length > 0) {
          item.cleanModes.forEach((data: any, i: number) => {
            data["value"] = data.name;
            data["label"] = data.name;
            data["key"] = data.i;
          });
        }

        if (item.executableTasks.length > 0) {
          item.executableTasks.forEach((data: any, i: number) => {
            data["value"] = data.id;
            data["label"] = data.name;
          });
        }

        setCleanModeList(item.cleanModes);
        setCleanTaskList(item.executableTasks);

        break;
      }
      case "COMMAND_TASK": {
        onViewReset();
        setTaskView(true);

        if (item.taskList.length > 0) {
          item.taskList.forEach((data: any, i: number) => {
            data["value"] = data.taskId;
            data["label"] = data.taskName;
            data["key"] = data.i;
          });
        }

        setTaskList(item.taskList);
        break;
      }
      default: {
        onViewReset();
      }
    }
  };

  const findSelectedIdx = (list: [], value: any): number => {
    return list.findIndex((item: any) => item.value === value);
  };

  const onSelectWork = (data: any) => {
    setSelectWork(data.target.value);
  };

  const onSelectCleanMode = (value: string) => {
    setSelectCleanMode(value);
  };
  const onSelectCleanTask = (value: string) => {
    const selectedIdx = findSelectedIdx(cleanTaskList, value);

    const { siteId, siteName, mapId, mapName, taskId, taskName } =
      cleanTaskList[selectedIdx];

    setSelectCleanTask({ siteId, siteName, mapId, mapName, taskId, taskName });
  };
  const onSelectPoi = (value: string) => {
    const selectedIdx = findSelectedIdx(poiList, value);

    const { id, name } = poiList[selectedIdx];

    setSelectPoi({ id, name });
  };
  const onSelectFromFloor = (value: string) => {
    setSelectFromFloor(value);
  };
  const onSelectToFloor = (value: string) => {
    setSelectToFloor(value);
  };
  const onSelectCarCallFloor = (value: string) => {
    setSelectCarCallFloor(value);
  };
  const onSelectTask = (value: number) => {
    const selectedIdx = findSelectedIdx(taskList, value);

    const { taskId, taskName } = taskList[selectedIdx];
    setSelectTask({ taskId, taskName });
  };

  return (
    <Modal
      open={props.isModalOpen}
      onOk={handleOk}
      okText={"확인"}
      onCancel={handleCancel}
      cancelText={"취소"}
      width={650}
    >
      <div style={style.title}>
        <RiListSettingsLine size={25} />
        <span style={style.span}>{props.modalTitle}</span>
      </div>
      <div style={style.table}>
        <div style={style.search}>
          <Select
            onChange={(value, option) => handleSelectType(value, option)}
            style={{ width: "200px" }}
            value={filterRobotType}
            options={robotTypeFilter}
          />
          <Input
            placeholder="로봇명"
            allowClear
            onChange={(value) => {
              onRobotNameSearch(value);
            }}
            style={{ marginLeft: "10px", width: 200 }}
          />
        </div>
        <div>
          <Table
            columns={robotColumns}
            dataSource={modalRobotFilterData}
            size={"small"}
            bordered={true}
            rowClassName={(record, index) =>
              selectRowIdx === index ? "table-red" : ""
            }
            pagination={{
              hideOnSinglePage: true,
              showSizeChanger: false,
              pageSize: 5,
            }}
            className={"hover:cursor-pointer"}
            rowKey={(row) => row.id}
            onRow={(row, index) => ({ onClick: () => onRowSelect(row, index) })}
          />
        </div>

        {props.useWork && cmdList.length > 0 && robotTableClick && (
          <div style={style.work}>
            <div style={style.subTitle}>
              <MdBookmarkBorder size={15} />
              <span style={style.text}>작업지시</span>
            </div>
            <div>
              <Radio.Group
                buttonStyle="solid"
                value={selectWork}
                onChange={(data) => onSelectWork(data)}
              >
                {cmdList?.map((item, idx) => {
                  if (item.command !== "COMMAND_REPORT") {
                    return (
                      <Radio.Button
                        key={item.command}
                        value={item.command}
                        onClick={() => onClickWork(item)}
                      >
                        {item.name}
                      </Radio.Button>
                    );
                  }
                })}
              </Radio.Group>
            </div>
            {poiView && (
              <div>
                <span>목적지</span>
                <Select
                  placeholder={"목적지를 선택하세요."}
                  style={{
                    width: "200px",
                    marginTop: "15px",
                    marginLeft: "10px",
                  }}
                  options={poiList}
                  onChange={onSelectPoi}
                />
              </div>
            )}
            {hallCallView && (
              <div>
                <span>이동 층</span>
                <Select
                  placeholder={"이동 층을 선택하세요."}
                  style={{
                    width: "200px",
                    marginTop: "15px",
                    marginLeft: "10px",
                    marginRight: "20px",
                  }}
                  options={hallCallList}
                  onChange={onSelectToFloor}
                />
                <span>호출 층</span>
                <Select
                  placeholder={"호출 층을 선택하세요."}
                  style={{
                    width: "200px",
                    marginTop: "15px",
                    marginLeft: "15px",
                  }}
                  options={hallCallList}
                  onChange={onSelectFromFloor}
                />
              </div>
            )}
            {carCallView && (
              <div>
                <span>이동 층</span>
                <Select
                  placeholder={"이동할 층을 선택하세요."}
                  style={{
                    width: "200px",
                    marginTop: "15px",
                    marginLeft: "15px",
                  }}
                  options={carCallList}
                  onChange={onSelectCarCallFloor}
                />
              </div>
            )}
            {cleanModeView && (
              <div>
                <span>청소모드</span>
                <Select
                  placeholder={"청소모드를 선택하세요."}
                  style={{
                    width: "200px",
                    marginTop: "15px",
                    marginLeft: "10px",
                    marginRight: "20px",
                  }}
                  onChange={onSelectCleanMode}
                  options={cleanModeList}
                />
                <span>TASK</span>
                <Select
                  placeholder={"TASK를 선택하세요."}
                  style={{
                    width: "200px",
                    marginTop: "15px",
                    marginLeft: "15px",
                  }}
                  options={cleanTaskList}
                  onChange={onSelectCleanTask}
                />
              </div>
            )}
            {taskView && (
              <div>
                <span>태스크</span>
                <Select
                  placeholder={"태스크를 선택하세요."}
                  style={{
                    width: "200px",
                    marginTop: "15px",
                    marginLeft: "10px",
                    marginRight: "20px",
                  }}
                  onChange={onSelectTask}
                  options={taskList}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default SettingWorkModal;

const style = {
  alignCenter: {
    alignSelf: "center",
  },

  table: {
    marginTop: "20px",
    // height: '470px'
    //width: '50%'
  },

  title: {
    display: "flex",
    borderBottom: "1px solid #eee",
    paddingBottom: "15px",
  },

  span: {
    fontSize: "18px",
    marginLeft: "10px",
    fontWeight: "bold",
  },

  search: {
    marginBottom: "15px",
  },

  subTitle: {
    display: "flex",
    marginTop: "20px",
    marginBottom: "15px",
    alignItems: "center",
  },

  text: {
    fontSize: "14px",
    alignSelf: "center",
    fontWeight: "bold",
    paddingLeft: "5px",
  },

  work: {
    height: "130px",
  },
};
