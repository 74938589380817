import smallLogo from "../../../shared/assets/image/logo.png";

import marosolIcon from "../../../shared/assets/image/marosol.png";
import openIcon from "../../../shared/assets/icon/GNB_Open.svg";
import { Link } from "react-router-dom";

import useDatastore from "../../../shared/store/dataStore";
import useRobotstore from "../../../shared/store/robotStore";

import { useTranslation } from "react-i18next";
import useUserStore from "../../../shared/store/userStore";
import { Permission } from "../../../shared/types/user";

import gnbData from "../../../shared/json/gnb.json";
import { Menu } from "../../../shared/ui";
export default function Gnb() {
  const { t } = useTranslation();

  const { userStatus } = useUserStore();

  const { updateIsShowRobotDetail } = useRobotstore();
  const { updateRobotDetailData } = useDatastore();

  const isSuperAdmin = userStatus === Permission.SUPER_ADMIN;
  const isEngineer = userStatus === Permission.ENGINEER;

  return (
    <div
      onClick={() => {
        updateIsShowRobotDetail(false);
        updateRobotDetailData(null);
      }}
      className="z-[300] fixed w-[75px] h-[100vh] bg-white border-r flex flex-col items-center justify-between pr-1"
    >
      <div className="w-[100%] mt-1">
        <Link
          to="/"
          className="border-l-4 border-l-white flex flex-col items-center w-[100%] pt-3 pb-1 mt-1 mb-2 hover:cursor-pointer"
        >
          <img src={smallLogo} width={30} height={30} alt="logo" />
        </Link>

        {gnbData.map(
          (menu, idx) =>
            (menu.i18nKey !== "real_time" || isSuperAdmin || isEngineer) &&
            (process.env.REACT_APP_ENV !== "production" ||
              !(
                menu.i18nKey === "schedule" ||
                menu.i18nKey === "workflowBuilder" ||
                menu.i18nKey === "bookmark"
              )) && (
              <Menu
                key={idx}
                i18nKey={menu.i18nKey}
                iconPath={menu.iconPath}
                toPath={menu.toPath}
              />
            )
        )}
      </div>

      <div className="flex flex-col items-center justify-center mb-3">
        <a
          className="w-[100%] flex flex-col items-center hover:cursor-pointer hover:text-[#08B295]"
          target="_blank"
          rel="noreferrer"
          href="https://www.myrobotsolution.com"
        >
          <img src={marosolIcon} className="w-[35%]" alt="marosol logo" />
          <div className="flex items-center">
            <div className="py-2 text-xs text-gray-800">{t("marosol")}</div>
            <img src={openIcon} className="w-[0.8rem] ml-1" alt="open" />
          </div>
        </a>
      </div>
    </div>
  );
}
