import { CurrentTask } from "../../../shared/types/schedule";

import { deleteTask, postStopTask } from "../api";
import { toast } from "react-toastify";
import useModalstore from "../../../shared/store/modalStore";
import { t } from "i18next";
import Empty from "../../../shared/ui/Empty";
import wariningIcon from "../../../shared/assets/icon/icon_warning.svg";
import useUserStore from "../../../shared/store/userStore";
import Spinner from "../../../shared//ui/Loading/Spinner";
import TaskCard from "../../../entities/scheduleTask/ui/TaskCard";

interface ProgressProps {
  loading: boolean;
  curentTaskList: CurrentTask[];
}

const Progress = ({ loading, curentTaskList }: ProgressProps) => {
  const { closeModal } = useModalstore();
  const { selectedUserAuthSite } = useUserStore();

  return (
    <div>
      {!loading ? (
        curentTaskList && curentTaskList.length > 0 ? (
          curentTaskList.map((task: CurrentTask) =>
            task.isVisible ? (
              <div className="flex flex-col gap-4 pb-3" key={task.taskId}>
                <div className="w-[60%] ">
                  <TaskCard
                    taskId={task.taskId}
                    taskName={task.taskName}
                    taskState={task.taskState}
                    currentAction={task.currentAction}
                    prevAction={task.prevAction}
                    agentNameList={task.agentNameList}
                    currentActionAlias={task.currentActionAlias}
                    prevActionAlias={task.prevActionAlias}
                    currentActionCount={task.currentActionCount}
                    totalActionCount={task.totalActionCount}
                    startTime={task.startTime}
                    lastUpdateTime={task.lastUpdateTime}
                    ellapsedTimeTotal={task.ellapsedTimeTotal}
                    isStopped={task.taskState === "USER_STOP"}
                    onStopClick={() =>
                      postStopTask({
                        siteId: selectedUserAuthSite[0],
                        taskId: task.taskId,
                        activation: "STOP",
                      })
                        .then(() => {
                          toast.success(t("It_has_been_stopped"));
                          closeModal();
                        })
                        .catch((error) => toast.error(`${error}`))
                    }
                    onDeleteClick={() =>
                      deleteTask({
                        siteId: selectedUserAuthSite[0],
                        taskId: task.taskId,
                      })
                        .then(() => {
                          toast.success(t("It_has_been_deleted"));
                          closeModal();
                        })
                        .catch((error) => toast.error(`${error}`))
                    }
                  />
                </div>
              </div>
            ) : null
          )
        ) : (
          <div className="flex items-center justify-center w-full pt-[120px]  ">
            <Empty
              mainText={t("There_are_no_ongoing_works_at_the_moment")}
              subText={t("There_is_no_work_available_on_the_selected_site")}
            >
              {<img src={wariningIcon} className="w-[48px] py-4" />}
            </Empty>
          </div>
        )
      ) : (
        <div className="flex pt-20 h-h-full">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default Progress;
