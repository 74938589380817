import { siteRobotType } from "../../../shared/types";
import { baseApi } from "../../../shared/api";

const { client } = baseApi;
export async function getSiteRobots(siteIds: string): Promise<siteRobotType[]> {
  try {
    const res = await client.get(`/robot/all/by-sites?siteIds=${siteIds}`);

    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error("로봇 데이터를 가져올 수 없습니다.");
  }
}
