import { useState } from "react";
import { toast } from "react-toastify";

import CheckIcon from "../../../../shared/assets/icon/icon_check.png";
import {
  CleanRobotCommandType,
  ExecutableTask,
  PostJobOrderType,
  TaskType,
} from "../../../../shared/types";
import useModalstore from "../../../../shared/store/modalStore";
import useDatastore from "../../../../shared/store/dataStore";
import { Button } from "../../../../shared/ui";
import { getIsRobotReady, onStartJob } from "../../api/robot";

type PropsType = {
  deviceId: string;
  command: CleanRobotCommandType[];
};

type CleanJobType = {
  cleaningMode: string;
  executableTask: ExecutableTask | null;
};

export default function CleanModal({ deviceId, command }: PropsType) {
  const { closeModal } = useModalstore();
  const { robotDetailData } = useDatastore();

  const [selectedTask, setSelectedTask] = useState<number | null>(null);
  const [selectedMode, setSelectedMode] = useState<number | null>(null);

  const [jobOrder, setJobOrder] = useState<CleanJobType>({
    cleaningMode: "",
    executableTask: null,
  });

  const onSelectTask = (index: number, executableTask: ExecutableTask) => {
    setSelectedTask(index);
    executableTask;
    setJobOrder((prev) => ({ ...prev, executableTask }));
  };

  const onSelectCleanMode = (index: number, cleaningMode: string) => {
    setSelectedMode(index);
    setJobOrder((prev) => ({ ...prev, cleaningMode }));
  };

  const onStartClean = async () => {
    if (!robotDetailData || !robotDetailData.id) return;

    const isReady = await getIsRobotReady(deviceId);

    if (!isReady) {
      toast.error("현재 다른 작업을 수행 중입니다.");
      return;
    }

    if (!jobOrder.executableTask) {
      toast.info("Task를 선택해 주세요.");
      return;
    }

    if (!jobOrder.cleaningMode) {
      toast.info("청소 모드를 선택해 주세요.");
      return;
    }

    const robotData: PostJobOrderType = {
      command: "COMMAND_CLEAN",
      deviceId: deviceId,
      ...jobOrder,
    };

    onStartJob(robotData)
      .then(() => {
        toast.success("작업지시가 완료되었습니다.");
        closeModal();
      })
      .catch((e) => toast.error(`ERROR:: ${e.message}`));
  };

  return (
    <div className="flex flex-col justify-evenly h-[22rem]">
      <section className="flex justify-center">
        <div className="w-[45%] flex flex-col m-2">
          <h3 className="my-3 mx-0 font-bold text-[#868686]">Task 선택</h3>

          <ul
            id="scroll"
            className="h-[200px] overflow-auto rounded border-[1px] border-[#d1d1d1]"
          >
            {command &&
              command?.map((cmd) =>
                cmd.executableTasks?.map((t: TaskType, index: number) => (
                  <li
                    key={index}
                    className={`flex justify-between items-center px-4 py-[10px] cursor-pointer hover:bg-hover ${
                      selectedTask === index && "bg-hover"
                    }`}
                    onClick={() => {
                      onSelectTask(index, cmd.executableTasks[index]);
                    }}
                  >
                    <p className="text-xs text-[#8e979e] whitespace-nowrap overflow-hidden text-ellipsis">
                      {t.taskName}
                    </p>
                    {selectedTask === index && (
                      <img src={CheckIcon} alt="check icon" />
                    )}
                  </li>
                ))
              )}
          </ul>
        </div>

        <div className="w-[45%] flex flex-col m-2">
          <h3 className="my-3 mx-0 font-bold text-[#868686]">청소 모드 선택</h3>

          <ul
            id="scroll"
            className="h-[200px] overflow-auto rounded border-[1px] border-[#d1d1d1]"
          >
            {command &&
              command?.map((cmd) =>
                cmd?.cleanModes?.map((c: { name: string }, index: number) => (
                  <li
                    key={index}
                    className={`flex justify-between items-center px-4 py-[10px] cursor-pointer hover:bg-hover ${
                      selectedMode === index && "bg-hover"
                    }`}
                    onClick={() => onSelectCleanMode(index, c.name)}
                  >
                    <p className="text-xs text-[#8e979e] whitespace-nowrap overflow-hidden text-ellipsis">
                      {c.name}
                    </p>
                    {selectedMode === index && (
                      <img src={CheckIcon} alt="check icon" />
                    )}
                  </li>
                ))
              )}
          </ul>
        </div>
      </section>

      <section className="flex items-center justify-center w-full mx-0 my-3">
        <Button
          outlined
          label="취소"
          type="button"
          size="small"
          onClick={closeModal}
        />
        <Button
          label={"시작"}
          type="button"
          size="small"
          primary
          onClick={onStartClean}
        />
      </section>
    </div>
  );
}
