import { doc, getDoc } from "firebase/firestore";
import { AuthType, FirestoreCollenctions } from "../../shared/types";
import { db } from "../../shared/lib";

export async function getUserById(uid: string) {
  try {
    const docRef = doc(db, FirestoreCollenctions.USERS, uid);
    const docSnap = await getDoc(docRef);
    const snapshotData = docSnap.data();

    return snapshotData as AuthType;
  } catch (error) {
    console.error(error);
    throw new Error("불러올 수 없습니다.");
  }
}
