import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

//react-tooltip
import { Tooltip } from "react-tooltip";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
} from "chart.js";

import { useBlurStyle } from "../../../../shared/hooks";

import MostErrorBarChart from "./MostErrorBarChart";
import useUserStore from "../../../../shared/store/userStore";
import { ErrorRobotType, PeriodWithSiteId } from "../../../../shared/types";

import {
  LOCALE,
  PAGE_VIEW_CNT_ERROR_ROBOT,
} from "../../../../shared/constants";
import { Pagination, Skeleton } from "../../../../shared/ui";
import { numberFormat } from "../../../../shared/util/format";
import { getErrorRobotByPeriod } from "../../api";
import { useDashboardStore } from "../../../../features/chart/model";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Legend);

export default function MostErrorRobots(props: any) {
  const { t } = useTranslation();
  const blurStyle = useBlurStyle();

  const { selectedUserAuthSite } = useUserStore();
  const { currentDate, oneMonthAgoDate } = useDashboardStore();
  const skeletonDummyData = [90, 192, 278, 228, 150, 205, 177];

  const [errorData, setErrorData] = useState<ErrorRobotType[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [max, setMax] = useState<ErrorRobotType | null>(null);
  const [isShowTooltip, setIsShowTooltip] = useState<boolean>(false);
  const [isLoding, setIsLoding] = useState<boolean>(true);

  useEffect(() => {
    if (selectedUserAuthSite.length === 0) {
      return;
    }

    const data: PeriodWithSiteId = {
      startDate:
        props.startDate === "null" || !props.startDate
          ? oneMonthAgoDate
          : props.startDate,
      endDate:
        props.endDate === "null" || !props.endDate
          ? currentDate
          : props.endDate,
      siteIds: selectedUserAuthSite,
    };

    getErrorRobotByPeriod(data) //
      .then((res) => {
        const temp = res.sort(
          (a: any, b: any) => b.errorDatas[0].cnt - a.errorDatas[0].cnt
        );

        setErrorData(temp);
        setIsLoding(false);
        setMax(temp[0]);

        setTotalPages(Math.ceil(temp.length / PAGE_VIEW_CNT_ERROR_ROBOT));
      });
  }, [
    selectedUserAuthSite,
    currentDate,
    oneMonthAgoDate,
    props.startDate,
    props.endDate,
  ]);

  return (
    <div className="h-[100%] flex flex-col p-2 px-5 items-center justify-between">
      <div className="w-full">
        <Tooltip
          anchorSelect={"#mostErrorRobotsTooltip"}
          place={"right"}
          content="최근 1일간 로봇이 각 시간대에 수행한 서비스 건수를 나타내는 차트입니다."
        />
        <div className="flex self-start pt-2">
          <div className="p-2 mr-5">
            <div
              id={"mostErrorRobotsTooltip"}
              className="text-[#08B295] text-sm cursor-default"
            >
              <Trans i18nKey="robot_with_the_most_errors" />
            </div>

            <div className={`text-lg font-bold text-gray-800 ${blurStyle}`}>
              {/* {t(max?.robotName)} */}
              {max?.robotName}
            </div>
          </div>
        </div>

        {errorData.length > 0 ? (
          <div
            className={`w-[97%] h-[90%] pl-5 pt-0 pb-2 mt-0 ${blurStyle}`}
            style={{ display: "flex" }}
          >
            <div className={`w-[80%] h-[90%] pl-5 pt-0 pb-2 mt-0 ${blurStyle}`}>
              {isLoding ? (
                skeletonDummyData.map((item, i) => (
                  <div key={i} className="flex">
                    <Skeleton
                      width={item}
                      height={20}
                      borderRadius={3}
                      className="my-1"
                    />
                  </div>
                ))
              ) : (
                <MostErrorBarChart
                  robotData={errorData.slice(
                    (currentPage - 1) * PAGE_VIEW_CNT_ERROR_ROBOT,
                    currentPage * PAGE_VIEW_CNT_ERROR_ROBOT
                  )}
                  max={max?.errorDatas[0].cnt ?? 0}
                />
              )}
            </div>
            <div className={`w-[50%] ${blurStyle}`}>
              <div className="flex text-[0.8rem] justify-center text-center w-[100%] mt-3">
                <div className="py-1 font-bold rounded-l-lg w-[40%] bg-neutral-100">
                  <Trans i18nKey="robot_name" />
                </div>
                <div className="py-1 font-bold w-[30%] bg-neutral-100">
                  <Trans i18nKey="error" />
                </div>
                <div className="py-1 font-bold rounded-r-lg w-[30%] bg-neutral-100">
                  <Trans i18nKey="number_of_cases" />
                </div>
              </div>

              {isLoding ? (
                <Skeleton className="w-full h-[190px] mt-1 rounded-md" />
              ) : (
                errorData
                  .slice(
                    (currentPage - 1) * PAGE_VIEW_CNT_ERROR_ROBOT,
                    currentPage * PAGE_VIEW_CNT_ERROR_ROBOT
                  )
                  .map((item: ErrorRobotType) => (
                    <div
                      key={item.deviceId}
                      className="w-[100%] flex justify-center text-[0.7rem] text-center border-b"
                    >
                      <div className="py-2 w-[40%]">{item.robotName}</div>
                      <div className="py-2 w-[30%]">
                        {item.errorDatas[0].errorTypeKr}
                      </div>
                      <div className="py-2 w-[30%]">
                        {numberFormat(item.errorDatas[0].cnt, LOCALE)}
                      </div>
                    </div>
                  ))
              )}
              <div className={`pt-3 ${blurStyle}`}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onClickPrev={() => setCurrentPage((prev) => prev - 1)}
                  onClickNext={() => setCurrentPage((prev) => prev + 1)}
                />
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              fontSize: "60px",
              textAlign: "center",
              fontWeight: "bold",
              fontFamily: "SUIT",
              height: "400px",
              lineHeight: 5,
              color: "#e0e0e0",
            }}
          >
            NO DATA
          </div>
        )}
      </div>
    </div>
  );
}
