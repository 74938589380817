
export const NodeEdgeValidation = (nodeList: any, edgeList: any) => {

    const isValidation = false;
    const msg = '';

    console.log('nodeList v= ', nodeList);
    console.log('edgeList v= ', edgeList);

    const output = nodeList.filter((item: any, idx: number) => item.type === 'output');
    const input = nodeList.filter((item: any, idx: number) => item.type === 'input');

    if(input.length === 0 && output.length === 0) {
        return { isValidation: true, msg: "START/END 노드가 없습니다." }
    }else if(output.length === 0) {
        return { isValidation: true, msg: "END 노드가 없습니다." }
    }else if(input.length === 0) {
        return { isValidation: true, msg: "START 노드가 없습니다." }
    }else {
        const nodeId: any[] = [];
        const edgeId: any[] = [];

        nodeList.filter((item: any, idx: number) => {
            if (item.type !== 'output') {
                nodeId.push(item.id);
            }
        });

        edgeList.filter((item: any, idx: number) => {
            if (item.type !== 'output') {
                edgeId.push(item.source);
            }
        });

        const dup = [...new Set(edgeId)];

        const edgeCheck = nodeId.filter(x => !dup.includes(x));

        console.log('dup v= ', dup);
        console.log('nodeId v= ', nodeId);

        //if (JSON.stringify(dup) !== JSON.stringify(nodeId)) {
        if(edgeCheck.length > 0){
            return {isValidation: true, msg: "노드연결을 확인하세요."}
        }
        return { isValidation, msg };
    }

}
