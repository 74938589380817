import { Link } from "react-router-dom";
import { Pathname } from "../shared/types";
import { t } from "i18next";

export default function NotFound() {
  return (
    <div className="flex flex-col items-center justify-center w-full h-screen gap-5 pt-10 ">
      <span className="text-5xl font-semibold text-[#12172C] pb-5">
        {t("This_page_does_not_exist")}
      </span>
      <div className="flex flex-col items-center justify-center text-sm text-gray-500">
        <p>
          {t(
            "Sorry_The_page_address_you_entered_is_incorrect_or_has_been_deleted_and_it_is_not_available_for_use"
          )}
        </p>
        <p>{t("Please_double_check_the_address")}</p>
      </div>

      <Link
        className="px-6 py-3 transition duration-300 ease-in-out text-white rounded-lg shadow-lg hover:opacity-90 shadow-[#83d6c8] bg-primary"
        to={Pathname.MAIN}
      >
        {t("Go_to_the_main_page")}
      </Link>
    </div>
  );
}
