import { PostJobOrderType, RobotDetailType } from "../../../shared/types";
import { baseApi } from "../../../shared/api";

const { client } = baseApi;

export async function getCmdList(deviceId: string) {
  try {
    const { data }: { data: RobotDetailType } = await client.get(
      `/robot/status/${deviceId}`
    );

    return data ? data.commandList.sort((a, b) => a.order - b.order) : null;
  } catch (error) {
    console.error(error);
  }
}

export async function getIsRobotReady(deviceId: string | number) {
  try {
    const res = await client.get(
      `/robot/check-is-robot-ready?deviceId=${deviceId}`
    );
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export async function onStartJob(robotData: PostJobOrderType) {
  try {
    const res = await client.post(`/robot/command`, robotData);
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export async function getReportList(robotDetailData: any) {
  try {
    const res = await client.get(
      `/robot/gausium-task-report/${robotDetailData.deviceId}?page=1&pageSize=100`
    );
    return res.data;
  } catch (error) {
    console.error(error);
  }
}
