import {create} from "zustand";

interface WorkflowData {
    robotInfo: any;
    robotCmdList: any;
    nodeList:any,
    edgeList: any,
    elvList: any,
    edgeType: string,
    robotType: string,

    changeRobotInfo: (inputRobotInfo: any) => void;
    changeRobotCmdList: (inputRobotCmdInfo: any) => void;
    changeNodeList: (inputNodeInfo: any) => void;
    changeEdgeList: (inputEdgeInfo: any) => void;
    setElvList: (inputEdgeInfo: any) => void;
    changeEdgeType: ( selectEdgeType: string) => void;
    changeRobotType: ( selectRobotType: string) => void;
}

const useWorkflowStore = create<WorkflowData>()((set) => ({
    robotInfo: null,
    robotCmdList: [],
    nodeList: [],
    edgeList: [],
    elvList: [],
    edgeType: 'default',
    robotType: 'single',

    changeRobotInfo: (inputRobotInfo) =>
        set((state) => ({
            robotInfo: inputRobotInfo,
        })),
    changeRobotCmdList: (inputRobotCmdInfo) =>
        set((state) => ({
            robotCmdList: inputRobotCmdInfo,
        })),
    changeNodeList: (inputNodeInfo) =>
        set((state) => ({
            nodeList: inputNodeInfo,
        })),
    changeEdgeList: (inputEdgeInfo) =>
        set((state) => ({
            edgeList: inputEdgeInfo,
        })),
    setElvList: (elvInfo) =>
        set((state) => ({
            elvList: elvInfo,
        })),
    changeEdgeType: (selectEdgeType) =>
        set((state) => ({
            edgeType: selectEdgeType,
        })),
    changeRobotType: (selectRobotType) =>
        set((state) => ({
            robotType: selectRobotType,
        })),
}));

export default useWorkflowStore;

