import { Timestamp } from "firebase/firestore";

export enum Permission {
  SUPER_ADMIN,
  ENGINEER,
  USER,
}

export enum PermissionStatus {
  WAITING,
  DONE,
  JOIN,
}

export interface AuthType {
  id?: string;
  email: string;
  display_name: string;
  permission: Permission | null; // 어드민 관리자 권한
  status: PermissionStatus; // 관리자 권한 요청 처리 상태
  phone: string | null;
  joined_at: Date | Timestamp;
  updated_at: string | null;
  updated_by: string | null;
  auth_sites: string[];
  auth_robots: string[];
}

export interface UserType extends AuthType {
  emailVerified: boolean;
}

/**
 * admin user api types
 */

interface TUserState {
  uuid: string;
  name: string;
  nameKr: string;
  nameEn: string;
}

interface TUserRoleClass {
  uuid: string;
  name: string;
  nameKr: string;
  nameEn: string;
  roleLevel: number;
  updatedAt: string | Date | null;
  createdAt: string | Date;
  userRolePermissionList: [];
}
export interface TUser {
  uid: string;
  name: string;
  phone: string | null;
  email: string;
  siteUuid: string | null;
  approverUid: string;
  updatedAt: string;
  createdAt: string;
  state: TUserState;
  userRoleClass: TUserRoleClass;
}

export interface TSignupParams {
  uid: string;
  email: string;
  name: string;
  phone?: string;
  approverEmail?: string;
}

export type TSignupInputParams = Pick<
  TSignupParams,
  "name" | "phone" | "approverEmail"
>;

export interface TEmailParams {
  email: string;
}
