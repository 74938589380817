import React, {useEffect, useState} from 'react';
import {IoCaretForwardCircle, IoStopCircle} from "react-icons/io5";
import {FaElevator, FaListCheck, FaUserCheck} from "react-icons/fa6"; //car call
import {
    MdEventRepeat,
    MdHome,
    MdLibraryAddCheck,
    MdOutlineCleaningServices,
    MdOutlineDoorSliding,
    MdPermMedia
} from "react-icons/md";
import {BiDoorOpen, BiSolidTimer} from "react-icons/bi";
import {LuMove} from "react-icons/lu";
import {PiElevatorFill} from "react-icons/pi"; //hall call
import {RiBattery2ChargeFill, RiDoorClosedLine} from "react-icons/ri";
import {BsDatabaseFillCheck} from "react-icons/bs";


import {DatePicker, Input, Select} from 'antd';


import "../ui/workflow.css";
import dayjs, {Dayjs} from "dayjs";

import {TbEaseInOutControlPoints, TbStairsUp} from "react-icons/tb";
import {AiFillSchedule} from "react-icons/ai";
import {FaMapMarkedAlt} from "react-icons/fa";
import {FcSerialTasks} from "react-icons/fc";
import {Handle, Position} from "reactflow";
import {getCmdList} from "../../command/api/robot";
import errorPage from "../../../pages/ErrorPage";
import {useWorkflowStore} from "../model";
import {useRestRobotData} from "../../robotManagement/model";
import {AuthType} from "../../../shared/types";
import workflowStore from "../model/workflowStore";


export const NodeLabelStyle = (type: any, title: any) => {

    const style = {
        style: {
            width: '175px',
            height: '50px',
            padding: 7,
            fontSize: '19px',
            background: '#fff',
            cursor: 'move',
            borderRadius: 4,
            display: 'flex',
            alignItems: 'center',
            border: '1px solid rgba(0, 0, 0, 0.35)',
            fontFamily: 'Gmarket Sans, Medium',
            boxShadow: '0 1px 4px 1px rgba(0, 0, 0, 0.4)',
        }
    };

    if (title === 'ROBOT ELV MOVE') {
        style.style.height = '100px';
        style.style.width = '190px'
    }

    if (type === 'START/END') {
        style.style.width = '130px';
        style.style.height = '40px';
    }

    if (title === 'HALL_CALL' || title === 'CAR_CALL' || title === 'CLEAN') { /*|| title === 'CHANGE_FLOOR')*/
        style.style.height = '63px';
        style.style.width = '180px';
    }

    return style;
}

// let newNode: any = [];
// export const NodeDataChange = (newNodeData: any) => {
//     newNode = newNodeData;
// }

const list = [
    {label: '1', name: '1'},
    {label: '2', name: '2'},
    {label: '3', name: '3'},
]

export const NodeLabelList = (props: any, id: string, fullName: string, type: string) => {

    const [userData, setUserData] = useState<AuthType | null>(null);

    const {
        robotInfo,
        robotCmdList,
        nodeList,
        edgeList,
        elvList,
        setElvList,
        changeNodeList,
        changeEdgeList,
        changeRobotInfo,
        changeRobotCmdList,
        robotType
    } = useWorkflowStore();

    const {
        robotData,
    } = useRestRobotData(userData);

    const [inputData, setInputData] = useState<string>('');


    const [elevatorList, setElevatorList] = useState<any>([]); //엘리베이터 목록

    const [selectRobot, setSelectRobot] = useState<any>({});

    const [selectRobotCmdList, setSelectRobotCmdList] = useState<any>([]);
    const [selectElvCmdList, setSelectElvCmdList] = useState<any>([]);


    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const [workDateStr, setWorkDateStr] = useState<string | string[] | null>(null);
    const [workDate, setWorkDate] = useState<Dayjs | null>(null);
    const [moveList, setMoveList] = useState<any>([]); //COMMAND_MOVE
    const [cleanTaskList, setCleanTaskList] = useState<any>([]); //COMMAND_CLEAN
    const [cleanModeList, setCleanModeList] = useState<any>([]); //COMMAND_CLEAN
    const [elvHcFloorList, setElvHcFloorList] = useState<any>([]); //COMMAND_HALL_CALL
    const [elvCcFloorList, setElvCcFloorList] = useState<any>([]); //COMMAND_CAR_CALL
    const [changeFloorList, setChangeFloorList] = useState<any>([]); //COMMAND_CAR_CALL


    const [move, setMove] = useState<string>('');
    const [changeFloor, setChangeFloor] = useState<string>('');
    const [cleaningMode, setCleaningMode] = useState<string>('');
    const [cleaningTask, setCleaningTask] = useState<string>('');
    const [hcFrom, setHcFrom] = useState<string>('');
    const [hcTo, setHcTo] = useState<string>('');
    const [ccTo, setCcTo] = useState<string>('');
    const [selectHcElv, setSelectHcElv] = useState<string>('');
    const [selectCcElv, setSelectCcElv] = useState<string>('');


    ///PRESET
    const [selectPresetElv, setSelectPresetElv] = useState<string>('');
    const [inMoveList, setInMoveList] = useState<any>([]);
    const [outMoveList, setOutMoveList] = useState<any>([]);
    const [selectInMove, setSelectInMove] = useState<string>('');
    const [selectOutMove, setSelectOutMove] = useState<string>('');
    const [elvPresetFloorList, setElvPresetFloorList] = useState<any>([]);
    const [presetElvHcList, setPresetElvHcList] = useState<any>([]);
    const [presetElvCcList, setPresetElvCcList] = useState<any>([]);

    const [selectPresetFrom, setSelectPresetFrom] = useState<string>('');
    const [selectPresetTo, setSelectPresetTo] = useState<string>('');

    const [resultNodeList, setResultNodeList] = useState<any>([]);

    //로봇타입
    const [selectRobotType, setSelectRobotType] = useState<string>('');


    useEffect(() => {

        if (elvList.length > 0) {
            elvList.forEach((item: any, i: number) => {
                item['value'] = item.deviceId;
                item['label'] = item.name;
            })
        }

        setElevatorList(elvList);

    }, [elvList, elevatorList, props.item]);

    useEffect(() => {
        changeNodeList(nodeList);
    }, [nodeList]);


    useEffect(() => {

        setSelectRobot(robotInfo);
        setSelectRobotCmdList(robotCmdList);

        /* 청소시작 변수 */
        setCleaningTask('');
        setCleaningMode('');

        /* 목적지 */
        setMove('');

    }, [robotInfo, robotCmdList]);


    const getCmdListAPI = async (deviceId: string) => {
        await getCmdList(deviceId)
            .then((res: any) => {
                if (res !== null && res !== undefined) {
                    setSelectElvCmdList(res);
                }
            })
            .catch((error: any) => {
                errorPage();
            });
    };

    /* TASK노드 불러오기 */
    useEffect(() => {

        if (props.item !== undefined) {

            switch (props.action) {
                case "WAIT": {
                    if (props.item.variable !== undefined) {
                        setInputData(props.item.variable[0].value)
                    }
                    break;
                }
                case "ON_TIME": {
                    if (props.item.variable !== undefined) {
                        setWorkDate(dayjs(props.item.variable[0].value, 'YYYY-MM-DD'))
                        setWorkDateStr(props.item.variable[0].value)
                    }
                    break;
                }
                case "MOVE": {
                    if (props.item.variable !== undefined) {
                        setMove(props.item.variable[0].value)
                    }
                    break;
                }
                case "HALL_CALL": {
                    if (props.item.selectElv !== undefined) {

                        (async () => {
                            await getCmdListAPI(props.item.selectElv[0].value);

                            setSelectHcElv(props.item.selectElv[0].value);
                        })()
                    }
                    if (props.item.startingFloor !== undefined) {
                        setHcFrom(props.item.startingFloor[0].value);
                    }
                    if (props.item.startingFloor !== undefined) {
                        setHcTo(props.item.targetFloor[0].value);
                    }


                    break;
                }
                case "CAR_CALL": {
                    if (props.item.selectElv !== undefined) {
                        (async () => {
                            await getCmdListAPI(props.item.selectElv[0].value);

                            setSelectCcElv(props.item.selectElv[0].value);
                        })()
                    }
                    if (props.item.variable !== undefined) {
                        setCcTo(props.item.variable[0].value);
                    }

                    break;
                }
                case "CLEAN": {
                    if (props.item.task !== undefined) {
                        setCleaningTask(props.item.task[0].value);
                    }
                    if (props.item.mode !== undefined) {
                        setCleaningMode(props.item.mode[0].value);
                    }
                    break;
                }
                // case "CHANGE_FLOOR": {
                //
                //     setSelectCcElv(props.item.variable[0].value);
                //     setChangeFloor(props.item.variable[1].value);
                //
                //     getCmdListAPI(props.item.variable[0].value);
                //     break;
                // }
                case "ROBOT ELV MOVE": {

                    if (props.item.selectElv !== undefined) {

                        (async () => {
                            await getCmdListAPI(props.item.selectElv[0].value);

                            setSelectPresetElv(props.item.selectElv[0].value);
                        })()
                    }

                    if (props.item.insidePoi !== undefined) {
                        setSelectInMove(props.item.insidePoi[0].value);
                    }
                    if (props.item.outPoi !== undefined) {
                        setSelectOutMove(props.item.outPoi[0].value);
                    }
                    if (props.item.startingFloor !== undefined) {
                        setSelectPresetFrom(props.item.startingFloor[0].value1);
                    }
                    if (props.item.targetFloor !== undefined) {
                        setSelectPresetTo(props.item.targetFloor[0].value1);
                    }
                    break;
                }
                default: {
                    setElevatorList(elvList);
                    break;
                }
            }
        }


    }, [moveList, elevatorList, cleanTaskList, cleanModeList, props.item]);


    useEffect(() => {


        switch (props.action) {
            case "MOVE": {
                if (selectRobotCmdList && selectRobotCmdList.length > 0) {
                    selectRobotCmdList.forEach((data: any, i: number) => {
                        if (data.command === 'COMMAND_MOVE') {
                            makePoiList(data.poiList, props.action);
                        }
                    });
                }
                break;
            }

            case "CLEAN" : {
                if (selectRobotCmdList && selectRobotCmdList.length > 0) {
                    selectRobotCmdList.forEach((data: any, i: number) => {
                        if (data.command === 'COMMAND_CLEAN') {
                            makeCleaningDataList(data.executableTasks, 'task');
                            makeCleaningDataList(data.cleanModes, 'mode');
                        }
                    });
                }
                break;
            }

            case "HALL_CALL": {
                if (selectElvCmdList && selectElvCmdList.length > 0) {
                    selectElvCmdList.forEach((data: any, i: number) => {
                        if (data.command === 'COMMAND_HALL_CALL') {
                            makeElvFloorList(data.elvFloorList, props.action, null);
                        }
                    });
                }
                break;
            }
            case "CAR_CALL": {
                if (selectElvCmdList && selectElvCmdList.length > 0) {
                    selectElvCmdList.forEach((data: any, i: number) => {
                        if (data.command === 'COMMAND_CAR_CALL') {
                            makeElvFloorList(data.elvFloorList, props.action, null);
                        }
                    });
                }
                break;
            }
            case "CHANGE_FLOOR": {
                if (selectElvCmdList && selectElvCmdList.length > 0) {
                    selectElvCmdList.forEach((data: any, i: number) => {
                        if (data.command === 'COMMAND_CAR_CALL') {
                            makeElvFloorList(data.elvFloorList, props.action, null);
                        }
                    });
                }
                break;
            }
            case "ROBOT ELV MOVE": {

                if (selectElvCmdList && selectElvCmdList.length > 0) {
                    selectElvCmdList.forEach((data: any, i: number) => {
                        if (data.command === "COMMAND_HALL_CALL") {
                            makeElvFloorList(data.elvFloorList, props.action, 'HC');
                        }
                        if (data.command === "COMMAND_CAR_CALL") {
                            makeElvFloorList(data.elvFloorList, props.action, 'CC');
                        }
                    });
                }

                if (selectRobotCmdList && selectRobotCmdList.length > 0) {
                    selectRobotCmdList.forEach((data: any, i: number) => {
                        if (data.command === 'COMMAND_MOVE') {
                            makePoiList(data.poiList, props.action);
                        }
                    });
                }
                break;
            }
            default: {
                break;
            }
        }
    }, [selectRobotCmdList, selectElvCmdList, props.action]);


    const makeElvFloorList = (data: any, action: string, type: string | null) => {

        if (action === 'HALL_CALL') {
            data.forEach((item: any, i: number) => {
                item['value'] = item.floorIdx;
                item['label'] = item.floorName;
            });
            setElvHcFloorList(data);
        }

        if (action === 'CAR_CALL') {
            data.forEach((item: any, i: number) => {
                item['value'] = item.floorIdx;
                item['label'] = item.floorName;
            });
            setElvCcFloorList(data);
        }

        if (action === 'CHANGE_FLOOR') {
            data.forEach((item: any, i: number) => {
                item['value'] = item.floorIdx;
                item['label'] = item.floorName;
            });
            setElvCcFloorList(data);
        }

        if (action === 'ROBOT ELV MOVE') {
            data.forEach((item: any, i: number) => {
                item['value'] = item.floorIdx;
                item['label'] = item.floorName;
            });
            if (type === 'HC') {
                setPresetElvHcList(data);
            }
            if (type === 'CC') {
                setPresetElvCcList(data);
            }
        }

    }

    const makePoiList = (data: any, action: string) => {

        data.forEach((item: any, i: number) => {
            item['value'] = item.id;
            item['label'] = item.name;
        });

        if (action === 'MOVE') {
            setMoveList(data);
        }

        if (action === 'ROBOT ELV MOVE') {
            setInMoveList(data);
            setOutMoveList(data);
        }

    }


    const makeCleaningDataList = (data: any, type: string) => {
        if (type === 'task') {
            data.forEach((item: any, i: number) => {
                item['value'] = item.name;
                item['label'] = item.name;
            });
            setCleanTaskList(data);
        }
        if (type === 'mode') {
            data.forEach((item: any, i: number) => {
                item['value'] = item.name;
                item['label'] = item.name;
            });
            setCleanModeList(data)
        }
    }


    const onInputData = (e: any, id: string) => {
        const value = e.target.value;
        setInputData(value)

        const data = [{"key": props.action, "value": value}];

        nodeDataMapping(id, data, 'WAIT', 'variable');
    }

    const onDateTime = (dateTime: any, dateTimeString: any, id: string) => {

        setWorkDate(dateTime);
        setWorkDateStr(dateTimeString);

        const data = [{"key": props.action, "value": dateTimeString}];

        nodeDataMapping(id, data, 'ON_TIME', 'variable');

    }

    const onSelectMove = (value: string, id: string) => {
        setMove(value);
        const data = [{"key": props.action, "value": value}];
        nodeDataMapping(id, data, 'MOVE', 'variable');
    }

    /* CHANGE FLOOR */
    const onSelectChangeFloor = (value: string, id: string) => {
        setChangeFloor(value);

        const data = [{"key": props.action, "value": value}];
        nodeDataMapping(id, data, 'MOVE', 'variable');

        (async () => {
            await getCmdListAPI(value);
        })()
    }

    /* CLEAN */
    const onSelectTask = (value: string, option: any, id: string) => {

        setCleaningTask(value);
        const data = [{"key": props.action, "value": value, "data": option}];
        nodeDataMapping(id, data, 'CLEAN', 'task');
    }
    /* CLEAN */
    const onSelectMode = (value: string, id: string) => {
        setCleaningMode(value);
        const data = [{"key": props.action, "value": value}];
        nodeDataMapping(id, data, 'CLEAN', 'mode');
    }

    const nodeDataMapping = (id: string, data: any, action: string, key: string) => {

        const mappingNodeList = JSON.parse(JSON.stringify(nodeList));

        mappingNodeList.forEach((nds: any, i: number) => {
            if (nds.id === id) {
                nds[key] = data;
            }
        });

        changeNodeList(mappingNodeList);

        // setResultNodeList(mappingNodeList);
    }

    const onSelectHcElv = (value: string, id: string) => {

        setSelectHcElv(value);
        const data = [{"key": "selectElv", "value": value}];

        nodeDataMapping(id, data, 'HALL_CALL', 'selectElv');

        (async () => {
            await getCmdListAPI(value);
        })()
    }
    const onSelectCcElv = (value: string, id: string) => {
        setSelectCcElv(value);
        const data = [{"key": "selectElv", "value": value}];

        nodeDataMapping(id, data, 'CAR_CALL', 'selectElv');

        (async () => {
            await getCmdListAPI(value);
        })()
    }


    const onSelectHcFrom = (value: string, id: string) => {
        setHcFrom(value);
        const data = [{"key": "startingFloor", "value": value}];

        nodeDataMapping(id, data, 'HALL_CALL', 'startingFloor');
    }
    const onSelectHcTo = (value: string, id: string) => {
        setHcTo(value);
        const data = [{"key": "targetFloor", "value": value}];

        nodeDataMapping(id, data, 'HALL_CALL', "targetFloor");
    }
    const onSelectCcTo = (value: string, id: string) => {
        setCcTo(value);
        const data = [{"key": props.action, "value": value}];

        nodeDataMapping(id, data, 'CAR_CALL', 'variable');
    }

    ////////////////// PRESET - ROBOT ELV MOVE ///////////////////

    const onSelectPresetElv = (value: string, id: string) => {

        setSelectPresetElv(value);

        const data = [{"key": 'selectElv', "value": value}];

        nodeDataMapping(id, data, 'ROBOT ELV MOVE', 'selectElv');

        (async () => {
            await getCmdListAPI(value);
        })()
    }

    const onSelectInMove = (value: string, id: string) => {

        setSelectInMove(value);

        const data = [{"key": 'insidePoi', "value": value}];

        nodeDataMapping(id, data, 'ROBOT ELV MOVE', 'insidePoi');
    }


    const onSelectOutMove = (value: string, id: string) => {

        setSelectOutMove(value);

        const data = [{"key": 'outPoi', "value": value}];

        nodeDataMapping(id, data, 'ROBOT ELV MOVE', 'outPoi');
    }

    const onSelectPresetFrom = (value: string, option: any, id: string) => {
        setSelectPresetFrom(value);

        const data = [{"key": 'startingFloor', "value1": value, "value2": option.label}];

        nodeDataMapping(id, data, 'ROBOT ELV MOVE', 'startingFloor');
    }

    const onSelectPresetTo = (value: string, option: any, id: string) => {

        setSelectPresetTo(value);

        const data = [{"key": 'targetFloor', "value1": value, "value2": option.label}];

        nodeDataMapping(id, data, 'ROBOT ELV MOVE', 'targetFloor');
    }


    const showModal = () => {
        setIsModalOpen(true);
    };


    const iconTag = () => {

        let tag;
        let nodeCustom;

        switch (props.fullName) {

            case 'PRESET|CONVEYOR CONTROL' : {
                tag = <FcSerialTasks size={15}/>
                break;
            }

            case 'PRESET|ROBOT ELV MOVE': {
                nodeCustom = <>
                    <div style={styles.elvValueBox}>
                        <span style={styles.nodeCustomText}>엘리베이터</span>
                        <Select
                            className={"nodrag"}
                            size={"small"}
                            suffixIcon={null}
                            style={{width: 83, height: 13, fontSize: 9}}
                            onChange={(value) => onSelectPresetElv(value, props.id)}
                            options={elevatorList}
                            value={selectPresetElv}/>
                    </div>
                    <div style={styles.elvValueBox}>
                        <span style={styles.nodeCustomText}>탑승 엘리베이터안</span>
                        <Select
                            className={"nodrag"}
                            size={"small"}
                            suffixIcon={null}
                            style={{width: 60, height: 13, fontSize: 9}}
                            onChange={(value) => onSelectInMove(value, props.id)}
                            options={inMoveList}
                            value={selectInMove}/>
                    </div>
                    <div style={styles.elvValueBox}>
                        <span style={styles.nodeCustomText}>하차 엘리베이터밖</span>
                        <Select
                            className={"nodrag"}
                            size={"small"}
                            suffixIcon={null}
                            style={{width: 60, height: 13, fontSize: 9}}
                            onChange={(value) => onSelectOutMove(value, props.id)}
                            options={outMoveList}
                            value={selectOutMove}/>
                    </div>
                    <div style={styles.nodeValueBox}>
                        <span style={styles.nodeCustomText}>탑승</span>
                        <Select
                            className={"nodrag"}
                            size={"small"}
                            suffixIcon={null}
                            style={{width: 42, height: 13, fontSize: 9}}
                            onChange={(value, option) => onSelectPresetFrom(value, option, props.id)}
                            options={presetElvHcList}
                            value={selectPresetFrom}/>
                        <span style={styles.nodeCustomText}>하차</span>
                        <Select
                            className={"nodrag"}
                            size={"small"}
                            suffixIcon={null}
                            style={{width: 42, height: 13, fontSize: 9}}
                            onChange={(value, option) => onSelectPresetTo(value, option, props.id)}
                            options={presetElvCcList}
                            value={selectPresetTo}/>
                    </div>
                </>;
                tag = <FcSerialTasks size={15}/>
                break;
            }
            case 'START/END|START':
                tag = <IoCaretForwardCircle size={15}/>;
                break;
            case 'START/END|END':
                tag = <IoStopCircle size={15}/>;
                break;

            /* 공통 */
            case 'COMMON|USER_SELECT':
                tag = <FaUserCheck size={15}/>;
                break;
            case 'COMMON|WAIT':
                nodeCustom = <div>
                    <Input name={props.id} value={inputData} id="waitInput" onChange={(e) => onInputData(e, props.id)}
                           className={"nodrag"}
                           style={{width: 40, height: 15, fontSize: 9, outline: 'none'}}/>
                    <span style={styles.nodeCustomText}>초(s)</span>
                </div>;
                tag = <BiSolidTimer size={15}/>;
                break;
            case 'COMMON|ON_TIME':
                nodeCustom = <div>
                    <DatePicker
                        name={props.id}
                        value={workDate}
                        className={"nodrag on_time"}
                        showTime
                        style={{width: 110, height: 13, outline: 'none', borderRadius: '4px', padding: '5px'}}
                        placeholder={"일시를 선택하세요."}
                        onChange={(dateTime, dateTimeString) => onDateTime(dateTime, dateTimeString, props.id)}

                    />
                </div>
                tag = <MdEventRepeat size={15}/>;
                break;

            /* 로봇 : AMR */
            case 'AMR|MOVE':
                nodeCustom = <div>
                    <Select
                        id={props.id}
                        className={"nodrag"}
                        size={"small"}
                        placeholder={"목적지 선택"}
                        suffixIcon={null}
                        style={{width: 80, height: 13, fontSize: 9}}
                        onChange={(value) => onSelectMove(value, props.id)}
                        options={moveList}
                        value={move}/>
                    {/*<span style={styles.nodeCustomText}>로 이동</span>*/}
                </div>;
                tag = <LuMove size={15}/>;
                break;
            case 'AMR|CHARGE':
                tag = <RiBattery2ChargeFill size={15}/>;
                break;
            case 'AMR|CHECK':
                tag = <FaListCheck size={15}/>;
                break;
            case 'AMR|CHECK_SELECT':
                tag = <MdLibraryAddCheck size={15}/>;
                break;
            case 'AMR|CHANGE_MAP':
                tag = <FaMapMarkedAlt size={15}/>;
                break;
            case 'AMR|CHANGE_FLOOR':
                // nodeCustom = <>
                //     <div style={styles.elvValueBox}>
                //         <span style={styles.nodeCustomText}>엘리베이터</span>
                //         <Select
                //             className={"nodrag"}
                //             size={"small"}
                //             suffixIcon={null}
                //             style={{width: 73, height: 13, fontSize: 9}}
                //             onChange={(value) => onSelectCcElv(value, props.id)}
                //             options={elevatorList}
                //             value={selectCcElv}/>
                //     </div>
                //     <div style={styles.nodeValueBox}>
                //         <span style={styles.nodeCustomText}>현재층</span>
                //         <Select
                //             className={"nodrag"}
                //             size={"small"}
                //             suffixIcon={null}
                //             style={{width: 40, height: 13, fontSize: 9}}
                //             onChange={(value) => onSelectChangeFloor(value, props.id)}
                //             options={changeFloorList}
                //             value={changeFloor}/>
                //     </div>
                // </>;
                tag = <TbStairsUp size={15}/>;
                break;
            case 'AMR|IO_CONTROL':
                tag = <TbEaseInOutControlPoints size={15}/>;
                break;
            case 'AMR|CONTENTS_PLAY':
                tag = <MdPermMedia size={15}/>;
                break;
            case 'AMR|BACK_TO_SCHEDULE':
                tag = <AiFillSchedule size={15}/>;
                break;
            case 'AMR|CLEAN':
                nodeCustom =
                    <>
                        <div style={styles.elvValueBox}>
                            <span style={styles.cleanNodeCustomText}>TASK</span>
                            <Select
                                className={"nodrag"}
                                size={"small"}
                                suffixIcon={null}
                                style={{width: 85, height: 13, fontSize: 9}}
                                onChange={(value, option) => onSelectTask(value, option, props.id)}
                                value={cleaningTask}
                                options={cleanTaskList}/>
                        </div>
                        <div style={styles.elvValueBox}>
                            <span style={styles.cleanNodeCustomText}>MODE</span>
                            <Select
                                className={"nodrag"}
                                size={"small"}
                                suffixIcon={null}
                                style={{width: 85, height: 13, fontSize: 9}}
                                onChange={(value) => onSelectMode(value, props.id)}
                                value={cleaningMode}
                                options={cleanModeList}/>
                        </div>
                    </>
                tag = <MdOutlineCleaningServices size={15}/>;
                break;
            case 'AMR|HOME':
                tag = <MdHome size={15}/>;
                break;

            /* 엘리베이터 */
            case 'ELV|HALL_CALL':
                nodeCustom =
                    <>
                        <div style={styles.elvValueBox}>
                            <span style={styles.nodeCustomText}>엘리베이터</span>
                            <Select
                                className={"nodrag"}
                                size={"small"}
                                suffixIcon={null}
                                style={{width: 73, height: 13, fontSize: 9}}
                                onChange={(value) => onSelectHcElv(value, props.id)}
                                options={elevatorList}
                                value={selectHcElv}/>
                        </div>
                        <div style={styles.nodeValueBox}>
                            <span style={styles.nodeCustomText}>탑승</span>
                            <Select
                                className={"nodrag"}
                                size={"small"}
                                suffixIcon={null}
                                style={{width: 40, height: 13, fontSize: 9}}
                                onChange={(value) => onSelectHcFrom(value, props.id)}
                                options={elvHcFloorList}
                                value={hcFrom}/>
                            <span style={styles.nodeCustomText}>하차</span>
                            <Select
                                className={"nodrag"}
                                size={"small"}
                                suffixIcon={null}
                                style={{width: 40, height: 13, fontSize: 9}}
                                onChange={(value) => onSelectHcTo(value, props.id)}
                                options={elvHcFloorList}
                                value={hcTo}/>
                        </div>
                    </>;
                tag = <PiElevatorFill size={15}/>;
                break;
            case 'ELV|CAR_CALL':
                nodeCustom =
                    <>
                        <div style={styles.elvValueBox}>
                            <span style={styles.nodeCustomText}>엘리베이터</span>
                            <Select
                                className={"nodrag"}
                                size={"small"}
                                suffixIcon={null}
                                style={{width: 73, height: 13, fontSize: 9}}
                                onChange={(value) => onSelectCcElv(value, props.id)}
                                options={elevatorList}
                                value={selectCcElv}/>
                        </div>
                        <div style={styles.nodeValueBox}>
                            <span style={styles.nodeCustomText}>하차</span>
                            <Select
                                className={"nodrag"}
                                size={"small"}
                                suffixIcon={null}
                                style={{width: 40, height: 13, fontSize: 9}}
                                onChange={(value) => onSelectCcTo(value, props.id)}
                                options={elvCcFloorList}
                                value={ccTo}/>
                        </div>
                    </>;
                tag = <FaElevator size={15}/>;
                break;
            case 'ELV|OPEN_DOOR':
                tag = <BiDoorOpen size={15}/>;
                break;
            case 'ELV|KEEP_OPEN':
                tag = <RiDoorClosedLine size={15}/>;
                break;
            case 'ELV|CHECK':
                tag = <FaListCheck size={15}/>;
                break;
            case 'ELV|CHECK_SELECT':
                tag = <MdLibraryAddCheck size={15}/>;
                break;

            /* 자동문 */
            case 'AUTODOOR|AUTO_DOOR_OPEN':
                tag = <MdOutlineDoorSliding size={15}/>;
                break;
            case 'AUTODOOR|AUTO_DOOR_CLOSE':
                tag = <MdOutlineDoorSliding size={15}/>;
                break;

            /* PLC */
            case 'PLC|IO_CONTROL':
                tag = <TbEaseInOutControlPoints size={15}/>;
                break;
            case 'PLC|SET_VALUE':
                tag = <BsDatabaseFillCheck size={15}/>;
                break;
            case 'PLC|CHECK':
                tag = <FaListCheck size={15}/>;
                break;
            case 'PLC|CHECK_SELECT':
                tag = <MdLibraryAddCheck size={15}/>;
                break;

            case 'CLEANING ROBOT|MOVE':
                nodeCustom = <div>
                    <Select
                        className={"nodrag"}
                        size={"small"}
                        placeholder={"목적지 선택"}
                        suffixIcon={null}
                        style={{width: 80, height: 15, fontSize: 9}}
                        onChange={(value) => onSelectMove(value, props.id)}
                        options={moveList}
                        value={move}/>
                </div>;
                // tag = <img src={Move} width={15} height={15}/>;
                break;
            case 'CLEANING ROBOT|CHARGE':
                // tag = <img src={Charge} width={15} height={15}/>;
                break;
            case 'CLEANING ROBOT|CHANGE MAP':
                // tag = <img src={ChangeMap} width={15} height={15}/>;
                break;
            default:
                tag = <></>;
                nodeCustom = <></>;
                break;
        }
        return {tag, nodeCustom};
    }

    useEffect(() => {
        setSelectRobotType(robotType);
    }, [robotType]);


    const robotDevice = () => {

        if(props.action === 'START' || props.action === 'END' || props.type === 'COMMON'){
            return;
        }else{
            return(
                <div>
                    <span style={styles.nodeCustomText}>로봇 선택</span>
                    <Select
                        className={"nodrag"}
                        size={"small"}
                        suffixIcon={null}
                        style={{width: 80, height: 15, fontSize: 9}}
                        onChange={(value) => onSelectMove(value, props.id)}
                        options={moveList}
                        value={selectRobotType === 'single' ? robotInfo.name : move}
                        disabled={selectRobotType === 'single'}
                    />
                </div>
            );
        }
    }

    const styles = {
        iconParent: {
            borderRight: '1px solid rgba(0, 0, 0, 0.5)',
            padding: 5,
            paddingRight: 10,
            paddingLeft: 5,
            marginRight: '10px',
            display: 'flex'
        },

        nodeCustomText: {
            marginLeft: 3,
            marginRight: 3,
            fontFamily: 'Gmarket Sans, Medium'
        },

        cleanNodeCustomText: {
            width: 20,
            marginLeft: 3,
            marginRight: 3,
            fontFamily: 'Gmarket Sans, Medium'
        },

        nodeTextBox: {
            fontSize: '7px',
            width: '100%'
        },

        nodeValueBox: {
            display: 'flex',
            alignItems: 'center'
        },

        elvValueBox: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '3px',
            marginTop: '3px'
        }

    }

    return (
        <>
            <div style={styles.iconParent}>
                {iconTag().tag}
            </div>
            <div style={styles.nodeTextBox}>
                {/*<span style={{color: 'red'}}>{props.id}</span>*/}
                <div>{props.type}</div>
                <div style={{textAlign: 'center', fontSize: '9px', fontWeight: 'bold'}}>{props.title}</div>
                <div>{robotDevice()}</div>
                <div>{iconTag().nodeCustom}</div>
            </div>
        </>
    )
    // }

}




