import { create } from "zustand";

interface RobotStore {
  selectedRobotDetailData: any;
  isShowRobotDetail: boolean;
  selectedRobot: string | null;
  changeSelectedRobotDetailData: (inputData: any) => void;
  changeSelectedRobot: (input: any) => void;
  updateIsShowRobotDetail: (input: any) => void;
}

const useRobotstore = create<RobotStore>()((set) => ({
  selectedRobotDetailData: null,
  isShowRobotDetail: false,
  selectedRobot: null,
  changeSelectedRobotDetailData: (inputData) =>
    set((state) => ({
      selectedRobotDetailData: inputData,
    })),
  changeSelectedRobot: (input) =>
    set((state) => ({
      selectedRobot: input,
    })),
  updateIsShowRobotDetail: (input) =>
    set((state) => ({
      isShowRobotDetail: input,
    })),
}));

export default useRobotstore;
