import { useEffect, useState } from "react";
import { useAuth } from "./";

export function useBlurStyle() {
  const { hasAuthSite, hasAuthRobot, login, isVerified } = useAuth();

  const [blurStyle, setBlurStyle] = useState<string>("");

  useEffect(() => {
    if (
      login === "true" &&
      isVerified === "true" &&
      hasAuthSite === "true" &&
      hasAuthRobot === "true"
    ) {
      return setBlurStyle("");
    }
    setBlurStyle("blur-sm pointer-events-none");
  }, [login, isVerified, hasAuthSite, hasAuthRobot]);

  return blurStyle;
}
