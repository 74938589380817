import { useBlurStyle } from "../../hooks";
import Skeleton from "./Skeleton";

const ServiceSkeleton = () => {
  const blurStyle = useBlurStyle();
  const skeletonDummyData = [
    80, 162, 178, 114, 120, 105, 195, 107, 123, 60, 199, 126,
  ];

  return (
    <div
      className={`w-[95%] h-[100%] self-center md:p-0 ${blurStyle} flex justify-center items-end`}
    >
      {skeletonDummyData.map((item, index) => (
        <div key={index} className="mx-1 mb-12">
          <Skeleton width={22} height={item} borderRadius={2} />
        </div>
      ))}
    </div>
  );
};

export default ServiceSkeleton;
