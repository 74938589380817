import ReactDOMServer from "react-dom/server";

interface MapOverlayProps {
  siteName: string;
  roadAddress: string;
  isVisibleOperationButton: boolean;
}

export default function MapOverlay({
  siteName,
  roadAddress,
  isVisibleOperationButton,
}: MapOverlayProps) {
  return ReactDOMServer.renderToString(
    <div className="w-[250xp] text-[15px] p-[20px]">
      <div className="font-semibold">{siteName}</div>
      <div className="text-[12px]">{roadAddress}</div>
      {isVisibleOperationButton && (
        <div className="flex mt-[12px] bg-[#08b295] font-semibold">
          <div className="pr-[10px]">
            <a href={`/robot?siteName=${siteName}`}>로봇운영</a>
          </div>
        </div>
      )}
    </div>
  );
}
